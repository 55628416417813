import styled from "styled-components";

export const ResultContainer = styled.div`
    .title{
        color: #35B7E0;
        font-size: 1.25rem;
    }

    .btn-next {
        padding: 1rem 3rem;
        background-color: #35B7E0;
        border-radius: 6px;
        border: none;

        &:hover{
            color: #35B7E0;
            background-color: white;
            box-shadow: 0 0 0 1px #35B7E0 inset;
        }
    }

    .btn-back{
        font-family: 600;
        padding: 1rem 3.75rem;
        color: #2B6CBF;
        box-shadow: 0 0 0 1px #2B6CBF inset;
        &:hover{
            background-color: #2B6CBF;
            color: white;
        }
    }

    .selected{
        transition: .2s ease-in-out;
        box-shadow: 0px 0px 1px 3px #09CAED1A;
        border: 2px solid #35B7E0 !important;
    }
`