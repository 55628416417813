export const DateFormat = (date, american)=> {
    const splitPart = date.split('/');
    
    if(american){
        const parsedDate = `${splitPart[2]}/${splitPart[1]}/${splitPart[0]}`;
        return parsedDate
    }

    const parsedDate = `${splitPart[2]}-${splitPart[1]}-${splitPart[0]}`;
    return parsedDate;
}