import styled from "styled-components";

export const SearchTravelerDescStyle = styled.form`

    .title {
        color: #35B7E0;
        font-size: 1.25rem;
    }

    .help{
        min-width: 1.5rem;
        min-height: 1.5rem;
        background-color: #EFEFF0;
        color: #7C7C83;
        font-size: .875rem;
        padding: .125rem .5rem;
        border-radius: 50%;
        line-height: 21px;
    }

    .desc {
        margin-top: 1rem;
        max-width: 44.5rem;
        min-height: 14rem;
    }

    .checkout-btn{
        color: white;
        font-weight: bold;
        background-color: #35B7E0;
        border-radius: 6px;
        padding: 1rem 3.5rem;
        border: none;

        &:hover{
            color: #35B7E0;
            background-color: white;
            box-shadow: 0 0 0 1px #35B7E0 inset;
        }
    }

    .btn-back{
        font-weight: bold;
        padding: 1rem 3.5rem;
        color: #2B6CBF;
        box-shadow: 0 0 0 1px #2B6CBF inset;

        &:hover{
            color: white;
            background-color: #2B6CBF;
        }
    }
`