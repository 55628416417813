import { useState } from "react";
import { MenuSideBar } from "../../components/MenuSideBar";
import { SearchTravelerDescContainer } from "../../components/enterprise/SearchTravelerDescContainer";
import { SearchTravelerForm } from "../../components/enterprise/SearchTravelerForm";
import { StepHeader } from "../../components/StepHeader";
import { TravelersSearchResult } from "../../components/enterprise/TravelersSearchResult";
import ReactLoading from 'react-loading';

const CompanySearchUserControl = ({setStep, step})=>{
    const [travels, setTravels] = useState([]);
    const [selectedTravel, setSelectedTravel] = useState([]);
    const [travelSolic, setTravelSolic] = useState([]);
    
    switch(step){
        case 0:
            return <SearchTravelerForm setTravels={setTravels} setStep={setStep} setTravelSolic={setTravelSolic} travelSolic={travelSolic}/> ;
        case 1:
            return <TravelersSearchResult setStep={setStep} travels={travels} setSelectedTravel={setSelectedTravel} setTravelSolic={setTravelSolic} travelSolic={travelSolic}/>;
        case 2:
            return <SearchTravelerDescContainer setStep={setStep} selectedTravel={selectedTravel} setTravelSolic={setTravelSolic} travelSolic={travelSolic}/>;
        default:
            return (
            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <ReactLoading type='spin' color='#35B7E0' height='5%' width='5%'/>
            </div>);
    }
}

export const CompanySearchUser = ()=>{
    const [step, setStep] = useState(0);

    return (
        <MenuSideBar search={true} title={'Buscar viajante'}>
        <StepHeader activeStep={step}  steps={['Informações', 'Selecionar viajante', 'Checkout' ]}>
            <CompanySearchUserControl step={step} setStep={setStep}/>
        </StepHeader>
        
        </MenuSideBar>
        )
}