import React, { useContext } from "react";
import {Navigate, Route, Routes} from 'react-router-dom';
import RegisterEnterpriseFull from "../pages/enterprise/RegisterEnterpriseFull";
import { RegisterEnterpriseCnpj } from "../pages/enterprise/RegisterEnterpriseCnpj";
import { AuthContext } from "../Contexts/AuthContext";
import { useState } from "react";


const ConfigCompanyControl = ()=>{
    const {auth, user} = useContext(AuthContext);
    const [companyData, setCompanyData] = useState({});
    
    
    if(!auth || user.length === 0)
    {
        return <Navigate to="/login" />;
        
    } else if(!user.hasOwnProperty('cnpj')){
        return <>{user.cnpj}</>;
    }

    if(!user.email_verificado_em){
        return <Navigate to="/verificar-email"/>
    }
    
    if(user.cnpj == null){
        return <RegisterEnterpriseCnpj setCompanyData={setCompanyData}/>
    }
    
    if(user.cep == null){
        return <RegisterEnterpriseFull companyData={companyData}/>
    }
    
    
    return <Navigate to='/companies/search-users'/>
}

export default ConfigCompanyControl;