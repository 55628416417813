import styled from "styled-components";

export const AddressStyledContainer = styled.form`
    h5{
        color: #35B7E0;
        font-weight: bold;
    }

    label{
        color: #68686E;
    }

    input{
        padding: 1rem;
    }

    .btn-edit{
        color: #2B6CBF;
        box-shadow: inset 0 0 0 1px #2B6CBF;
        padding: 1rem 1.75rem;
        &:hover{
            background-color: #2B6CBF;
            color: white;
        }
    }

    .address{
        margin-top: 2rem;
    }
`