import { Navigate, Route, Routes } from "react-router-dom";
import { CompanySearchUser } from "../pages/enterprise/CompanySearchUser";
import {AuthContext} from "../Contexts/AuthContext";
import { useContext } from "react";
import { Error } from "../pages/errors/Error";
import { Profile } from "../pages/Profile";
import { MySents } from "../pages/enterprise/MySents";
import { Support } from "../pages/Support";

export const CompaniesRoutesControl = ({route})=>{
    const {auth, user} = useContext(AuthContext);
    

    if(!auth || Object.keys(user).length === 0){
        return <Navigate to="/login" />;
    }
    
    if(!user.email_verificado_em){
        return <Navigate to="/verificar-email"/>
    }
    
    if(!user.hasOwnProperty('cnpj')){
        return null;
    }

    if(user.cep == null || user.cep.length === 0 ){
        return <Navigate to="/register-company"/>
    }

    switch(route){
        case 'search':
            return <CompanySearchUser/>;
        case 'profile':
            return <Profile/>;
        case 'my-sents':
            return <MySents/>;
        case 'support':
            return <Support/>
        default:
            break;
    }
}