import { TravelerResultCard } from "./TravelerResultCard"
import { ResultContainer } from "../styles/enterprise/ResultContainer.styled"
import { useState } from "react";

export const TravelersSearchResult = ({travels, setSelectedTravel, setStep, setTravelSolic, travelSolic})=>{
    const [selected, setSelected] = useState(-1);
    const [btnDisable, setDisable] = useState(true);

    const handleSelect = (e)=>{
        const travelElement = e.currentTarget;
        const travel = travels[travelElement.id];
        setSelectedTravel(travel);
        setSelected(travelElement.id);
        console.log(selected == 0)
        setTravelSolic({ 
            ...travelSolic,
            nome: travel.transportador.nome,
            cep: travel.transportador.cep,
            rua: travel.transportador.rua,
            numero: travel.transportador.numero,
            bairro: travel.transportador.bairro,
            cidade: travel.transportador.cidade,
            uf: travel.transportador.estado,
            data_partida: travel.dt_partida,
            data_chegada: travel.dt_chegada
        });

        setDisable(false);
    }

    const handleNext = ()=>{
        setStep(2)
    }

    return (
        <ResultContainer className="d-flex flex-column flex-grow-1">
            <h5 className="title mt-5 mb-3">Viajantes disponíveis</h5>
            <div className="bg-transparent flex-grow-1 d-flex flex-column justify-content-between">
                <div className="d-flex gap-4 flex-wrap">
                    {travels.length === 0 ?
                    <>Nenhuma viagem encontrada</>
                     :
                    Object.keys(travels).map((key, index)=>{
                        return (
                            <TravelerResultCard
                                selected={selected == index ? 'selected':''}
                                onClick={handleSelect}
                                key={index}
                                id={index}
                                traveler={travels[key].transportador}
                                dt_begin={travels[key].dt_partida}
                                dt_destiny={travels[key].dt_chegada}
                                hr_begin={travels[key].hr_partida}
                                hr_destiny={travels[key].hr_chegada}
                            />
                        )
                    })}
                </div>
                <div className="justify-content-end d-flex flex-column flex-md-row gap-3 mt-4">
                    <button className="btn btn-back" onClick={_=>setStep(0)}>Voltar</button>
                    <button className="btn btn-next text-white" disabled={btnDisable} onClick={handleNext}>Selecionar</button>
                </div>
            </div>
        </ResultContainer>
    )
}