import axios from 'axios';
import React, {createContext, useState } from 'react'
import ReactLoading from 'react-loading';
import { Navigate } from 'react-router-dom';

export const AuthContext = createContext();

function AuthProvider ({children}){
    const [user, setUser] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [auth, setAuth] = useState(false);

    const config = {
        headers:{
            "Accept": 'application/json',
            "Conten-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    };
    
    if(user == null && localStorage.getItem('token') !== null){
        axios.get(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/login/profile`, config)
        .then(res => {
            setAdmin(res.data.admin ?? false);
            setUser(res.data);
            const token = localStorage.getItem('token');
            setAuth(token);
        }).catch(e => {
            setUser([]);
            setAuth(false);
            localStorage.removeItem('token');
        });

        return (
            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <ReactLoading type='spin'  color='#35B7E0' height='5%' width='5%'/>
            </div>
        )
    }

    return (
        <AuthContext.Provider value={{auth, user, admin, setAuth, setUser, setAdmin}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;