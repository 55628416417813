import { Link } from 'react-router-dom';
import T3logo from '../images/t3logo.svg';
export const LogoNavBar = ()=>{
    return (
    <header className='px-0'>
        <nav className='pb-5'>
            <Link to='/'>
                <img alt='Company logo' width={158} src={T3logo}/>
            </Link>
        </nav>
    </header>
    )
}