import React, { useContext } from 'react'
import { AuthContext } from '../Contexts/AuthContext'
import { Navigate, Outlet } from 'react-router-dom';

export const AdminRoutesController = () => {
    const {user, auth} = useContext(AuthContext);

    if(!user.admin){
        return <Navigate to={'/login'}/>;
    }

    return <Outlet/>
}