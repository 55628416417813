import { MenuSideBar } from "../../components/MenuSideBar";
import { Header } from "../../components/Header";
import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { AuthContext } from "../../Contexts/AuthContext";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { MyDeliveryCard } from "../../components/styles/deliverer/MyDeliveryCard";
import { useLocation } from "react-router-dom";
import { CardBorder } from "../../components/styles/CardBorder.styled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { RotaMaps } from "../../components/RotaMaps";
import { LoginButton, LoginButtonReturn } from "../../components/styles/LoginButton.styled";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import styled from "styled-components";

const RotasButton = styled.a`
    box-shadow: 0 0 0 1px #3874CB inset;
    border: none;
    color: #3874CB;
    border-radius: 6px;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: transparent;
    width: 100%;
    text-decoration: none;

    &:hover{
        color: white;
        background-color: #3874CB;
    }
`;

export const DeliveriesSingle = () => {
  const [travel, setTravel] = useState([]);
  const { auth } = useContext(AuthContext);
  const { state } = useLocation();
  

  const uri =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return (
    <MenuSideBar
      bag={true}
      title={state.type == "open" ? "Buscar entregas" : "Minhas entregas"}
    >
      <div className="row gy-5 h-100" style={{ marginTop: "3.5rem" }}>
        <div className="d-flex flex-column col-12 col-xl-6">
          <CardBorder
            borderColor="#35B7E0"
            borderDirection="bottom"
            className="col-12 col-lg d-flex flex-column justify-content-between"
          >
            
            <RotaMaps 
            partidaPosition={`${state.travel.estado_entrega}+${state.travel.rua_entrega.replaceAll(' ', '+')}+${state.travel.num_entrega}`}
            chegadaPosition={`${state.cep.uf}+${state.cep.logradouro.replaceAll(' ', '+')}+${state.travel.num_encomenda}`}
            />
            
            <div className="d-flex flex-column gap-3 my-3">
              <div className="partida d-flex gap-4">
                <div>
                  <FiberManualRecordIcon
                    style={{ fontSize: "1.7rem", color: "#35B7E0" }}
                  />
                </div>
                <div>
                  <p
                    style={{ color: "#4B4B53", fontWeight: 600 }}
                    className="mb-0 ms-2 fs-16"
                  >
                   {state.travel.rua_entrega}, {state.travel.num_entrega}
                  </p>
                </div>
              </div>
              <div className="distancia d-flex gap-4">
                <div
                  style={{ width: 24 }}
                  className="d-flex flex-column gap-2 align-items-center"
                >
                  <FiberManualRecordIcon
                    style={{ fontSize: ".6rem", color: "#35B7E0" }}
                  />
                  <FiberManualRecordIcon
                    style={{ fontSize: ".6rem", color: "#35B7E0" }}
                  />
                  <FiberManualRecordIcon
                    style={{ fontSize: ".6rem", color: "#35B7E0" }}
                  />
                  <FiberManualRecordIcon
                    style={{ fontSize: ".6rem", color: "#35B7E0" }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <p
                    style={{ color: "#35B7E0", fontWeight: 600 }}
                    className="mb-0 ms-2 fs-16"
                  >
                    1,3km
                  </p>
                </div>
              </div>
              <div className="destino d-flex gap-4">
                <div>
                  <FmdGoodIcon
                    style={{ fontSize: "1.7rem", color: "#35B7E0" }}
                  />
                </div>
                <div>
                  <p
                    style={{ color: "#4B4B53", fontWeight: 600 }}
                    className="mb-0 ms-2 fs-16"
                  >
                    {state.cep.logradouro}, {state.travel.num_encomenda}
                  </p>
                </div>
              </div>
            </div>
            <div className=" d-flex flex-column flex-sm-row justify-content-between border-top pt-3 gap-3">
              <div className="w-100 d-flex justify-content-between flex-sm-column">
                <p
                  style={{ color: "#7C7C83", fontWeight: 500, fontSize: 14 }}
                  className="mb-0 fs-16"
                >
                  Valor a receber
                </p>
                <p
                  style={{ color: "#35B7E0", fontWeight: 600, fontSize: 18 }}
                  className="mb-0"
                >
                  R$ 50,00
                </p>
              </div>
              <div>
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-end">
            <RotasButton target="_blank" href={`https://www.google.com.br/maps/dir/${state.travel.estado_entrega}+${state.travel.bairro_entrega}+${state.travel.rua_entrega}+${state.travel.num_entrega}/${state.cep.uf}+${state.cep.bairro}+${state.cep.logradouro}+${state.travel.num_encomenda}`} style={{width: 'auto',  padding: '12px 16px'}}>Rota</RotasButton>
            </div>
          </CardBorder>
        </div>
        <div className="d-flex flex-column col-12 col-xl-6 gap-3">
          <CardBorder borderColor="#35B7E0" borderDirection="bottom">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <h6 className="title">Status da entrega</h6>
              <span className="fw-bold">{state.travel.status[0].ultimo_status}</span>
            </div>
          </CardBorder>
          <CardBorder
            borderColor="#35B7E0"
            borderDirection="bottom"
            className="col-12 col-lg d-flex flex-column gap-4"
          >
            {state.type == "open" ? (
              <>
                <h6 className="title">Produto a ser enviado</h6>
                <p style={{ color: "#68686E" }}>
                  Lembre-se de verificar se o produto a ser transportado condiz
                  com a descrição!
                </p>
              </>
            ) : (
              <h6 className="title">Produto enviado</h6>
            )}

            <div className="d-flex flex-column gap-4">
              <div>
                <label className="fw-bold">
                  Dimensões{" "}
                  <span className="fw-normal">
                    (Altura, largura e comprimento)
                  </span>
                </label>
                <div className="d-flex align-items-center gap-3">
                  <input
                    type="number"
                    readOnly
                    max={80}
                    className="form-control"
                    id="height"
                    name="height"
                    placeholder="00"
                    value={state.travel.solic_viajantes[0].altura}
                    required
                  />
                  <input
                    type="number"
                    readOnly
                    max={50}
                    className="form-control"
                    id="width"
                    name="width"
                    placeholder="00"
                    value={state.travel.solic_viajantes[0].largura}
                    required
                  />
                  <input
                    type="number"
                    readOnly
                    max={28}
                    className="form-control"
                    id="length"
                    name="length"
                    placeholder="00"
                    value={state.travel.solic_viajantes[0].comprimento}
                    required
                  />
                  <p className="mb-0">cm</p>
                </div>
              </div>
              <div className="d-flex gap-5">
                <div className="w-100">
                  <label className="fw-bold" htmlFor="weight">
                    Peso
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control"
                      type="number"
                      readOnly
                      max={23000}
                      id="weight"
                      name="weight"
                      placeholder="00"
                      required
                    />
                    <span className="mb-0 ms-2">g</span>
                  </div>
                </div>
                <div className="w-100">
                  <label className="fw-bold" htmlFor="weight">
                    Categoria
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      placeholder="Categoria"
                      value={state.travel.solic_viajantes[0].tipo}
                      required
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="form-group mt-4">
                  <label className="fw-bold">Descrição</label>
                  <textarea
                    className="form-control mt-2"
                    rows={10}
                    placeholder="Descrição"
                    readOnly
                    value={state.travel.solic_viajantes[0].desc}
                  ></textarea>
                </div>
              </div>
            </div>
            
          </CardBorder>
          {state.type == "open" ? (
              <div className="d-flex justify-content-end">
              <LoginButton className="d-flex gap-2" style={{width: 'auto', padding: '12px 16px'}}>
                <QrCodeScannerIcon/>
                Confirmar recebimento</LoginButton>
              </div>
            ) : (
              null
            )}
          
        </div>
      </div>
    </MenuSideBar>
  );
};
