import axios from "axios";
import { MyTravelStyledCard } from "../styles/traveler/MyTravelStyledCard.styled";
import { MdDelete, MdEdit, MdFlightTakeoff } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { LoginButtonReturn } from "../styles/LoginButton.styled";
import { DeleteMyTravelModal } from "./DeleteMyTravelModal";
import { EditMyTravelModal } from "./EditMyTravelModal";

export const MyTravelCard = ({myTravels, setMyTravels, index, travel})=>{
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const uri = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    
    const config = {
        headers: {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }

    const handleDateFormat = (dt)=>{
        const dtObject = new Date(dt);
        const day = dtObject.getDate();
        const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        const month = months[dtObject.getMonth()];
        const year = dtObject.getFullYear();
        const date = day+1 + " " + month + " " + year;
        return date;
    }

    const handleClose = () => setShow(false);
    const handleCloseEdit = () => setShowEdit(false);

    const handleStatus = (status)=>{
        switch(status){
            case 'EM ANÁLISE':
                return 'Pendente'
            case 'CONFIRMADA':
                return 'Confirmada'
            case 'EMITIDA':
                return 'Emitida'
            case 'EM TRÂNSITO':
                return 'Em trânsito'
            case 'EM ROTA DE ENTREGA':
                return 'Rota de entrega'
            case 'ENTREGUE':
                return 'Entregue'
            case 'NEGADA':
                return 'Negada'
            default:
                break;
        }
    }

    const startIndividualDelete = ()=>{
        setShow(true);
    }

    const startEdit = ()=>{
        setShowEdit(true);
    }

    const handleIndividualDelete = ()=>{
        const newTravels = myTravels.data.filter((t,i)=>t[i] !== myTravels[index]);
        axios.delete(`${uri}/cadastro_viagens/${travel.id}`, config)
        .then((res)=>{
            setMyTravels({...myTravels, data: newTravels});
            setShow(0);
        })
        .catch(error=>console.log(error))
    }
    // const getLocalOrigem = ()=>{
    //     axios.get(`https://opencep.com/v1/${travel.local_encomenda}`)
    //     .then(res=>{
    //        (res)
    //     });
    // }

    // getLocalOrigem();


    return (
        <MyTravelStyledCard className="col-12 col-lg">
            
            {travel.status === 'EM ANÁLISE'?
                <>
                    <div className="d-flex aling-items-center justify-content-between">
                        <input className="form-check-input p-2 border-2 border-dark rounded-1" type="checkbox"/>

                        <div>
                            <button className="me-3 border-0 p-0 bg-transparent" onClick={startEdit}>
                                <MdEdit style={{fontSize: '1.5rem', color: '#35B7E0', cursor: 'pointer'}}/>
                            </button>
                            <button className="border-0 bg-transparent p-0" onClick={startIndividualDelete}>
                                <MdDelete style={{fontSize: '1.5rem', color: '#FF385C'}}/>
                            </button>
                        </div>
                    </div>

                    <hr/>
                </>
            :''}

            <div className="d-flex gap-2 flex-wrap justify-content-between">
                <p className="fw-bold">(Contratada ou não)</p>
                <p className="fw-bold">{handleDateFormat(travel.dt_partida)}</p>
            </div>

            <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">

                <div className="flex-shrink-1">
                    <label className="details-label">Origem</label>
                    <p className="fw-bold">{travel.cidade_encomenda} - {travel.local_encomenda}</p>

                    <label className="details-label">Data</label>
                    <p className="fw-bold">{handleDateFormat(travel.dt_partida)}</p>

                    <label className="details-label">Hora</label>
                    <p className="fw-bold">{travel.hr_partida.slice(0, 5)}</p>
                </div>
                <div className="d-none d-md-block">
                <MdFlightTakeoff className="flight flex-grow-1"/>
                </div>
                

                <div className="flex-shrink-1">
                    <label className="details-label">Destino</label>
                    <p className="fw-bold">{travel.cidade_entrega} - {travel.estado_entrega}</p>

                    <label className="details-label">Data</label>
                    <p className="fw-bold">{handleDateFormat(travel.dt_chegada)}</p>

                    <label className="details-label">Hora</label>
                    <p className="fw-bold">{travel.hr_chegada.slice(0, 5)}</p>
                </div>

            </div>

            <hr/>

            <div className="d-flex flex-wrap justify-content-between">
                <div>
                    <label className="details-label">Valor a receber</label>
                    <h5 className="price fw-bold">R$ 60,00</h5>
                </div>
                <div>
                    <label className="details-label">Status</label>
                    <p className="status">{handleStatus(travel.status)}</p>
                </div>
            </div>

            <DeleteMyTravelModal 
                show={show} 
                setShow={setShow} 
                handleClose={handleClose} 
                handleIndividualDelete={handleIndividualDelete}
            />

            <EditMyTravelModal
                show={showEdit}
                handleClose={handleCloseEdit}
                myTravel={myTravels.data[index]}
            />
        </MyTravelStyledCard>
    )
}