import styled from "styled-components";

export const ConfirmTravelModal = styled.div`
    border: 1px solid #35B7E0;
    border-radius: 8px;
    padding: 1rem 1.5rem 2rem;
    
    .modal-header{   
        border: 0;
        padding: 0;
    }
        
    .modal-body{
        margin-top: .5rem;
        border-radius: 8px;
        padding: 0;
        .info-container{
            gap: 3.5rem;
            
            .subtitle{
                color: #35B7E0;
            }

            .info{
                gap: 2.5rem;
            }
        }
    }

    .next{
        font-family: 600;
        padding: .75rem 2rem;
        background-color: #35B7E0;

        &:hover{
            background-color: white;
            color: #35B7E0 !important;
            box-shadow: 0 0 0 1px #35B7E0 inset;
        }
    }

    .back{
        font-family: 600;
        padding: .75rem 3.25rem;
        color: #2B6CBF;
        box-shadow: 0 0 0 1px #2B6CBF inset;
        border: 0;

        &:hover{
            background-color: #2B6CBF;
            color: white;
        }
    }
`