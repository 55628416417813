import React, { useState } from "react";
import { StyledAside } from "../components/styles/Aside.styled";
import { BoxAsideContainer } from "../components/BoxAsideContainer";
import { LogoNavBar } from "../components/LogoNavBar";
import RecoveryImage from "../images/recovery_pass.svg";
import { LoginForm } from "../components/styles/LoginForm.styled";
import { LoginButton } from "../components/styles/LoginButton.styled";
import axios from "axios";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import PasswordInput from "../components/forms/PasswordInput";
import SelectInput from "../components/forms/SelectInput";
import TextInput from "../components/forms/TextInput";

export const RecoveryPassword = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [confirmPassVis, setConfirmPassVis] = useState(false);
  const [primaryPassVis, setPrimaryPassVis] = useState(false);
  const [checkPasswordConfirm, setPasswordConfirm] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();

  const URI =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const CONFIG = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${URI}/pass_recover_mail`, { email: e.target.email.value }, CONFIG)
      .then((res) => {
        setLoading(false);
        setStatus("info");
      })
      .catch((error) => {
        setLoading(false);
        setStatus("danger");
        console.log(error);
      });
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    if (!checkPasswordConfirm) {
      e.target.userpassconfirm.focus();
      return;
    }

    const data = {
      tipo: e.target.type.value,
      token: token,
      senha: e.target.pass.value,
    };

    axios
      .post(`${URI}/reset_password`, data, CONFIG)
      .then((res) => {
        alert("Senha alterada com sucesso!");
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        alert("Token ou e-mail inválido");
      });
  };

  const handlePrimaryPassVis = () => {
    if (primaryPassVis) {
      setPrimaryPassVis(false);
      return;
    }

    setPrimaryPassVis(true);
  };

  const handleConfirmPassVis = () => {
    if (confirmPassVis) {
      setConfirmPassVis(false);
      return;
    }
    setConfirmPassVis(true);
  };

  const handlePassChange = (e) => {
    const passwordField = document.querySelector("#pass").value;
    if (e.target.value === passwordField) {
      setPasswordConfirm(true);
    } else {
      setPasswordConfirm(false);
    }
  };

  return (
    <section style={{ minHeight: "100%" }} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bold ">Resetar senha de segurança</h2>
            <p className="text-muted">
              {token ? "Digite a nova senha" : "Confirme seu e-mail"}
            </p>
          </div>

          {token ? (
            <LoginForm
              onSubmit={handleSubmitNewPassword}
              className="w-100 gap-2"
              style={{ marginTop: "3.5rem", alignItems: "normal" }}
            >
              <div className="row">
                <div className="col-12">
                  <SelectInput
                    id="type"
                    name="type"
                    options={[
                      { value: 1, texto: "Viajante" },
                      { value: 2, texto: "Empresa" },
                      { value: 3, texto: "Entregador" },
                    ]}
                    placeholder="Escolha seu tipo de conta"
                    label="Tipo de conta"
                    required
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-12">
                <PasswordInput
                  id="pass"
                  name="userpass"
                  label="Senha"
                  toogleVision={primaryPassVis}
                  handleToogleVision={handlePrimaryPassVis}
                  placeholder="Insira sua senha de acesso"
                  autoComplete="password"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <PasswordInput
                  id="passconfirm"
                  name="userpassconfirm"
                  onChange={handlePassChange}
                  label="Confirme sua senha"
                  toogleVision={confirmPassVis}
                  handleToogleVision={handleConfirmPassVis}
                  placeholder="Insira a mesma senha"
                  autoComplete="same-password"
                  required
                />
                <span
                  className={`text-danger ${
                    checkPasswordConfirm ? "d-none" : ""
                  }`}
                >
                  Senhas não coincidem.
                </span>
              </div>
            </div>

              

              <LoginButton
                disabled={loading || !checkPasswordConfirm}
                type="submit"
                className="fw-bold d-flex align-items-center justify-content-center w-100 mt-3"
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height="100%"
                    width="6%"
                  />
                ) : (
                  "Próximo"
                )}
              </LoginButton>
            </LoginForm>
          ) : (
            <LoginForm
              onSubmit={handleSubmitEmail}
              className="w-100 gap-2"
              style={{ marginTop: "3.5rem" }}
            >
              <div className="d-flex aling-items-center justify-content-between w-100">
              <TextInput
                  id="email"
                  name="useremail"
                  type="email"
                  label="E-mail"
                  placeholder="E-mail"
                  required
                  autoComplete="useremail"
                />
                {status === "info" ? (
                  <small className="text-info">E-mail enviado!</small>
                ) : (
                  ""
                )}
                {status === "danger" ? (
                  <small className="text-danger">E-mail inválido!</small>
                ) : (
                  ""
                )}
              </div>
              <LoginButton
                disabled={loading}
                type="submit"
                className="fw-bold d-flex align-items-center justify-content-center w-100 mt-3"
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height="100%"
                    width="6%"
                  />
                ) : (
                  "Próximo"
                )}
              </LoginButton>
            </LoginForm>
          )}
        </div>
      </StyledAside>
      <BoxAsideContainer image={RecoveryImage} width={528} />
    </section>
  );
};
