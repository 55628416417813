import { StyledFileInput } from './styles/FileInput.styled';
import { Required } from './styles/Required.styled';
import { Tooltip } from './styles/Tooltip.styled';
import { MdFileUpload } from "react-icons/md";
import { FileLabel } from './styles/deliverer/FileLabel.styled';

export const FileInput = ({label, placeholder, id, name, onChange})=>{
    return (
        <>
            <div className="d-flex">
                <p>{label}</p>
                <Required>
                    <Tooltip>Esse campo é obrigatório.</Tooltip>
                    <span className="text-danger required">*</span>
                </Required>
            </div>
            
            <FileLabel style={{cursor: 'pointer'}} className='d-flex form-control justify-content-between align-items-center' htmlFor={id}>
                <span className='mb-0'>{placeholder}</span>
                <MdFileUpload/>
            </FileLabel>

            <StyledFileInput
                className='form-control mb-2'
                type="file"
                name={name}
                id={id}
                required={true}
                onChange={onChange}
            />
        </>
    )
}