import styled from "styled-components";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const FooterStyled = styled.footer`
    background-color: #313135;
    padding: 3.5rem 0;
`
export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width:995px) {
        gap: 32px;
    }
`
export const FooterLogo = styled.img`
    background-color: #313135;
    padding: 3.5rem 0;
`
export const FooterLink = styled(Link)`
     font-size: 16px;
     color: #EFEFF0;
     text-decoration: none;
`
export const FooterHashLink = styled(HashLink)`
     font-size: 16px;
     color: #EFEFF0;
     text-decoration: none;
`
export const FooterLinksTitle = styled.p`
    font-size: 18px;
    color: white;
    font-weight: 700;
    margin-bottom: 24px;
`