import { StyledFileInput } from "./styles/FileInput.styled";
import { Required } from "./styles/Required.styled";
import { Tooltip } from "./styles/Tooltip.styled";
import { MdFileUpload } from "react-icons/md";
import { FileLabel } from "./styles/deliverer/FileLabel.styled";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import axios from "axios";
import { useEffect, useState } from "react";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const partidaExemplo = {
  lat: -5.808342309027849,
  lng: -35.24190503300426,
};

const ChegadaExemplo = {
  lat: -5.8046518439162185,
  lng: -35.227662274906834,
};


export const RotaMaps = ({ partidaPosition, chegadaPosition }) => {
  const [initPos, setInitPos] = useState({});
  const [finalPos, setFinalPos] = useState({});
  
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCuBG1vNsAB_FOTCi7nk8WS6L8D1lq_JDA",
  });

  const config = {
    headers:{
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': 'AIzaSyCuBG1vNsAB_FOTCi7nk8WS6L8D1lq_JDA',
      'X-Goog-FieldMask': '*',
    }
  }

  const getPlaceCoordinates = (place)=>{
    axios.get('https://nominatim.openstreetmap.org/search?q='+place+'&format=json')
    .then(res=>res.data[0])
    .catch(e=>e.message);
  }

  useEffect(()=>{
    console.log(initPos, finalPos)
    if(!initPos || !finalPos)
    {
      const init = getPlaceCoordinates(partidaPosition);
      const final = getPlaceCoordinates(chegadaPosition);
      setInitPos({
        lat: init.lat,
        lng: init.log,
      });
      setFinalPos({
        lat: final.lat,
        lng: final.log,
      });
    }
  })

  return (
    <div>
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={partidaExemplo} zoom={12}>
          <Marker
            position={initPos ?? partidaExemplo}
            icon={{
              url: require("./../images/icons/moto.svg").default,
              fillColor: "#EB00FF",
              scale: 0.5,
            }}
          ></Marker>
          
          <Marker
            position={finalPos ?? ChegadaExemplo}
            icon={{
              url: require("./../images/icons/moto.svg").default,
              fillColor: "#EB00FF",
              scale: 0.5,
            }}
          ></Marker> 
          
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};
