import styled from "styled-components";

export const PersonalContainerStyle = styled.div`
    margin-top: 3.5rem;

    input{
        padding: 1rem;
    }
    
    h5{
        color: #35B7E0;
        font-weight: bold;
    }

    .personal {
        margin-top: 2rem;
    }

    label{
        color: #68686E;
    }

    .img-container{
        position: relative;
        .img{
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 140px;
            height: 140px;
            border-radius: 50%;
        }

        .btn-file{
            font-size: 1.25rem;
            color: #2B6CBF;
            background-color: #D6F9FF;
            border: 1px solid  #2B6CBF;
            padding: .625rem;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            right: .25rem;
            bottom: .25rem;
        }
    }
`