import React from 'react'
import { Modal } from 'react-bootstrap'
import { LoginButtonReturn } from '../styles/LoginButton.styled'

export const DeleteMyTravelModal = ({show, setShow, handleClose, handleIndividualDelete}) => {
  return (
    <Modal
        show={show} 
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
    >
        <div  className="pt-3 px-4">
            <Modal.Header closeButton>
                <Modal.Title className="fw-bold">Confirmar ação</Modal.Title>
            </Modal.Header>
        </div>
        <Modal.Body className="text-center">
            <div  style={{margin: '3.5rem 0'}}>
                <h5>Certeza que deseja excluir essa viagem?</h5>
                <p>Essa ação não pode ser desfeita!</p>
            </div>
        
            <div className="text-center d-flex" style={{marginBottom: '2rem'}}>
                <LoginButtonReturn onClick={_=>setShow(false)} style={{minWidth: '0'}} className="py-2 px-4 me-4">Cancelar</LoginButtonReturn>
                <button onClick={handleIndividualDelete} style={{minWidth: '0'}} className="py-2 px-4 btn btn-danger w-100">Excluir</button>
            </div>
        </Modal.Body>
    </Modal>
  )
}
