// import logo from '../logo.svg';

import axios from 'axios';
import {StyledAside} from '../components/styles/Aside.styled.js';
import { LoginButton } from '../components/styles/LoginButton.styled.js';
import { LoginForm } from '../components/styles/LoginForm.styled.js';
import { Required } from '../components/styles/Required.styled.js';
import { Tooltip } from '../components/styles/Tooltip.styled.js';
import { useContext, useState } from 'react';
import { AuthContext } from '../Contexts/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import { LogoNavBar } from '../components/LogoNavBar.js';
import { BoxAsideContainer } from '../components/BoxAsideContainer.js';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import LoginBox  from '../images/login_box.svg';
import SelectInput from '../components/forms/SelectInput/index.js';
import TextInput from '../components/forms/TextInput/index.js';
import PasswordInput from '../components/forms/PasswordInput/index.js';

function Login(){
    const {setAuth,setUser, auth, user} = useContext(AuthContext);
    const [infoError, setInfoError] = useState(false);
    const [confirmPassVis, setConfirmPassVis] = useState(false);
    const [loginDisabled, setLoginDisabled] = useState(false);
    const navi = useNavigate();

    const loginRequest = (fd, redirect, uri)=>{        
        const config = {
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            }
        }
        
        setLoginDisabled(true);
        const ep = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/login/${uri}` 
        axios.post(ep, fd, config
        ).then((res)=>{
            localStorage.setItem('token', res.data.token);
            config.headers['Authorization'] = `Bearer ${res.data.token}`
            axios.get(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/login/profile`, config
            ).then((resProfile)=>{
                setUser(resProfile.data);
                setAuth(res.data.token);
                return navi(redirect);
            }).catch((error)=>error);

        }).catch((error)=>{
            setLoginDisabled(false);
            setInfoError(true)
        });
    }
    

    const handleLogin = (e)=>{
        e.preventDefault();
        
        const fd = new FormData();
        fd.append('email', e.target.email.value);
        fd.append('senha', e.target.pass.value);

        switch(Number(e.target.type.value)){ 
            case 1:
                loginRequest(fd, '/register-traveler', 'viajantes')
                break;
            case 2:
                loginRequest(fd, '/register-company', 'empresas')
                break;
            case 3:
                loginRequest(fd, '/register-deliverer', 'entregadores')
                break;
            default:
                e.target.type.focus();
                break;
        };
    }

    const handleConfirmPassVis = ()=>{
        if(confirmPassVis){
            setConfirmPassVis(false);
            return
        }
        setConfirmPassVis(true);
    }

    

    return (

    <section style={{minHeight: '100%'}} className='d-flex'>
        <StyledAside className='w-100 w-lg-50 d-flex flex-column container'>

            <div>
                <LogoNavBar/>
            </div>
            
            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                <div>
                    <h2 className='fw-bold'>Bem vindo de volta!</h2>
                    <span className='text-muted'>Acesse o sistema inserindo suas informações</span>
                </div>
                
                <LoginForm className='mt-5' onSubmit={handleLogin} method='POST'>
                    <span className={`text-danger ${infoError?"":"d-none"}`}>Dados não encontrados, tente novamente.</span>
                    <SelectInput id="type" name="type" options={[{'value' : 1, 'texto': 'Viajante'}, {'value' : 2, 'texto': 'Empresa'}, {'value' : 3, 'texto': 'Entregador'}]} placeholder='Escolha seu tipo de conta' label="Seu tipo de conta" required/>
                    <TextInput id="email" name="email" type="email" label="Seu e-email" placeholder="Insira seu endereço de e-mail" required autoComplete="useremail"/>
                    <PasswordInput id="pass" name="pass" label="Sua senha" toogleVision={confirmPassVis} handleToogleVision={handleConfirmPassVis} placeholder="Insira sua senha de acesso" autoComplete="same-password" linkPasswordRecovery="/recuperar-senha" />
                    
                    <LoginButton disabled={loginDisabled} type='submit' className='w-100'>
                        {loginDisabled ?
                        <Spinner animation="border" role="status" size='sm'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : 'Acessar'
                        }
                    </LoginButton>
                    <p className="mt-4 w-100 text-center">Não possui uma conta? <Link to='/register' className="text-decoration-none">Cadastre-se!</Link></p>
                </LoginForm>
            </div>

        </StyledAside>
        <BoxAsideContainer width={528} image={LoginBox}/>
    </section>

    )
}

export default Login;