// import logo from '../logo.svg';
import { useContext, useState } from "react";
import { StyledAside } from "../../components/styles/Aside.styled.js";
import {
  LoginButton,
  LoginButtonReturn,
} from "../../components/styles/LoginButton.styled.js";
import { LoginForm } from "../../components/styles/LoginForm.styled.js";
import { Required } from "../../components/styles/Required.styled.js";
import { Tooltip } from "../../components/styles/Tooltip.styled.js";
import ReactInputMask from "react-input-mask";
import { AuthContext } from "../../Contexts/AuthContext.js";
import cep from "cep-promise";
import axios from "axios";
import { BoxAsideContainer } from "../../components/BoxAsideContainer.js";
import { LogoNavBar } from "../../components/LogoNavBar.js";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import RegisterBox from "../../images/register_box.svg";
import TextInput from "../../components/forms/TextInput/index.js";

function RegisterEnterpriseFull({ companyData }) {
  const [checkCep, setCep] = useState(true);
  const { auth, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleCepChange = (e) => {
    const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNumbersCep.length === 8) {
      axios
        .get(`https://opencep.com/v1/${onlyNumbersCep}`)
        .then((res) => {
          const state = document.querySelector("#state");
          const city = document.querySelector("#city");
          const street = document.querySelector("#street");
          const nhood = document.querySelector("#nhood");
          state.value = res.data.uf;
          city.value = res.data.localidade;
          street.value = res.data.logradouro;
          nhood.value = res.data.bairro;

          setCep(true);
        })
        .catch((e) => setCep(false));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fd = new FormData();
    const onlyNumbersCep = e.target.cep.value.replace(/[^0-9]/g, "");

    fd.append("nome", e.target.name.value);
    fd.append("nome_fantasia", e.target.fakename.value);
    fd.append("cep", onlyNumbersCep);
    fd.append("numero", e.target.number.value);
    fd.append("rua", e.target.street.value);
    fd.append("bairro", e.target.nhood.value);
    fd.append("estado", e.target.state.value);
    fd.append("cidade", e.target.city.value);

    config.headers.Authorization = `Bearer ${auth}`;
    axios
      .patch(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/empresas/${user.id}`,
        fd,
        config
      )
      .then((res) => {
        setUser(res.data);
        navigate("/companies/profile");
      })
      .catch((e) => e);
  };

  const getBack = () => {
    setUser({ ...user, cnpj: null });
  };

  useEffect(() => {
    const state = document.querySelector("#state");
    const city = document.querySelector("#city");
    const street = document.querySelector("#street");
    const nhood = document.querySelector("#nhood");
    console.log(companyData);
    if (companyData.cep && state.length !== 0) {
      document.querySelector("#cep").value = companyData.cep;
      document.querySelector("#number").value = companyData.num;
      document.querySelector("#name").value = companyData.name;
      document.querySelector("#fakename").value = companyData.fantasy;

      axios
        .get(`https://opencep.com/v1/${companyData.cep.replace("-", "")}`)
        .then((res) => {
          state.value = res.data.uf;
          city.value = res.data.localidade;
          street.value = res.data.logradouro;
          nhood.value = res.data.bairro;

          setCep(true);
        })
        .catch((e) => setCep(false));
    }
  }, [companyData]);

  return (
    <section style={{minHeight: '100%'}} className="d-flex">
      <StyledAside  className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Vamos verificar seu CNPJ</h2>
            <span className="text-muted">Insira o CPNJ da sua empresa</span>
          </div>

          <LoginForm
            style={{ alignItems: "normal" }}
            className="mt-5"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="row">
                <div className="col-12">
                <TextInput
                type="text"
                name="name"
                id="name"
                placeholder="Ex.: T3Lake Aeronáutica e Logística Avançada S.A"
                readOnly
                required
                label="Nome da empresa"
                
              />
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                <TextInput
                type="text"
                name="fakename"
                id="fakename"
                placeholder="Ex.: T3lake"
                readOnly
                required
                label="Nome fantasia"
              />
                </div>

            </div>

            <div className="row">
            <div className="col-12 col-lg-6">
            <TextInput
                mask={"99999-999"}
                onChange={handleCepChange}
                type="text"
                name="cep"
                id="cep"
                placeholder="00000-000"
                required
                readOnly
                label="CEP"
              />
                <span className={`text-danger ${checkCep ? "d-none" : ""}`}>
                  CEP inválido!
                </span>
              </div>

              <div className="col-12 col-lg-6">
              <TextInput
               type="text"
               name="state"
               id="state"
               placeholder="Ex.: Minas Gerais"
               readOnly
               required
                label="Estado"
              />
              </div>
            </div>

            <div className="row">
            <div className="col-12 col-lg-6">
            <TextInput
               type="text"
               name="city"
               id="city"
               placeholder="Ex.: Uberlândia"
               readOnly
               required
                label="Cidade"
              />
              </div>

              <div className="col-12 col-lg-6">
              <TextInput
              type="text"
              name="street"
              id="street"
              readOnly
              placeholder="Ex.: Rua Croácia"
              required
                label="Rua"
              />
              </div>
            </div>

            <div className="row">
            <div className="col-12 col-lg-6">
            <TextInput
             type="text"
             name="nhood"
             id="nhood"
             readOnly
             placeholder="Ex.: Sport Club"
             required
                label="Bairro"
              />
              </div>
              <div className="col-12 col-lg-6">
              <TextInput
            type="text"
            name="number"
            id="number"
            readOnly
            placeholder="Ex.: 342"
            required
                label="Número"
              />
              </div>
            </div>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                readOnly
                required={true}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
                style={{ fontSize: ".75em" }}
              >
                Confirmo a leitura, concordo com todos os termos e autorizo o
                uso dos dados apresentados.
              </label>
            </div>
            <div className="w-100">
            <TextInput
           type="text"
           name="desc"
           id="desc"
           readOnly
           placeholder="Complemento"
                label="Complemento"
              />
            </div>

            <div className="d-flex justify-content-between w-100 gap-2 mt-2">
              <LoginButtonReturn type="button" onClick={getBack}>
                Voltar
              </LoginButtonReturn>
              <LoginButton type="submit">Próximo</LoginButton>
            </div>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
}

export default RegisterEnterpriseFull;
