import { useContext, useEffect, useState } from "react";
import { StyledAside } from "../components/styles/Aside.styled";
import { LoginForm } from "../components/styles/LoginForm.styled";
import { LoginButton } from "../components/styles/LoginButton.styled";
import axios from "axios";
import { AuthContext } from "../Contexts/AuthContext";
import { LogoNavBar } from "../components/LogoNavBar";
import { BoxAsideContainer } from "../components/BoxAsideContainer";
import {Navigate, useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import BoxImage from '../images/register_box.svg';

export const VerifyEmail = ()=>{
    const [error, setError] = useState(false);
    const {user, setUser} = useContext(AuthContext);
    const navigator  = useNavigate();
    const uri = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}`;
    const config = {
        headers: {
            Application: 'application/json',
            ContentType: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }

    const onInputChange = (e, id)=>{
        const input = document.getElementById(id);
        setError(false);
        if(e.target.value !== ''){
            input.focus();
        };
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const n1 = e.target.n1.value;
        const n2 = e.target.n2.value;
        const n3 = e.target.n3.value;
        const n4 = e.target.n4.value;

        const data = {otp: n1+n2+n3+n4};
        axios.post(`${uri}/verify_code`, data, config)
        .then(async res=>{
            await setUser({...res.data.data, email_verificado_em: Date.now()});
            
            navigator('/login')
        })
        .catch(e=>{
            setError(true);
        });
    }

    const resendCode = ()=>{
        axios.post(`${uri}/resend_email`, {}, config)
        .then(async res=>{
        })
        .catch(error=>{
            if(error.response.status === 401){
                alert('Faça login para receber um novo código');
                navigator('/login');
            }
        });
    }

    useEffect(()=>{
        if(user && user.email_verificado_em){
            navigator('/login');
        }
     },[user])

    return (
        <section style={{ minHeight: "100%" }} className="d-flex">
            <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
                <div>
                    <LogoNavBar/>
                </div>

                <div style={{maxWidth: 350}} className="d-flex flex-column flex-grow-1 justify-content-center align-self-center w-100">
                    <div>
                        <h3 className="fw-bolder text-muted">Confirme seu e-mail</h3>
                        <span className='text-muted'>Digite o código enviado para seu email</span>
                    </div>

                    <LoginForm className='mt-5' onSubmit={handleSubmit} method="POST">
                        <div>
                            {error ? <small className="text-danger">Código inválido!</small>:''}
                            <div className="d-flex  gap-4 mt-2">
                                <ReactInputMask 
                                    mask={'9'} 
                                    maskChar={0} 
                                    style={{width: '24%'}}
                                    className={`form-control text-center ${error ? 'border-danger':''}`}
                                    max={9} 
                                    min={0} 
                                    type="text"
                                    id="n1"
                                    onChange={(e)=>onInputChange(e, 'n2')}
                                />
                                <ReactInputMask 
                                    mask={'9'} 
                                    maskChar={0} 
                                    style={{width: '24%'}}
                                    className={`form-control text-center ${error ? 'border-danger':''}`} 
                                    max={9} 
                                    min={0} 
                                    type="text"
                                    id="n2"
                                    onChange={(e)=>onInputChange(e, 'n3')}
                                />
                                <ReactInputMask 
                                    mask={'9'} 
                                    maskChar={0} 
                                    style={{width: '24%'}}
                                    className={`form-control text-center ${error ? 'border-danger':''}`} 
                                    max={9} 
                                    min={0} 
                                    type="text"
                                    id="n3"
                                    onChange={(e)=>onInputChange(e, 'n4')}
                                />
                                <ReactInputMask 
                                    mask={'9'} 
                                    maskChar={0} 
                                    style={{width: '24%'}}
                                    className={`form-control text-center ${error ? 'border-danger':''}`} 
                                    max={9} 
                                    min={0} 
                                    type="text"
                                    id="n4"
                                />
                            </div>
                        </div>

                        <LoginButton type='submit' className='w-100'>Avançar</LoginButton>
                        <p className="mt-3 text-center w-100">Não recebeu o código? <button type="button" onClick={resendCode} className="bg-transparent border-0 text-info">Enviar outro</button></p>

                        <p className="mt-3 text-center w-100">Já tem uma conta? <Link to='/login' className="text-decoration-none">Faça login!</Link></p>

                    </LoginForm>
                </div>
            </StyledAside>
            <BoxAsideContainer image={BoxImage} width={712} />
        </section>
    );
}