import { MdNotifications } from "react-icons/md";
import { StepHeaderStyle, StepperStyle } from "./styles/StepHeaderStyle.styled";
import { MdDone } from "react-icons/md";
import { useContext } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import {Header} from './Header';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';





export const StepHeader = ({activeStep, steps, children}) => {
    const {user} = useContext(AuthContext);
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (<>
        <Box sx={{ width: '100%', paddingY: 6 }}>
      <StepperStyle activeStep={activeStep} orientation={width < 500 ? "vertical" : "horizontal"}>
        {steps.map((label, index) => {
          return (
            <Step  key={label} >
              <StepLabel >{label}</StepLabel>
            </Step>
          );
        })}
      </StepperStyle>
    </Box>
     {children}
     </>
    )
}