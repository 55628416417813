import styled from "styled-components";
import Stepper from '@mui/material/Stepper';

export const StepperTrackingStyle = styled(Stepper)`
    .MuiStepConnector-root{
        
        margin-left: 24px;
        .MuiStepConnector-line{
            border-left-width: 3px;
            border-color: #09CAED;
        }
    }
    .MuiStepContent-root{
        border-left-width: 3px;
        margin-left: 24px;
        border-color: #09CAED;
        
    }
    
    
    
`;
