import { Header } from "../components/Header"
import { MenuSideBar } from "../components/MenuSideBar"
import { SupportContainer } from "../components/styles/SupportContainer.styled"

export const Support=()=>{
    return(
            <MenuSideBar support={true} title="Suporte">
            <div className="flex-grow-1 d-flex flex-column">
                <div className='d-flex flex-column' style={{maxWidth: 700, gap: '2.375rem'}}>
                    <SupportContainer>
                        <div className="form-group">
                            <label className="fw-bold">Título</label>
                            <input className="form-control mt-2" type="text" placeholder="Título"/>
                        </div>
                        <div className="form-group mt-4">
                            <label className="fw-bold">Mensagem</label>
                            <textarea className="form-control mt-2" rows={10} placeholder="Mensagem"></textarea>
                        </div>
                        <div className="w-100 text-end">
                            <button className="btn-support btn mt-5 fw-bold text-white">Enviar</button>
                        </div>
                    </SupportContainer>
                </div>
            </div>
            </MenuSideBar>
        
    );
} 