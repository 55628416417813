import { Link } from "react-router-dom";
import { Footer } from "../components/lading/Footer";
import { Header } from "../components/lading/Header";
import { Container } from "react-bootstrap";
import { LandingCard, LandingContainerCards } from "../components/styles/landing/LandingCard.style";
import about from '../images/about.svg';
import { LandingText, LandingTitle } from "../components/styles/landing/LandingContainer.style";

export const About = ()=>{
    return (
        <>
            <Header/>
            <Container style={{gap: 80, marginTop:48, marginBottom: 100}} className='d-flex flex-column  px-3'>
                <LandingText className="mb-5"><Link className="text-decoration-none text-muted" to={'/'}>Página inicial</Link><span className="mx-3">&gt;</span>Sobre nós</LandingText>
                <section>
                    <div className="row gy-4">
                        <div className="order-2 order-lg-1 col-12 col-lg-6">
                        <LandingText className="text-muted" style={{fontSize: '.875rem'}}>Conheça mais sobre a T3Lake</LandingText>
                        <article>
                            <LandingTitle className="fw-bold">Sobre nós</LandingTitle>
                            <LandingText className="text-muted">
                            Na T3Lake, nós entendemos a importância de uma jornada de compras tranquila e eficiente. Fundada com a missão de simplificar o processo de entrega de itens comprados online, somos uma equipe focada em conectar viajantes e e-commerces de maneira inovadora.
                            </LandingText>
                            <LandingText className="text-muted mt-4">
                            Nosso objetivo nasceu da percepção de que, embora a compra online tenha se tornado mais acessível e conveniente, a espera pela entrega muitas vezes pode ser demorada. Assim, concebemos nossa solução: aproveitar a mobilidade dos viajantes para agilizar a entrega de itens, eliminando os atrasos e garantindo a satisfação do cliente.
                            </LandingText>
                            <LandingText className="text-muted mt-4">Nosso compromisso com a excelência reflete-se em cada aspecto do nosso serviço.</LandingText>
                        </article>
                    
                        </div>
                        <div className="order-1 order-lg-2 col-12 col-lg-6 d-flex justify-content-center justify-content-xl-end">
                        <img alt="placeholder" src={about} style={{maxWidth: 580}} className='w-100'/>
                        </div>
                    </div>
                

                    
                </section>
                

            <div className="separator">
                <hr/>
            </div>

            <LandingContainerCards className='row mt-0'>
            <div className='col-12 col-lg-4 d-flex justify-content-center px-4'>
                <LandingCard >
                    <h6 className="fw-bold mb-0" style={{fontSize: '1.125rem', color: '#35B7E0'}}>Missão</h6>
                    <p className="mt-3 mb-0 text-muted">Proporcionar soluções logísticas eficientes e confiáveis,
                        superando as expectativas dos nossos clientes e contribuindo para o desenvolvimento sustentável das comunidades onde atuamos.</p>
                </LandingCard>
            </div>
            <div className='col-12 col-lg-4 d-flex justify-content-center px-4'>
                <LandingCard>
                    <h6 className="fw-bold mb-0" style={{fontSize: '1.125rem', color: '#35B7E0'}}>Visão</h6>
                    <p className="mt-3 mb-0 text-muted">Ser reconhecida como a principal escolha em transporte e logística,
                        pela excelência na prestação de serviços, inovação constante e compromisso com a satisfação total dos clientes.</p>
                </LandingCard>
            </div>
            <div className='col-12 col-lg-4 d-flex justify-content-center px-4'>
                <LandingCard >
                    <h6 className="fw-bold mb-0" style={{fontSize: '1.125rem', color: '#35B7E0'}}>Valores</h6>
                    <p className="mt-3 mb-0 text-muted">Compromisso com integridade e inovação, priorizando satisfação do cliente e mantendo altos padrões.
                        Assumindo responsabilidade social e ambiental, promovendo práticas sustentáveis.</p>
                </LandingCard>
            </div>
            </LandingContainerCards>
            </Container>

            <Footer/>
        </>
    )
}