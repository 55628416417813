import { FooterContainer, FooterHashLink, FooterLink, FooterLinksTitle, FooterLogo, FooterStyled } from '../styles/landing/FooterStyle.styled';
import logo from '../../images/footer_logo.svg';

import { FaFacebookSquare } from 'react-icons/fa';
import { MdCall, MdEmail } from 'react-icons/md';
import { RiInstagramFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Container } from 'react-bootstrap';

export const Footer = ()=>{
    return (
        <FooterStyled>
            <Container>
                <FooterContainer className='row text-center text-lg-start'>
                    <div className='col-12 col-xl-6 d-flex justify-content-center justify-content-xl-start'>
                    <FooterLogo alt='t3 logo' src={logo}/>
                    </div>
                    <div className='col-12 col-lg-4  col-xl-2'>
                        
                    <div >
                        <FooterLinksTitle>Links</FooterLinksTitle>
                        <ul className='nav flex-column gap-3'>
                            <li><FooterLink preventScrollReset={false} to='/about'>Sobre nós</FooterLink></li>
                            <li><FooterLink to='/how'>Como funciona</FooterLink></li>
                            <li><FooterLink to='/contact'>Contato</FooterLink></li>
                            <li><FooterHashLink to='/#perguntas_frequentes' >Perguntas frequentes</FooterHashLink></li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 col-lg-4  col-xl-2'>
                    <div>
                    <FooterLinksTitle>Redes sociais</FooterLinksTitle>
                        <ul className='nav flex-column gap-3'>
                            <li> <FooterLink to='https://www.instagram.com/t3lake_pl/?utm_source=ig_web_button_share_sheet' target='blank'><RiInstagramFill className='me-3'/>@instagram</FooterLink></li>
                            <li> <FooterLink to='#' ><FaFacebookSquare className='me-3'/>@facebook</FooterLink></li>
                        </ul>
                    </div>
                
                </div>
                <div className='col-12 col-lg-4  col-xl-2'>
                    <div>
                    <FooterLinksTitle>Contato</FooterLinksTitle>
                        <ul className='nav flex-column gap-3'>
                            <li><FooterLink to='#' className='text-white mt-3 text-decoration-none'><MdCall className='me-3'/>xx xxxxx-xxxx</FooterLink></li>
                            <li><FooterLink to='/contact' className='text-white mt-3 text-decoration-none'><MdEmail className='me-3'/>contato@t3lake.com</FooterLink></li>
                        </ul>
                    </div>
                </div>
                </FooterContainer>
                
            </Container>
        </FooterStyled>
    )
}