import styled from "styled-components";

export const HowList = styled.ol`
    counter-reset: counter;
    list-style: none;
    padding-left: 0;

    li {
        counter-increment: counter;
        margin: 0.25rem;
        flex: 0 0 50%;

        &::before {
            content: counter(counter);
            background: #35B7E0;
            min-width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            margin-right: 2rem;
            justify-content: center;
            color: white;
            font-weight: bold;

            @media only screen and (max-width: 990px) {
                margin-right: 1.2rem;
            }
        }
    }
    h5{
        color: #4B4B53;
        @media only screen and (max-width: 990px) {
            font-size: 18px;
        }
    }
    

    .item-container{
        display: flex;
        gap: 4rem;
    }

    .mt-4r{
        margin-top: 4rem;
    }
`