import styled from 'styled-components';

export const RegLocTravelStyle = styled.form`
input{
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    .title{
        font-size: 1.25rem;
        font-weight: 700;
        color: #35B7E0;
        margin-bottom: 1.5rem;
    }

    label {
        margin-bottom: .5rem;
    }

    

    .next{
        font-family: 600;
        padding: 1rem 3.75rem;
        background-color: #35B7E0;
        &:hover{
            background-color: white;
            color: #35B7E0 !important;
            box-shadow: 0 0 0 1px #35B7E0 inset;
        }
    }

    .back{
        font-family: 600;
        padding: 1rem 3.75rem;
        color: #2B6CBF;
        box-shadow: 0 0 0 1px #2B6CBF inset;
        &:hover{
            background-color: #2B6CBF;
            color: white;
        }
    }
`