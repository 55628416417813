import styled from "styled-components";

export const MySentCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.3% - 1rem);
    background-color: white;
    border-bottom: 2px solid #35B7E0;
    box-shadow: -1px 4px 8.1px 0px #09CAED1A;
    padding: 1.5rem;
    border-radius: .5rem;

    svg {
        font-size: 3rem;
        color: #35B7E0;
    }

    .details-label{
        color: #7C7C83;
    }

    .price{
        color: #35B7E0;
    }

    .status {
        color: #F3AD43;
    }
`