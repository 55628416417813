import styled from "styled-components";

export const FileLabel = styled.label`
    

    span{
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-wrap: nowrap;
        flex: 1;
        overflow-x: hidden;
    }
`