import styled from 'styled-components';

export const RegTravelBagContainer = styled.form`
    .title {
        font-weight: bold;
        color: #35B7E0;
        margin-bottom: 1.5rem;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }

    .add{
        border-radius: 6px;
        color: #68686E;
        border: 1px solid #68686E;
        background-color: transparent;
        &:hover{
            background-color: #68686E;
            color: white;
        }

        &:disabled{
            cursor: not-allowed;
        }
    }

    #bagCardsContainer{
        overflow-y: auto;
    }

    .next{
        font-family: 600;
        padding: 1rem 3.75rem;
        background-color: #35B7E0;

        &:disabled{
            cursor: not-allowed;
            pointer-events: all;
        }

        &:hover{
            color: #35B7E0 !important;
            box-shadow: 0 0 0 1px #35B7E0 inset;
            background-color: white;
        }

    }

    .back{
        font-family: 600;
        padding: 1rem 3.75rem;
        color: #2B6CBF;
        box-shadow: 0 0 0 1px #2B6CBF inset;
        &:hover{
            background-color: #2B6CBF;
            color: white;
        }
    }
`;