import React from "react";
import { Required } from "../../styles/Required.styled";
import { Tooltip } from "../../styles/Tooltip.styled";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Link } from "react-router-dom";

export default function PasswordInput({
  id,
  name,
  label,
  placeholder,
  toogleVision,
  handleToogleVision,
  autoComplete,
  linkPasswordRecovery,
  required = false,
  onChange
}) {
  return (
    <div className="w-100">
      <label className="form-label d-flex" htmlFor={id}>{label}
            {
            required ? 
            <Required>
                <Tooltip>Esse campo é obrigatório.</Tooltip>
                <span className='text-danger required'>*</span>
            </Required>
            : null
            }
            
      </label>
      <div
        className={`d-flex align-items-center w-100 form-control p-0 border mb-2 position-relative`}
      >
        <input
          className="flex-grow-1 border-0 pass p-3 rounded"
          type={toogleVision ? "text" : "password"}
          name={name}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
        />

        <button
          style={{ right: 0 }}
          className="border-0 bg-transparent position-absolute h-100 px-3 d-flex align-items-center"
          type="button"
          onClick={handleToogleVision}
        >
          {!toogleVision ? (
            <MdVisibility
              style={{
                fontSize: "1.5rem",
                color: "#7C7C83",
                cursor: "pointer",
              }}
            />
          ) : (
            <MdVisibilityOff
              style={{
                fontSize: "1.5rem",
                color: "#7C7C83",
                cursor: "pointer",
              }}
            />
          )}
        </button>
      </div>
      {linkPasswordRecovery ? (
        <Link
          to={linkPasswordRecovery}
          className="text-decoration-none text-muted"
        >
          Esqueceu sua senha?
        </Link>
      ) : null}
    </div>
  );
}
