import { initMercadoPago } from "@mercadopago/sdk-react";
import axios from "axios";

initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_KEY);

export const payment_initialization = (amount, user) => {
    return {
        amount: amount,
        preferenceId: null,
        payer: {
            email: user.email,
            identification:{
                "type": "CNPJ",
                "number": user.cnpj
            },
            address: {
                zipCode: user.cep,
                federalUnit: user.estado,
                city: user.cidade,
                neighborhood: user.bairro,
                streetName: user.rua,
                streetNumber: user.numero
            }
        }
    }
};

export const status_initialization = (id) => {
    return {
        paymentId: id
    };
}

export const payment_customization = {
    paymentMethods: {
        ticket: "all",
        bankTransfer: "all",
        creditCard: "all",
        debitCard: "all",
        pix: "all"
    },
};


export const onError = async (error) => {
    console.log(error);
};

export const onReady = async () => {
    /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
    */
};