import styled from "styled-components";

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5em;

    .code-container{
        input[type="number"]{

            &::-webkit-inner-spin-button{
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
            }
        }
    }
    
    .pass{
        
        &:focus{
            outline-width: 0 !important;
        }
    }
`;