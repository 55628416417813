import ReactInputMask from "react-input-mask";
import { StyledSearchTravelerForm } from "../styles/enterprise/SearchTravelerForm.styled";
import cep from "cep-promise";
import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { DatePicker } from "react-widgets/cjs";
import { DateFormat } from "../../utils/DateFormat";
import ReactLoading from 'react-loading';
import SelectInput from "../forms/SelectInput";

export const SearchTravelerForm = ({ setTravels, setStep, setTravelSolic }) => {
    const { user, auth } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleSearchUser = (e) => {
        e.preventDefault();
        setLoading(true)

        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        };

        const travelSolic = {
            empresa_id: user.id,
            tipo: e.target.category.value.toUpperCase(),
            altura: e.target.height.value,
            largura: e.target.width.value,
            comprimento: e.target.length.value,
            peso: e.target.weight.value,
        };

        const dt_start = DateFormat(e.target.dt_start_input.value);
        const dt_end = DateFormat(e.target.dt_end_input.value);
        const state_destiny = e.target.state_destiny.value;
        const state_origin = e.target.state_origin.value;

        const uri =
            `${
                process.env.NODE_ENV === "development"
                    ? process.env.REACT_APP_API_URL_DEV
                    : process.env.REACT_APP_API_URL_PROD
            }` +
            `/cadastro_viagens/filter?` +
            `state_destiny=${state_destiny}` +
            `&state_origin=${state_origin}` +
            `&date_start=${dt_start}` +
            `&date_end=${dt_end}` +
            `&weight=${travelSolic.peso}` +
            `&width=${travelSolic.largura}` +
            `&length=${travelSolic.comprimento}` +
            `&height=${travelSolic.altura}` +
            `&category=${travelSolic.tipo}`;

        setTravelSolic(travelSolic);
        
        axios.get(uri,config)
        .then(async res=>{
            await setTravels(res.data);
            setStep(1);
        })
        .catch(e=>{
            setLoading(false)
        });
    };

    const originCepChange = async (e) => {
        const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");

        if (onlyNumbersCep.length === 8) {
            await axios
                .get(`https://opencep.com/v1/${onlyNumbersCep}`)
                .then((res) => {
                    document.getElementById("state_origin").value = res.data.uf;
                    document.getElementById("city_origin").value =
                        res.data.localidade;
                    document.getElementById("nh_origin").value =
                        res.data.bairro;
                    document.getElementById("street_origin").value =
                        res.data.logradouro;
                })
                .catch((e) => e);
        }
    };

    const destinyCepChange = async (e) => {
        const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");

        if (onlyNumbersCep.length === 8) {
            await axios
                .get(`https://opencep.com/v1/${onlyNumbersCep}`)
                .then((res) => {
                    document.getElementById("state_destiny").value =
                        res.data.uf;
                    document.getElementById("city_destiny").value =
                        res.data.localidade;
                    document.getElementById("nh_destiny").value =
                        res.data.bairro;
                    document.getElementById("street_destiny").value =
                        res.data.logradouro;
                })
                .catch((e) => e);
        }
    };

    return (
        <StyledSearchTravelerForm onSubmit={handleSearchUser}>
            <h6 className="title">Produto a ser enviado</h6>

            <div className="row d-flex product gap-5 mt-3">
                <div className="col d-flex flex-wrap gap-5">

                
                <div className="d-flex flex-column">
                    <SelectInput
                    label="Categoria"
                    placeholder="Categoria"
                   id="category"
                    name="category"
                    options={[
                        {value : "ARTIGOS PERIGOSOS", texto: "ARTIGOS PERIGOSOS"},
                        {value : "HIGIENE E BELEZA", texto: "HIGIENE E BELEZA"},
                        {value : "DOCUMENTOS", texto: "DOCUMENTOS"},
                        {value : "ROUPAS", texto: "ROUPAS"},
                        {value : "ELETRÔNICOS", texto: "ELETRÔNICOS"},
                        {value : "PERFUROCORTANTES", texto: "PERFUROCORTANTES"},
                        {value : "MATERIAL HOSPITALAR", texto: "MATERIAL HOSPITALAR"},
                        {value : "PAPELARIA", texto: "PAPELARIA"},
                        {value : "OUTROS", texto: "OUTROS"}
                    ]}
                    />
                </div>

                <div>
                    <label className="fw-bold" htmlFor="weight">
                        Peso
                    </label>
                    <div className="d-flex align-items-center">
                        <input
                            className="form-control"
                            type="number"
                            max={23000}
                            id="weight"
                            name="weight"
                            placeholder="00"
                            required
                        />
                        <span className="mb-0 ms-3">g</span>
                    </div>
                </div>
                <div>
                    <label className="fw-bold">
                        Dimensões{" "}
                        <span className="fw-normal">
                            (Altura, largura e comprimento)
                        </span>
                    </label>
                    <div className="d-flex align-items-center gap-3">
                        <input
                            type="number"
                            max={80}
                            className="form-control"
                            id="height"
                            name="height"
                            placeholder="00"
                            required
                        />
                        <input
                            type="number"
                            max={50}
                            className="form-control"
                            id="width"
                            name="width"
                            placeholder="00"
                            required
                        />
                        <input
                            type="number"
                            max={28}
                            className="form-control"
                            id="length"
                            name="length"
                            placeholder="00"
                            required
                        />
                        <p className="mb-0">cm</p>
                    </div>
                </div>
                </div>

                
            </div>

            <h6 className="title mt-5">Viagem</h6>
            <div className="travel-date">
                <label>Data</label>
                <div className="d-flex flex-column flex-sm-row align-items-center gap-3">
                    <DatePicker
                        defaultValue={new Date()}
                        min={new Date()}
                        required
                        className=""
                        type="date"
                        id="dt_start"
                    />
                    <p className="mb-0">até</p>
                    <DatePicker
                        defaultValue={new Date()}
                        min={new Date()}
                        required
                        className=""
                        type="date"
                        id="dt_end"
                    />
                </div>
            </div>

            <div className="mt-5 row where-to">
                <div className="col-12 col-md-6">
                    <h6 className="title">Origem</h6>
                    <div className="row d-flex gy-3">
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="cep_origin">CEP</label>
                            <ReactInputMask
                                onChange={originCepChange}
                                mask={"99999-999"}
                                required
                                placeholder="00000-000"
                                className="form-control "
                                id="cep_origin"
                                name="cep_origin"
                                type="text"
                            />
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="state_origin">Estado</label>
                            <input
                                placeholder="Estado"
                                required
                                className="form-control "
                                id="state_origin"
                                name="state_origin"
                                type="text"
                            />
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="city_origin">Cidade</label>
                            <input
                                required
                                placeholder="Cidade"
                                className="form-control "
                                id="city_origin"
                                name="city_origin"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="nh_origin">Bairo</label>
                            <input
                                required
                                placeholder="Bairro"
                                className="form-control "
                                id="nh_origin"
                                name="nh_origin"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="street_origin">Rua</label>
                            <input
                                required
                                placeholder="Rua"
                                className="form-control "
                                id="street_origin"
                                name="street_origin"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="number_origin">Número</label>
                            <input
                                required
                                placeholder="000"
                                className="form-control "
                                id="number_origin"
                                name="number_origin"
                                type="text"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <h6 className="title">Destino</h6>
                    <div className="row d-flex gy-3">
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="cep_destiny">CEP</label>
                            <ReactInputMask
                                onChange={destinyCepChange}
                                mask={"99999-999"}
                                required
                                placeholder="00000-000"
                                className="form-control "
                                id="cep_destiny"
                                name="cep_destiny"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="state_destiny">Estado</label>
                            <input
                                required
                                placeholder="Estado"
                                className="form-control "
                                id="state_destiny"
                                name="state_destiny"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="city_destiny">Cidade</label>
                            <input
                                required
                                placeholder="Cidade"
                                className="form-control "
                                id="city_destiny"
                                name="city_destiny"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="nh_destiny">Bairo</label>
                            <input
                                required
                                placeholder="Bairro"
                                className="form-control "
                                id="nh_destiny"
                                name="nh_destiny"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="street_destiny">Rua</label>
                            <input
                                required
                                placeholder="Rua"
                                className="form-control "
                                id="street_destiny"
                                name="street_destiny"
                                type="text"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="number_destiny">Número</label>
                            <input
                                required
                                placeholder="000"
                                className="form-control "
                                id="number_destiny"
                                name="number_destiny"
                                ype="text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 text-end">
                <button disabled={loading} type="submit" className="btn btn-search">
                    {loading ? <ReactLoading type='spin' color='#ffffff' height='1.5rem' width='1.75rem'/> : 'Buscar'}
                </button>
            </div>
        </StyledSearchTravelerForm>
    );
};
