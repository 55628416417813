import styled from "styled-components";
import Stepper from '@mui/material/Stepper';

export const StepperStyle = styled(Stepper)`
    .MuiStepConnector-line{
        border-top-width: 4px;
        border-color: #DFDFE0;
        @media only screen and (max-width: 770px) {
            border-top-width: 2px;
        }
    }
    .MuiStepLabel-labelContainer .MuiStepLabel-label{
        color: #7C7C83;
        font-size: 18px;
        @media only screen and (max-width: 770px) {
            font-size: 12px;
        }
    }
    .MuiStepLabel-labelContainer .Mui-active{
            color: #4B4B53 !important;
        
    }
    .MuiStepLabel-iconContainer{
        .Mui-completed{
            color: #09CAED;  
        }
        .MuiSvgIcon-root{
            font-size: 3rem !important;
            @media only screen and (max-width: 770px) {
                font-size: 2rem !important;
        }
        }
        .Mui-active{
            color: #09CAED;
        }
       
    }
    
    
`;
export const StepHeaderStyle = styled.div`
    .steps {
        display: flex;
        gap: 14rem;
        margin-top: 3.5rem;
        
        div {
            display: flex;
            align-items: center;
        }

        .step-container:not(:last-child){
            position: relative;
            
            &::after{
                content: "";
                position: absolute;
                height: .25rem;
                width: 10rem;
                background-color: #DFDFE0;
                left: calc(100% + 2rem);
                top: 50%;
            }
        
        }

        .step {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: #7C7C83;
            height: 3rem;
            width: 3rem;
            margin-right: 1rem;

        }
        
        
        
        .step-container:first-child{
            .step-0{
                background-color: #09CAED !important;
            }
        }

        .step-container:not(:last-child){
            .step-1{
                background-color: #09CAED !important;
            }
        }
        
        .step-2{
            background-color: #09CAED !important;
        }
    }
`