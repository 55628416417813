import { MenuSideBar } from "../../components/MenuSideBar";
import {Header} from "../../components/Header";
import { useContext, useEffect, useState } from "react";
import { MyTravelCard } from "../../components/traveler/MyTravelCard";
import axios from "axios";
import { AuthContext } from "../../Contexts/AuthContext";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

export const MyTravels = ()=>{
    const [myTravels, setMyTravels] = useState([]);
    const [nextDisable, setNextDisable] = useState(false);
    const [prevDisable, setPrevDisable] = useState(true);
    const {auth} = useContext(AuthContext);

    const uri = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    
    const config = {
        headers: {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
    
    const handleNextPage = ()=>{
        axios.get(`${myTravels.next_page_url}`, config)
        .then(res=>{
            setMyTravels(res.data)
            if(!res.data.next_page_url){
                setNextDisable(true);
            }
            setPrevDisable(false);
        })
        .catch(e=>(e))
    }

    const handlePreviousPage = ()=>{
        axios.get(`${myTravels.prev_page_url}`, config)
        .then(res=>{
            setMyTravels(res.data)
            if(!res.data.prev_page_url){
                setPrevDisable(true);
            }
            setNextDisable(false);
        })
        .catch(e=>(e))
    }

    const toPage = (e)=>{
        if(e.target.id != myTravels.current_page){
            axios.get(`${myTravels.links[e.target.id].url}`, config)
            .then(res=>{
                setMyTravels(res.data)

                if(!res.data.next_page_url){
                    setNextDisable(true);
                } else {
                    setNextDisable(false)
                }

                if(!res.data.prev_page_url){
                    setPrevDisable(true);
                } else {
                    setPrevDisable(false);
                }
            })
            .catch(e=>(e))
        }
    }
    
    useEffect(()=>{
        if(myTravels.length === 0){
            axios.get(`${uri}/cadastro_viagens/mytravels`, config)
            .then(res=>{
                setMyTravels(res.data)
            })
            .catch(e=>console.log(e))
        }
    });

    return (
        <MenuSideBar bag={true} title="Minhas Viagens">
    <div className="row gy-5" style={{marginTop: '3.5rem'}}>
                {myTravels.data ?
                    Object.keys(myTravels.data).map((index)=>{
                    return (
                        <div className="col-12 col-lg-6 col-xl-4">
                            <MyTravelCard setMyTravels={setMyTravels} myTravels={myTravels} index={index} travel={myTravels.data[index]}/>
                        </div>
                    )
                }):'Nenhuma viagem encontrada'}
        </div>
            
            
            {myTravels.length !== 0 && myTravels.last_page !== 1 ?
            <div className="d-flex mt-5 flex-grow-1 align-items-end">
                {prevDisable ? '':
                <button className="btn p-0 rounded-circle" onClick={handlePreviousPage}>
                    <MdNavigateBefore style={{fontSize: '1.5rem'}}></MdNavigateBefore>
                </button>}

                {[...Array(myTravels.last_page).keys()].map((key, index)=>{
                    return (
                        <p
                        className="me-2 mb-0 px-2"
                        key={key}
                        id={key+1}
                        onClick={toPage}
                        style={myTravels.current_page == key+1 ?
                        {border: '1px solid #09CAED', borderRadius: '50%'}:{cursor: 'pointer'}}
                        >{key+1}</p>
                    )
                })}
                
                {nextDisable ? '': 
                <button className="btn p-0 rounded-circle" onClick={handleNextPage}>
                    <MdNavigateNext style={{fontSize: '1.5rem'}}></MdNavigateNext>
                </button>}
            </div>: ''}
    </MenuSideBar>
    )
}