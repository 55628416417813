import React, { useState } from 'react'
import { IconContainer, IconLine } from '../styles/landing/ToogleMenu.styled';

export default function ToogleIcon() {
    const [isMenuClicked, UseMenuClicked] = useState('unclicked');

    function MenuClicked(params) {
        UseMenuClicked(isMenuClicked == 'unclicked' ? 'clicked' : 'unclicked')
    }
    
  return (
    <IconContainer onClick={MenuClicked} className='burguer-menu'>
        <IconLine className={isMenuClicked}>

        </IconLine>
        <IconLine className={isMenuClicked}>
            
        </IconLine>
        <IconLine className={isMenuClicked}>
            
        </IconLine>
    </IconContainer>
  )
}
