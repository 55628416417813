import styled from "styled-components";

export const StyledAside = styled.aside`
    padding: 2em 5em;
    flex: 6 1 0;
    
    

    @media only screen and (max-width: 975px){
        padding: 2em 1.5em 5em 1.5em;
    }
`;