import { Link } from "react-router-dom";
import { Footer } from "../components/lading/Footer";
import { Header } from "../components/lading/Header";
import { Container } from "react-bootstrap";
import { HowList } from "../components/styles/landing/HowList.styled";
import { LandingText, LandingTitle } from "../components/styles/landing/LandingContainer.style";

export const HowWeWork = ()=>{
    return (
        <>
            <Header/>
            <Container style={{gap: 36, marginTop:48, marginBottom: 100}} className='d-flex flex-column  px-3'>
            <LandingText className="mb-5"><Link className="text-decoration-none text-muted" to={'/'}>Página inicial</Link><span className="mx-3">&gt;</span>Como funcionamos</LandingText>
                <section>
                <div className="d-flex align-items-center">
                    <div className="">
                        <LandingText className="text-muted mb-1" style={{fontSize: '.875rem'}}>Revolucionamos a forma como suas compras online chegam até você</LandingText>
                        <LandingTitle className="fw-bold">Como funcionamos?</LandingTitle>
                    </div>
                </div>
                </section>
                <section>
                <HowList className="row gy-5 justify-content-between my-5">
                    <div className="col-12 col-lg-5">
                        <li className="d-flex">
                            <div>
                                <h5 className="fw-bold  mt-2">Cadastro das viagens pelos Viajantes</h5>
                                <LandingText className="text-muted mt-4 mb-0">
                                Os viajantes parceiros podem cadastrar suas viagens em nossa plataforma. Escolhendo qual o volume e peso que desejam disbonibilizar em suas bagagens. Após o cadastro da viagem, a T3 Lake realizará uma conferência dos dados, afim de confirmar a vericidade da viagem.
                                </LandingText>
                            </div>
                        </li>
                    </div>
                    <div className="col-12 col-lg-6">
                        <li className="d-flex">
                            <div>
                                <h5 className="fw-bold mt-2">Busca de viagens pelos E-commerces</h5>
                                <LandingText className="text-muted mt-4  mb-0">
                                Uma vez que a viagem esteja verificada, as viagens passam a fazer parte do universo de pesquisa dos e-commerce's parceiros. O e-commerce parceiro, por sua vez, busca pelas viagens que atendam seus interesses e demandas.
                                </LandingText>
                            </div>
                        </li>
                    </div>

                    <div className="col-12 col-lg-5">
                        <li className="d-flex">
                            <div>
                                <h5 className="fw-bold mt-2">Coleta de pacote pelos Entregadores</h5>
                                <LandingText className="text-muted mt-4  mb-0">Após a contrartação e pagamento do serviço, nossos entregadores parceiros farão a coleta e conferencia do produto no e-commerce e entrega do mesmo ao viajante</LandingText>
                            </div>
                        </li>
                        
                    </div>
                    <div className="col-12 col-lg-6">
                        
                        <li className="d-flex">
                            <div>
                                <h5 className="fw-bold mt-2">Transporte do pacote na viagem</h5>
                                <LandingText className="text-muted mt-4  mb-0">O viajante contratado acomodará o pacote cuidadosamente em sua bagagem, garantindo que esteja seguro e protegido. Em seguida, ele realizará sua viagem conforme o itinerário previsto.</LandingText>
                            </div>
                        </li>
                    </div>

                    <div className="col-12 col-lg-5">
                        <li className="d-flex">
                            <div>
                                <h5 className="fw-bold mt-2">Etapa final da entrega do pacote</h5>
                                <LandingText className="text-muted mt-4  mb-0">Após o viajante chegar ao seu destino, ele entregará a encomenda a um entregador local. Este entregador então recolherá o pacote e será responsável por realizar a etapa final da entrega, levando a encomenda diretamente ao destinatário.</LandingText>
                            </div>
                        </li>
                    </div>
                </HowList>
                </section>
            </Container>
            <Footer/>
        </>
    )
}