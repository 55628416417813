import { Link } from "react-router-dom";
import { Footer } from "../components/lading/Footer";
import { Header } from "../components/lading/Header";
import { Container } from "react-bootstrap";
import { MdCall, MdEmail } from "react-icons/md";
import { CtaButtonSec } from "../components/styles/landing/LandinButtons.style";
import axios from "axios";
import { useState } from "react";
import ReactLoading from 'react-loading';
import { LandingText, LandingTitle } from "../components/styles/landing/LandingContainer.style";

export const Contact = ()=>{
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true);

        const config = {
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json'
            }
        }

        const data = {
            subject: e.target.subject.value,
            from: e.target.email.value,
            content: e.target.msg.value,
        }
        
        const uri = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        

        axios.post(`${uri}/send_email`, data, config)
        .then(res=>{
            setLoading(false);
            alert('Email enviado com sucesso');
        })
        .catch(e=>{
            setLoading(false);
            alert('Ocorreu um erro, tente novamente ou tente mais tarde.');
        });
    }
    return (
        <>
            <Header/>
            <Container style={{gap: 36, marginTop:48, marginBottom: 100}} className='d-flex flex-column  px-3'>
            <LandingText className="mb-5"><Link className="text-decoration-none text-muted" to={'/'}>Página inicial</Link><span className="mx-3">&gt;</span>Contato</LandingText>
                
                <section>
                    <div className="row">
                        <div className="col-12 col-xl-6" style={{paddingRight: '4rem'}}>
                            <LandingText className="text-muted mb-1" style={{fontSize: '.875rem'}}>Queremos saber como podemos ajudar</LandingText>
                            <LandingTitle className="fw-bold">Entre em contato!</LandingTitle>
                            <LandingText className="text-muted">Se tiver alguma dúvida, feedback ou simplesmente quiser compartilhar suas ideias, ficaremos felizes em ouvir.
                                Preencha o formulário ao lado e nossa equipe responderá o mais breve possível.</LandingText>

                            <div style={{marginTop: '2.5rem'}}>
                                <div className="d-flex align-items-center">
                                    <MdCall className="me-3" style={{fontSize: '2.5rem', color: '#35B7E0'}}/> 
                                    <LandingText className="mb-0 text-muted">xx xxxxx-xxxx</LandingText>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                        <form onSubmit={handleSubmit} className="d-flex flex-column gap-3 col" style={{marginTop: '5.25rem'}}>
                            <div className="bg-white">
                                <label className="fw-bold text-muted">E-mail</label>
                                <input required placeholder="E-mail" type="email" id="email" className="form-control p-3 mt-2"/>
                            </div>
                            <div>
                                <label className="fw-bold text-muted">Assunto</label>
                                <input required placeholder="Assunto" type="text" id="subject" className="form-control p-3 mt-2"/>
                            </div>
                            <div>
                                <label className="fw-bold text-muted">Mensagem</label>
                                <textarea placeholder="Mensagem" id="msg" className="form-control p-3 mt-2"/>
                            </div>
                            <div className="mt-3 text-end">
                                <CtaButtonSec disabled={loading?true:false} type="submit" className="py-3 align-items-center" style={{paddingRight: '4.25rem', paddingLeft: '4.25rem'}}>
                                    {loading ? 
                                        <ReactLoading type='bubbles' color='#fff' height='1.5rem' width='2rem'/> 
                                        : 'Enviar'
                                    }
                                </CtaButtonSec>
                            </div>
                        </form>
                        </div>
                        
                    </div>
                </section>
            </Container>
            <Footer/>
        </>
    )
}