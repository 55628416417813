import styled from "styled-components";

export const LandingCard = styled.div`
    padding: ${props=>props.about ? '2.5rem 2rem':'2rem'};
    border: 1px solid #35B7E0;
    box-shadow: -5px 3px 0px 2px #35B7E0;
    border-radius: 8px;
    height: 100%;
    max-width: 450px;
    @media only screen and (max-width: 1200px) {
        p{
            font-size: 14px;
        }
    }
    
    svg{
        font-size: 7rem;
        color: #35B7E0;
    }

    .subtitle{
        color: #35B7E0;
    }
`
export const LandingContainerCards = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 4rem;

   @media  only screen and (max-width: 990px) {
    gap: 32px;
   }
`

export const CommentCardStyle = styled.div`
    position: relative;

    .quote{
        position: absolute;
        font-size: 6rem;
        color: #35B7E0;
    }

    .info {
        margin-bottom: 2rem;
        margin-top: 5rem;
    }
`

export const CountCardStyle = styled.div`
    padding-right: 4.25rem !important;

    h2{
        color: #2B6CBF;
    }
`