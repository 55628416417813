import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {RegTravelForm} from './RegTravelForm';
import { RegTravelBagOptions } from './RegTravelBagOptions';
import { RegTravelLoc } from './RegTravelLoc';
import { StepHeader } from '../StepHeader';

export const EditMyTravelModal = ({show, handleClose, myTravel}) => {
  const [step, setStep] = useState(0);
  const [travel, setTravel] = useState([]);
  const [bags, setBags] = useState([]);

  const RegTravelControl = ({step, setStep, travel, setTravel, bags, setBags, hideEditModal})=>{
        if(step === 0){
            setTravel(0);
            setBags(0);
        }
        
        switch(step){
            case 0: 
                return <RegTravelForm stepSetter={setStep} travel={myTravel} setTravel={setTravel} />
            case 1:
                return <RegTravelBagOptions setStep={setStep} setBags={setBags} bags={myTravel.bagagens ?? bags}/>
            case 2:
                return <RegTravelLoc edit hideEditModal={hideEditModal} setStep={setStep} prevTravel={myTravel} travel={travel} setTravel={setTravel} bags={bags}/>
            default:
                break;
        }
    }

  return (
    <Modal
        show={show} 
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
    >
        <div  className="pt-3 px-4">
            <Modal.Header closeButton>
                <Modal.Title className="fw-bold">Editar viagem</Modal.Title>
            </Modal.Header>
        </div>
        <Modal.Body className="text-center">
          <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '1.5rem'}}>
          <StepHeader activeStep={step} setActiveStep={setStep} title={'Cadastrar viagem'} steps={['Informações', 'Bagagem', 'Endereços' ]}>
            <RegTravelControl step={step} setStep={setStep} travel={travel} setTravel={setTravel} setBags={setBags} bags={bags}/>
            </StepHeader>
          </div>
        </Modal.Body>
    </Modal>
  )
}
