import styled from "styled-components";

export const ResultCardStyle = styled.div`
    border: 0;
    border-radius: 8px;
    border-bottom: 2px solid #35B7E0;
    box-shadow: -1px 4px 8.1px 0px #09CAED1A;
    flex: 0 0 calc(33.33% - 1rem);
    transition: .2s ease-in-out;
    cursor: pointer;

    &:hover{
        transform: translateY(-.5rem);
    }

    .points {
        color: #35B7E0;
    }

    .user-picture {
        margin-right: .75em;
    }

    .airplane {
        font-size: 3rem;
        color: #35B7E0;
    }

    .travel-info {
        h6 {
            color: #35B7E0;
        }
    }

    .price{
        color: #35B7E0;
    }
`