import styled from "styled-components";

export const LoginButton = styled.button`
    background-color: #35B7E0;
    border-radius: 6px;
    color: white;
    border: none;
    padding: 1em 0;
    width: 100%;
    &:disabled{
        background-color: #cccccc;
    }
    &:hover:not(:disabled){
        box-shadow: 0 0 0 1px #35B7E0 inset;
        background-color: white;
        color: #35B7E0;
    }
`;

export const LoginButtonReturn = styled.button`
    box-shadow: 0 0 0 1px #3874CB inset;
    border: none;
    color: #3874CB;
    border-radius: 6px;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: transparent;
    width: 100%;

    &:hover{
        color: white;
        background-color: #3874CB;
    }
`;



