import { useContext, useState } from "react";
import { StyledAside } from "../components/styles/Aside.styled";
import { LoginForm } from "../components/styles/LoginForm.styled";
import { LoginButton } from "../components/styles/LoginButton.styled";
import axios from "axios";
import ReactLoading from "react-loading";
import { AuthContext } from "../Contexts/AuthContext";
import { LogoNavBar } from "../components/LogoNavBar";
import { BoxAsideContainer } from "../components/BoxAsideContainer";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Link } from "react-router-dom";
import RegisterBox from "../images/register_box.svg";
import TextInput from "../components/forms/TextInput";
import PasswordInput from "../components/forms/PasswordInput";
import SelectInput from "../components/forms/SelectInput";

export const Register = () => {
  const [checkPasswordConfirm, setPasswordConfirm] = useState(true);
  const [checkEmail, setEmailChecked] = useState(true);
  const { user, setAuth, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [confirmPassVis, setConfirmPassVis] = useState(false);
  const [primaryPassVis, setPrimaryPassVis] = useState(false);
  const navigate = useNavigate();

  const handlePassChange = (e) => {
    const passwordField = document.querySelector("#pass").value;
    if (e.target.value === passwordField) {
      setPasswordConfirm(true);
    } else {
      setPasswordConfirm(false);
    }
  };

  function handleEmailError(error, e) {
    if (error.response) {
      console.error(error);
      setEmailChecked(false);
      e.target.email.focus();
    }
  }

  const registerUser = async (e, type) => {
    const form = new FormData();

    let campoNulo;

    switch (type) {
      case "empresas":
        campoNulo = { cnpj: null };
        break;
      case "entregadores":
        campoNulo = { cnh: null };
        break;
      default:
        break;
    }

    form.append("email", e.target.email.value);
    form.append("senha", e.target.pass.value);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/${type}`,
        form,
        config
      )
      .then(async (res) => {
        const userData = res.data;
        await setUser({ ...userData, ...campoNulo });

        axios
          .post(
            `${
              process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_API_URL_DEV
                : process.env.REACT_APP_API_URL_PROD
            }/login/${type}`,
            form,
            config
          )
          .then((resLogin) => {
            const token = resLogin.data.token;
            localStorage.setItem("token", token);
            setAuth(token);
          })
          .catch((error) => error);
      })
      .catch((error) => handleEmailError(error, e));

    navigate("/verificar-email");
  };

  const handlePrimaryPassVis = () => {
    if (primaryPassVis) {
      setPrimaryPassVis(false);
      return;
    }

    setPrimaryPassVis(true);
  };

  const handleConfirmPassVis = () => {
    if (confirmPassVis) {
      setConfirmPassVis(false);
      return;
    }
    setConfirmPassVis(true);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!checkPasswordConfirm) {
      e.target.userpassconfirm.focus();
      return;
    }

    switch (e.target.type.value) {
      case "1":
        registerUser(e, "viajantes");
        break;
      case "2":
        registerUser(e, "empresas");
        break;
      case "3":
        registerUser(e, "entregadores");
        break;
      default:
        e.target.type.focus();
        break;
    }
  };

  return (
    <section style={{ minHeight: "100%" }} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder ">Crie sua conta!</h2>
            <span className="text-muted">
              Faça o cadastro para acessar o sistema
            </span>
          </div>

          <LoginForm  style={{ alignItems: "normal" }} className="mt-5" onSubmit={handleRegister} method="POST">
            <div className="row">
              <div className="col-12">
                <TextInput
                  id="email"
                  name="useremail"
                  type="email"
                  label="E-mail"
                  placeholder="Insira seu endereço de e-mail"
                  required
                  autoComplete="useremail"
                />
                <span className={`text-danger ${checkEmail ? "d-none" : ""}`}>
                  Email já cadastrado.
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <PasswordInput
                  id="pass"
                  name="userpass"
                  label="Senha"
                  toogleVision={primaryPassVis}
                  handleToogleVision={handlePrimaryPassVis}
                  placeholder="Insira sua senha de acesso"
                  autoComplete="password"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <PasswordInput
                onChange={handlePassChange}
                  id="passconfirm"
                  name="userpassconfirm"
                  label="Confirme sua senha"
                  toogleVision={confirmPassVis}
                  handleToogleVision={handleConfirmPassVis}
                  placeholder="Insira a mesma senha"
                  autoComplete="same-password"
                  required
                />
                <span
                  className={`text-danger ${
                    checkPasswordConfirm ? "d-none" : ""
                  }`}
                >
                  Senhas não coincidem.
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <SelectInput
                  id="type"
                  name="type"
                  options={[
                    { value: 1, texto: "Viajante" },
                    { value: 2, texto: "Empresa" },
                    { value: 3, texto: "Entregador" },
                  ]}
                  placeholder="Escolha seu tipo de conta"
                  label="Tipo de conta"
                  required
                />
              </div>
            </div>

            <LoginButton disabled={loading || !checkPasswordConfirm} type="submit" className="w-100">
              {loading ? (
                <ReactLoading type="spin" color="#fff" height="5%" width="5%" />
              ) : (
                "Próximo"
              )}
            </LoginButton>
            <p className="mt-4">
              Já tem uma conta?{" "}
              <Link to="/login" className="text-decoration-none">
                Fazer login.
              </Link>
            </p>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
};
