import styled from "styled-components";

export const StyledSearchTravelerForm = styled.form`
    margin-top: 3rem;
    color: #68686E;
    height: 100%;

    input{
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .title {
        font-weight: bold;
        color: #35B7E0;
    }

    .product{
        input {
            max-width: 5.5rem;
        }
    }
    
    label {
        margin-bottom: .5rem;
    }

   
    
    .btn-search{
        padding: 1rem 4rem;
        background-color: #35B7E0;
        font-weight: 600;
        margin-top: 3.125rem;
        color: white;

        &:hover{
            background-color: white;
            color: #35B7E0;
            box-shadow: 0 0 0 1px inset #35B7E0;
        }
    }
`