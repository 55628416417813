import Modal from "react-bootstrap/Modal";
import { SearchTravelerDescStyle } from "../styles/enterprise/SearchTravelerDescStyle.style"
import axios from "axios";
import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { useState } from "react";
import { Payment, StatusScreen } from "@mercadopago/sdk-react";
import { 
    onError, 
    onReady, 
    status_initialization,
    payment_initialization,
    payment_customization 
} from "../../services/MercadoPagoService";
import { LoginButton } from "../styles/LoginButton.styled";
import { MercadoPagoInstance } from "@mercadopago/sdk-react/mercadoPago/initMercadoPago";

export const SearchTravelerDescContainer = ({setStep, setTravelSolic, travelSolic, selectedTravel, amount})=>{
    const {user, auth} = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [pix, setPix] = useState(null);
    const intervalRef = useRef();
    
    const handleClose = () => setShow(false);
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        },
    };


    const startCheckout = ()=>{
        const descContainer = document.getElementById('desc');
        if(descContainer.value.length != 0){
            setShow(true);
        } else {
            descContainer.focus();
        }
    }

    const solicRequest = ()=>{
        axios.post(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/solic_viajante`, travelSolic, config)
        .then(res=>{
            axios.post(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/solic_viajante/cad_viagem/${res.data.id}`, {cad_id: selectedTravel.id}, config)
            .then(res=>{
                setShow(false);
                setStep(0);
            })
            .catch(err=>(err))
        })
        .catch(err=>(err))
    }
    
    const handleSubmit = async ( { selectedPaymentMethod, formData }) => {
        
        axios.post(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/cadastro_viagens/payment`, formData, config)
        .then((response) => {
            if(response.data.data.payment_method.id === 'pix'){
                setPix({
                    payment_id: response.data.data.id,
                    done: 'pending'
                });
                return
            };
        })
        .catch((error) => {
            console.log(error)
        });
    }

    const handleChangeDesc = (e)=>{
        setTravelSolic({
            ...travelSolic,
            desc: e.target.value
        });
    }

    const requestPayment = () => {
        if (pix && pix.done === 'pending') {
            axios.get(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/cadastro_viagens/payment/${pix.payment_id}`, config)
                .then(res => {
                    const newStatus = res.data[0].status;

                    if (newStatus !== 'pending') {
                        clearInterval(intervalRef.current);
                        intervalRef.current = null;
                        setPix(prevPix => ({
                            payment_id: res.data[0].id,
                            done: newStatus
                        }));
                    }
                });
        }
    };

    useEffect(() => {''
        if (pix && pix.done === 'pending' && !intervalRef.current) {
            intervalRef.current = setInterval(requestPayment, 2000);
            return () => clearInterval(intervalRef.current);
        }
    }, [pix]);

    return (
        <SearchTravelerDescStyle className="d-flex flex-column justify-content-between mt-5 flex-grow-1">
            <div>
                <h5 className="title fw-bold">Descreva o produto que será enviado <span className="help">?</span></h5>
                <textarea className="form-control desc" id="desc" name="desc" onChange={handleChangeDesc} placeholder="Descrição do produto"></textarea>
            </div>
            
            <div className="d-flex flex-column flex-md-row justify-content-end mt-4 gap-3">
                <button className="btn btn-back" onClick={_=>setStep(0)}>Voltar</button>
                <button type="button" onClick={startCheckout} className="checkout-btn btn">Checkout</button>
            </div>

            <Modal 
                show={show} 
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                size={pix ? 'md':"lg"}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Checkout</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {pix ?
                    <>
                        <StatusScreen 
                            initialization={status_initialization(pix.payment_id)}
                        />
                        {pix.done === 'approved' ? 
                        <LoginButton type="button" onClick={solicRequest} className="fw-bold w-100">Finalizar</LoginButton>
                        :''}
                        
                    </>
                    :
                    <>
                        <Payment
                            initialization={payment_initialization(0.01, user)}
                            customization={payment_customization}
                            onSubmit={handleSubmit}
                            onReady={onReady}
                            onError={onError}
                        />
                    </>
                    }

                </Modal.Body>
            </Modal>

        </SearchTravelerDescStyle>
    )
}