import ReactInputMask from "react-input-mask";
import { StyledAside } from "../../components/styles/Aside.styled";
import { LoginForm } from "../../components/styles/LoginForm.styled";
import { Required } from "../../components/styles/Required.styled";
import { Tooltip } from "../../components/styles/Tooltip.styled";
import { LogoNavBar } from "../../components/LogoNavBar";
import { BoxAsideContainer } from "../../components/BoxAsideContainer";
import {
  LoginButton,
  LoginButtonReturn,
} from "../../components/styles/LoginButton.styled";
import { cnpj } from "cpf-cnpj-validator";
import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../Contexts/AuthContext";
import RegisterBox from "../../images/register_box.svg";
import TextInput from "../../components/forms/TextInput";

export const RegisterEnterpriseCnpj = ({ setCompanyData }) => {
  const [validCnpj, setValidCnpj] = useState(true);
  const [cnpjError, setCpnjError] = useState({});
  const { user, setUser, auth, setAuth } = useContext(AuthContext);
  const uri =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth}`,
    },
  };

  const key_cnpj =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_CPFCNPJ_DEV_KEY
      : process.env.REACT_APP_CPFCNPJ_PROD_KEY;

  const handleCnpjChange = (e) => {
    setCpnjError({ cnpjError, isSet: false });
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNumbers.length === 14) {
      if (!cnpj.isValid(onlyNumbers) || e.target.value === "") {
        setValidCnpj(false);
      } else {
        axios
          .get(`https://api.cpfcnpj.com.br/${key_cnpj}/5/${onlyNumbers}`)
          .then((res) => {
            setCompanyData({
              cep: res.data.matrizEndereco.cep,
              num: res.data.matrizEndereco.numero,
              fantasy: res.data.fantasia,
              name: res.data.razao,
            });
          })
          .catch((error) => console.log(error));
        setValidCnpj(true);
      }
    }
  };

  const handleCnpjCheck = (e) => {
    e.preventDefault();

    const cnpj = e.target.cnpj;
    const onlyNumbersCnpj = cnpj.value.replace(/[^0-9]/g, "");

    if (!validCnpj) {
      cnpj.focus();
    }

    const fd = new FormData();
    fd.append("cnpj", onlyNumbersCnpj);

    axios
      .patch(`${uri}/empresas/${user.id}`, fd, config)
      .then(async (res) => {
        await setUser({ ...user, cnpj: res.data.cnpj });
      })
      .catch((e) => {
        if (e.response.data.errors.cnpj) {
          setCpnjError({
            message: "CNPJ já cadastrado",
            isSet: true,
          });
        }
      });
  };

  const getBack = () => {
    axios.post(`${uri}/login/logout`, {}, config);
    setUser({});
    setAuth(false);
  };

  return (
    <section style={{minHeight: '100%'}} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Vamos verificar seu CNPJ</h2>
            <span className="text-muted">Insira o CPNJ da sua empresa</span>
          </div>

          <LoginForm className="mt-5" onSubmit={handleCnpjCheck} method="post">
            <div className="w-100">
              <TextInput
                type="cnpj"
                name="cnpj"
                id="cnpj"
                placeholder="Insira o CNPJ"
                onChange={handleCnpjChange}
                required={true}
                label="CNPJ da sua empresa"
                mask={"99.999.999/9999-99"}
              />

              <span className={`text-danger ${validCnpj ? "d-none" : ""}`}>
                CNPJ inválido!
              </span>
              <span className={`text-danger ${cnpjError ? "" : "d-none"}`}>
                {cnpjError.message}
              </span>
            </div>

            <div className="d-flex justify-content-between w-100 gap-2 mt-2">
              <LoginButtonReturn type="button" onClick={getBack}>
                Voltar
              </LoginButtonReturn>
              <LoginButton type="submit">Cadastrar</LoginButton>
            </div>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
};
