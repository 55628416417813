import { Link } from "react-router-dom";
import { Footer } from "../components/lading/Footer";
import { Header } from "../components/lading/Header";
import { Container } from "react-bootstrap";
import { MdCall, MdEmail } from "react-icons/md";
import { CtaButtonSec } from "../components/styles/landing/LandinButtons.style";
import axios from "axios";
import { useState } from "react";
import ReactLoading from 'react-loading';
import { LandingText, LandingTitle } from "../components/styles/landing/LandingContainer.style";
import TrackingStatus from "../components/TrackingStatus";

export const Tracking = ()=>{
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true);

        const config = {
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json'
            }
        }

        const data = {
            
        }
        
        const uri = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    }
    return (
        <>
            <Header/>
            <Container style={{gap: 36, marginTop:48, marginBottom: 100}} className='d-flex flex-column  px-3'>
            <LandingText className="mb-5"><Link className="text-decoration-none text-muted" to={'/'}>Página inicial</Link><span className="mx-3">&gt;</span>Rastreio</LandingText>
                
                <section>
                    <div className="row">
                        <div className="col-12 col-xl-6" style={{paddingRight: '4rem'}}>
                            <LandingText className="text-muted mb-1" style={{fontSize: '.875rem'}}>Deseja acompanhar o rastreamento do pacote?</LandingText>
                            <LandingTitle className="fw-bold">Rastreamento</LandingTitle>
                            <form onSubmit={handleSubmit} className="d-flex flex-column gap-3 col" style={{marginTop: '1.25rem'}}>
                            
                            <div>
                                <label className="fw-bold text-muted">Digite o código de rastreio</label>
                                <input required placeholder="T3BRXXXXXXXXXXXXXX" type="text" id="subject" className="form-control p-3 mt-2"/>
                            </div>
                            <div className="mt-3">
                                <CtaButtonSec disabled={loading?true:false} type="submit" className="py-3 align-items-center" style={{paddingRight: '4.25rem', paddingLeft: '4.25rem'}}>
                                    {loading ? 
                                        <ReactLoading type='bubbles' color='#fff' height='1.5rem' width='2rem'/> 
                                        : 'Consultar'
                                    }
                                </CtaButtonSec>
                            </div>
                            
                        </form>

                           
                        </div>
                        <div className="col-12 col-xl-6 mt-5 mt-xl-0">
                        <TrackingStatus active={1}/>
                        </div>
                        
                    </div>
                </section>
            </Container>
            <Footer/>
        </>
    )
}