import styled from 'styled-components';

export const RegTravelFormStyle = styled.form`
    input{
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    
    .rw-widget-picker{
        border: none !important;
        border-radius: 4px !important;
    }

    .rw-state-focus{
        box-shadow: none !important;
    }
    
    .title {
        font-weight: bold;
        font-size: 1.25rem;
        color: #35B7E0;
    }
    
    .btn-next {
        font-family: 600;
        padding: 1rem 3.75rem;
        background-color: #35B7E0;

        &:hover{
            color: #35B7E0 !important;
            background-color: white;
            box-shadow: 0 0 0 1px #35B7E0 inset;
        }
    }

    .date{
        position: relative;
        input{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button{
            border: 1px #dee2e6 solid;
            border-radius: 8px;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`