import { useContext } from 'react';
import { Header } from '../components/Header';
import {MenuSideBar} from '../components/MenuSideBar';
import { PersonalInfoContainer } from '../components/PersonalInfoContainer';
import { AuthContext } from '../Contexts/AuthContext';
import { AddressInfoContainer } from '../components/AddressInfoContainer';

export const Profile = ()=>{
    const {user, setUser} = useContext(AuthContext);

    return (
       
            <MenuSideBar profile={true} title={'Perfil'}>
            <div className="" >
                <div className='d-flex flex-column' style={{ gap: '2.375rem'}}>
                    <PersonalInfoContainer setUser={setUser} user={user}/>
                    <hr/>
                    <AddressInfoContainer user={user}/>
                </div>
            </div>
            </MenuSideBar>
        
    )
} 