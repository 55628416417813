import { useState } from "react"
import { MenuSideBar } from "../../components/MenuSideBar"
import { StepHeader } from "../../components/StepHeader"
import { RegTravelForm } from "../../components/traveler/RegTravelForm";
import { RegTravelBagOptions } from "../../components/traveler/RegTravelBagOptions";
import { RegTravelLoc } from "../../components/traveler/RegTravelLoc";

const RegTravelControl = ({step, setStep, travel, setTravel, bags, setBags})=>{
    if(step === 0){
        setTravel(0);
        setBags(0);
    }
    
    switch(step){
        case 0: 
            return <RegTravelForm stepSetter={setStep} setTravel={setTravel} />
        case 1:
            return <RegTravelBagOptions setStep={setStep} setBags={setBags} bags={bags}/>
        case 2:
            return <RegTravelLoc setStep={setStep} travel={travel} setTravel={setTravel} bags={bags} setBags={setBags}/>
        default:
            break;
    }
}


export const RegisterTravel = ()=>{
    const [step, setStep] = useState(0);
    const [travel, setTravel] = useState([]);
    const [bags, setBags] = useState([]);

    return (
        <>
            <MenuSideBar flight={true} title={'Cadastrar viagem'}>
            <StepHeader activeStep={step}  steps={['Informações', 'Bagagem', 'Endereços' ]}>
            <RegTravelControl step={step} setStep={setStep} travel={travel} setTravel={setTravel} setBags={setBags} bags={bags}/>
            </StepHeader>
            </MenuSideBar>
        </>
    )
}