
import { Avatar } from "@mui/material";
import { CardBorder } from "../CardBorder.styled";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LoginButtonReturn } from "../LoginButton.styled";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

export const MyDeliveryCard = ({travel, type})=>{
    const imgUri = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_STORAGE_URL_DEV : process.env.REACT_APP_API_STORAGE_URL_PROD;
    const navigate = useNavigate();
    const [locInfo, setLocInfo] = useState([]);

    const getCep = ()=>{
        axios.get(`https://opencep.com/v1/${travel.cep_encomenda}`)
        .then(res=>{
            setLocInfo(res.data);
        })
        .catch(e=>e);
    }

    useEffect(()=>{
        if(locInfo.length === 0)
        {
            getCep();
        }
    });
    return (
        <CardBorder borderColor="#35B7E0" borderDirection="bottom"  className="col-12 col-lg">
           <div className="d-flex gap-3 mb-3">
           <div className="d-flex justify-content-center align-items-center">
           <div className="img-avatar" 
    
            style={{
                backgroundImage: travel.transportador.imagem ? 
                `url(${imgUri}/${travel.transportador.imagem})`
                :`url(https://placehold.co/40x40)`
            }}
            
            ></div>
            </div>
            <div> 
            <p style={{color: '#4B4B53', fontWeight: 600}} className="mb-0 fs-16">{travel.transportador.nome}</p>
           <p style={{color: '#7C7C83'}}className="mb-0 fs-16" >Viajante</p>
           </div>
           </div>
           <div className="d-flex flex-column gap-3 mb-3">
            <div className="partida d-flex gap-4">
                <div>
                    <FiberManualRecordIcon style={{fontSize: '1.7rem', color: '#35B7E0'}}/>
                </div>
                <div>
                <p style={{color: '#4B4B53', fontWeight: 600}} className="mb-0 ms-2 fs-16">{locInfo.logradouro}, {travel.num_encomenda}</p>
                </div>
            </div>
            <div className="distancia d-flex gap-4">
                <div style={{width: 24}} className="d-flex flex-column gap-2 align-items-center" >
                <FiberManualRecordIcon style={{fontSize: '.6rem', color: '#35B7E0'}}/>
                <FiberManualRecordIcon style={{fontSize: '.6rem', color: '#35B7E0'}}/>
                </div>
                <div>
                <p style={{color: '#35B7E0', fontWeight: 600}} className="mb-0 ms-2 fs-16">1,3km</p>
                </div>
            </div>
            <div className="destino d-flex gap-4">
                <div >
                    <FmdGoodIcon style={{fontSize: '1.7rem', color: '#35B7E0'}}/>
                </div>
                <div>
                <p style={{color: '#4B4B53', fontWeight: 600}} className="mb-0 ms-2 fs-16">{travel.rua_entrega}, {travel.num_entrega}</p>
                </div>
            </div>
            
           </div>
           <div className=" d-flex flex-column flex-sm-row justify-content-between border-top pt-3 gap-3">
            <div className="w-100 d-flex justify-content-between flex-sm-column">
            <p style={{color: '#7C7C83', fontWeight: 500, fontSize: 14}} className="mb-0 fs-16">Valor a receber</p>
            <p style={{color: '#35B7E0', fontWeight: 600, fontSize: 18}}className="mb-0">R$ 50,00</p>
            </div>
            <div>
            <span>
                <LoginButtonReturn onClick={()=> navigate('/deliverer/deliveriesSingle', {state: {type: type, travel:travel, cep: locInfo}})} className="py-2 px-3 text-nowrap fs-16">
                  Ver mais <ArrowForwardIosIcon fontSize="24" />
                </LoginButtonReturn>
              </span>
            </div>
           </div>
           
        </CardBorder>
    )
}