import { useContext, useEffect, useState } from "react"
import { MdNotifications } from "react-icons/md"
import { AuthContext } from "../Contexts/AuthContext"
import { HeaderStyle } from "./styles/HeaderStyle.styled";
import AvatarMenu from "./AvatarMenu";

export const Header = ({title, width})=>{
    const {user} = useContext(AuthContext);
    const [type, setType] = useState('');
    const URI_STORAGE = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_STORAGE_URL_DEV : process.env.REACT_APP_API_STORAGE_URL_PROD;

    useEffect(()=>{
        if(user.hasOwnProperty('cpf')){
            setType('Viajante');
        } 

        if(user.hasOwnProperty){
            setType('Entregador');
        }

        if(user.hasOwnProperty('cnpj')){
            setType('E-commerce');
        }
    }, [user])

    return (
        <HeaderStyle className="d-flex justify-content-between py-3">
            <div className="d-flex align-items-center">
                <h4 className="fw-bold mb-0">{title}</h4>
            </div>
            
            <div className="d-flex align-items-center gap-3">
                <MdNotifications className="notify"/>
                {width < 1050 ?
                
                <AvatarMenu nome={user.nome} type={type} >
                {user.imagem ?
                <div className="img" style={{backgroundImage: `url(${URI_STORAGE}/${user.imagem}`}}></div>
                :
                <img alt="Imagem do usuário" className="rounded-circle p-1" src="https://placehold.co/40x40"/>
                }
                </AvatarMenu>
                :
                <>
                <AvatarMenu nome={user.nome} type={type} >
                {
                user.imagem ?
                
                    <div className="img" style={{backgroundImage: `url(${URI_STORAGE}/${user.imagem}`}}></div>
                    :
                    <img alt="Imagem do usuário" className="rounded-circle p-1" src="https://placehold.co/40x40"/>
                    
                    }
                </AvatarMenu>
                    <div>
                    <p className="fw-bold mb-0">{user.nome ?? '(Nome completo)'}</p>
                    <p className="mb-0">{type}</p>
                </div>
                </>
                }

                
            </div>
        </HeaderStyle>
    )
}