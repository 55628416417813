import React from 'react'
import { Required } from '../../styles/Required.styled'
import { Tooltip } from '../../styles/Tooltip.styled'

export default function SelectInput({id, name, options, placeholder, label, required}) {
  return (
    <div className='w-100'>
     <label className='form-label d-flex' htmlFor={id}>{label}
            {
            required ? 
            <Required>
                <Tooltip>Esse campo é obrigatório.</Tooltip>
                <span className='text-danger required'>*</span>
            </Required>
            : null
            }
            
        </label>
    <select className="form-select p-3 text-muted" aria-label="default-select" id={id} name={name} required={required}>
        <option value={false} selected disabled hidden>{placeholder}</option>
        {options.map((option) =>  <option value={option.value}>{option.texto}</option> )}
    </select>
    </div>
  )
}
