import styled from 'styled-components';

export const BagCardStyle = styled.div`
    color: #68686E;
    box-shadow: -1px 4px 8.1px 0px #09CAED1A;
    border: 0;
    border-radius: 6px;
    border-bottom: 2px solid #35B7E0;
    flex: 0 0 (33.3% - 1rem);
    min-width: 300px;

    .title{
        font-size: 1.125rem;
    }

    .part{
        font-size: .75rem;
    }

    .label{
        font-size: .875rem;
    }

    .svg-container{
        svg {
            width: 6.25rem;
            height: 6.25rem;
            fill: ${(props)=>(`url(#gradient-${props.gradientid})`)};
        }
    }
    
    .w-input{
        border-radius: 6px;
        border: 1.5px solid #DFDFE0;
        max-width: 4.5rem;
    }

    .s-input{
        -webkit-appearance: none;
        appearance: none;
        min-width: 15.75rem;
        border-radius: 8px;
        max-height: .25rem;
        background: rgba(255, 255, 255, 0.6);
        background-image: linear-gradient(#35B7E0, #35B7E0);
        background-size: ${(props)=>(props.part ? `${props.part}% 100%`:`100%`)} ;
        background-color: #7C7C83;
        background-repeat: no-repeat;

        // Google
        &::-webkit-slider-thumb{
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: white;
            cursor: ew-resize;
            border: 1px solid #35B7E0;
        }

        &::-webkit-slider-runnable-track{
            -webkit-appearance: none;
            border: none;
            background: transparent;
        }

        // Mozzila and Edge
        &::-moz-range-thumb,
        ::-ms-thumb{
            appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: white;
            cursor: ew-resize;
            border: 1px solid #35B7E0;
        }
    }
`