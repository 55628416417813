import React from 'react';
import { Modal } from 'react-bootstrap';
import { LoginButton, LoginButtonReturn } from '../styles/LoginButton.styled';
import axios from 'axios';

export const DeleteDataModal = ({show, setShow, item, id, handleDeleteData}) => {
    
    return (
        <Modal
            show={show}
            onHide={_=>setShow(false)}
            size={'lg'}
            centered
        >
            <Modal.Header closeButton>
                <h3 className='fw-bold mb-0'>Tem certeza?</h3>
            </Modal.Header>

            <Modal.Body className='p-4'>
                <div className='p-4'>
                    <h4 className='fw-bold text-center'>Certeza que deseja excluir o item da tabela {item}, de ID: {id}?</h4>
                    <p className='text-center w-100'>Essa ação não pode ser desfeita</p>
                </div>

                <div className='d-flex mt-4 gap-3 align-items-center justify-content-center'>
                    <LoginButtonReturn onClick={_=>setShow(false)}>Cancelar</LoginButtonReturn>
                    <LoginButton onClick={handleDeleteData} className='bg-danger'>Confirmar</LoginButton>
                </div>
            </Modal.Body>
        </Modal>
    );
}
