import React from 'react'
import { Required } from '../../styles/Required.styled'
import { Tooltip } from '../../styles/Tooltip.styled'
import ReactInputMask from 'react-input-mask'

export default function TextInput({id, label, name, type = 'text', mask = null, value, required = false, placeholder, autoComplete, onChange=null, readOnly=false, onFocusDisabled=false}) {
  return (
    <div className='w-100'>
        <label className='form-label d-flex' htmlFor={id}>{label}
            {
            required ? 
            <Required>
                <Tooltip>Esse campo é obrigatório.</Tooltip>
                <span className='text-danger required'>*</span>
            </Required>
            : null
            }
            
        </label>
        <ReactInputMask
        readOnly={readOnly}
        className={`form-control ${onFocusDisabled ? 'shadow-none border-0': null}  p-3`}
        mask={mask}
        type={type}
        name={name}
        id={id}
        onBlur
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        />
        </div>
  )
}
