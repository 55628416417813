import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { StepperStyle } from './styles/StepHeaderStyle.styled';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { StepperTrackingStyle } from './styles/TrackingStatusStyle.styled';

const steps = [
    {
      label: '(status)',
      description: `Pacote enviado.`,
    },
    {
      label: '(status)',
      description:
        'Pacote enviado.',
    },
    {
      label: '(status)',
      description: `Pacote enviado.`,
    },
  ];

  const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
            backgroundColor: '#2B6CBF',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
            backgroundColor: '#2B6CBF'
        },
      },
    ],
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <AirplanemodeActiveIcon />,
      2: <AirplanemodeActiveIcon />,
      3: <AirplanemodeActiveIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  export default function TrackingStatus({active}) {
    const [activeStep, setActiveStep] = React.useState(active);
  
  
    return (
      <Box sx={{ maxWidth: 400 }}>
        <StepperTrackingStyle activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              StepIconComponent={ColorlibStepIcon}
              optional={
                <>
                  <Typography variant="caption">
                    <p className='m-0'>Local, Cidade - XX</p>
                    <p className='m-0'>00/00/00 00:00</p>
                    </Typography>
                  
                </>
              }>
                {step.label}
                
              </StepLabel>
              
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </StepperTrackingStyle>
      </Box>
    );
  }