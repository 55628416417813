import ReactInputMask from "react-input-mask";
import { StyledAside } from "../../components/styles/Aside.styled";
import { LoginForm } from "../../components/styles/LoginForm.styled";
import { Required } from "../../components/styles/Required.styled";
import { Tooltip } from "../../components/styles/Tooltip.styled";
import {
  LoginButton,
  LoginButtonReturn,
} from "../../components/styles/LoginButton.styled";
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { BoxAsideContainer } from "../../components/BoxAsideContainer";
import { LogoNavBar } from "../../components/LogoNavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RegisterBox from "../../images/register_box.svg";
import TextInput from "../../components/forms/TextInput";

export const RegisterTravelerAddress = () => {
  const [checkCep, setCep] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  const navi = useNavigate();

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleCepChange = (e) => {
    const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");
    const state = document.querySelector("#state");
    const city = document.querySelector("#city");
    const street = document.querySelector("#street");
    const nhood = document.querySelector("#nhood");

    if (onlyNumbersCep.length === 8) {
      axios
        .get(`https://opencep.com/v1/${onlyNumbersCep}`)
        .then((res) => {
          state.value = res.data.uf;
          city.value = res.data.localidade;
          street.value = res.data.logradouro;
          nhood.value = res.data.bairro;

          setCep(true);
        })
        .catch((e) => {
          setCep(false);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkCep) return e.taget.cep.focus();

    const fd = new FormData();

    fd.append("cep", e.target.cep.value.replace(/[^0-9]/g, ""));
    fd.append("rua", e.target.street.value);
    fd.append("numero", e.target.num.value);
    fd.append("bairro", e.target.nhood.value);
    fd.append("cidade", e.target.city.value);
    fd.append("estado", e.target.state.value);

    axios
      .patch(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/viajantes/${user.id}`,
        fd,
        config
      )
      .then((res) => {
        setUser({ ...res.data, cep: res.data.cep.replace(/[^0-9]/g, "") });
        navi("/login");
      })
      .catch((e) => e);
  };

  const getBack = () => {
    setUser({ ...user, cpf: false });
  };

  return (
    <section style={{minHeight: '100%'}} className="d-flex">
      <StyledAside  className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Cadastro de viajante</h2>
            <span className="text-muted">
              Preencha as informações do seu endereço
            </span>
          </div>

          <LoginForm
            style={{ alignItems: "normal" }}
            className="mt-5 "
            onSubmit={handleSubmit}
          >
            <div className="row ">
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="cep"
                  id="cep"
                  label="CEP"
                  placeholder="00000-000"
                  onChange={handleCepChange}
                  required={true}
                  mask={"99999-999"}
                />

                <span className={`text-danger ${checkCep ? "d-none" : ""}`}>
                  CEP inválido!
                </span>
              </div>
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="nhood"
                  id="nhood"
                  label="Bairro"
                  placeholder="Bairro"
                  required={true}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="state"
                  id="state"
                  label="Estado"
                  placeholder="Estado"
                  required={true}
                />
              </div>
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="city"
                  id="city"
                  label="Cidade"
                  placeholder="Cidade"
                  required={true}
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="street"
                  id="street"
                  label="Rua"
                  placeholder="Rua"
                  required={true}
                />
              </div>
              <div className="col-12 col-lg-6">
                <TextInput
                  type="number"
                  name="num"
                  id="num"
                  label="Número"
                  placeholder="00"
                  required={true}
                />
              </div>
            </div>
            <TextInput
              type="text"
              name="desc"
              id="desc"
              label="Complemento"
              placeholder="Complemento"
            />

            <div className="d-flex justify-content-between w-100 gap-2 mt-2">
              <LoginButtonReturn type="button" onClick={getBack}>
                Voltar
              </LoginButtonReturn>
              <LoginButton type="submit">Cadastrar</LoginButton>
            </div>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
};
