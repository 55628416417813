import { HiOutlineClipboardCheck } from "react-icons/hi";
import { ResultCardStyle } from "../styles/enterprise/TravelerResultCard.styled"
import { MdStar,MdStarOutline } from "react-icons/md";
import { MdFlightTakeoff } from "react-icons/md";
import {format} from "date-fns";

export const TravelerResultCard = ({traveler, dt_destiny, dt_begin, travel_key, onClick, id, selected, hr_begin, hr_destiny, price})=>{
    
    const capitalize = s => s && s[0].toUpperCase() + s.slice(1)
    const handleDateFormat = (dt)=>{

        const dtObject = new Date(dt);

        const day = dtObject.getDate()+1;
        const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        const month = months[dtObject.getMonth()];
        const year = dtObject.getFullYear();
        const date = day + " " + month + " " + year;
        return date;
    }


    return (
        <ResultCardStyle className={`p-4 flex-1 card ${selected}`} onClick={onClick} id={id}>
            <div className="d-flex align-items-center">
                <img className="rounded-circle user-picture" alt="Usuário" src="https://placehold.co/48x48" />
                <div className="ms-2">
                    <p className="mb-0">{capitalize(traveler.nome)}</p>
                    <div className="d-flex gap-1 mt-1 points">
                        <MdStar/>
                        <MdStar/>
                        <MdStar/>
                        <MdStar/>
                        <MdStar/>
                    </div>
                </div>
                <div className="h-100 d-flex ms-3">
                    <h5 className="price mt-auto fw-bold mb-0">R$ 60,00</h5>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between travel-info mt-4">
                <div>
                    <h6>Origem</h6>

                    <div>
                        <label className="fw-bold">Data</label>
                        <p>{handleDateFormat(dt_begin)}</p>
                    </div>

                    <div>
                        <label className="fw-bold">Hora</label>
                        <p>{hr_begin.replace(/:\d{2}$/, '')}</p>
                    </div>
                </div>

                <div className="airplane">
                    <MdFlightTakeoff/>
                </div>

                <div>
                    <h6>Destino</h6>

                    <div>
                        <label className="fw-bold">Data</label>
                        <p>{handleDateFormat(dt_destiny)}</p>
                    </div>

                    <div>
                        <label className="fw-bold">Hora</label>
                        <p>{hr_destiny.replace(/:\d{2}$/, '')}</p>
                    </div>
                </div>
            </div>

        </ResultCardStyle>
    )
}