import { CommentCardStyle, CountCardStyle, LandingCard } from "../styles/landing/LandingCard.style";

export const Card = ({icon, title, info})=>{
    return(
        <LandingCard className='d-flex flex-column'>
            <div className="text-center">
                {icon}
            </div>
            <div className="mt-3">
                <h5 className='text-center subtitle fw-bold'>{title}</h5>
                <p className='text-muted mt-3'>
                {info}
                </p>
            </div>
        </LandingCard>
    )
}

export const CommentCard = ()=>{
    return(
        <CommentCardStyle className='card border-0 px-4 pb-3'>
            <p className="quote">“</p>
            <p className='text-muted info'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
            sed do eiusmod tempor incididunt ut labore.</p>
            <address class="author"><a className='text-muted text-decoration-none' rel="author" href="#">John Doe</a></address>
        </CommentCardStyle>
    )
}

export const CountCard = ()=>{
    return (
        <CountCardStyle className="card border-0 p-4">
            <h2 className="mb-3 fw-bold">+00</h2>
            <p className="text-muted mb-0">Lorem ipsum dolor</p>
        </CountCardStyle>
    )
}