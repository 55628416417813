import styled from "styled-components";

export const IconContainer = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    .clicked:nth-child(1){
        transform: rotate(45deg) translate(0.4em, 0.3em);
        transition: ease-out 0.5s;
    }
    .clicked:nth-child(2){
        transform:scale(0);
        transition: ease-out 0.5s;
    }
    .clicked:nth-child(3){
        transform: rotate(135deg) translate(-0.4em, 0.3em);
        transition: ease-out 0.5s;
    }
    .unclicked{
        transform: rotate(0) translate(0);
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
    }

`;
export const IconLine = styled.div`
    width: 100%;
    height: 4px;
    background-color: black;
    border-radius: 12px;
    background-color: #35B7E0;
`;

