import styled from "styled-components";

export const HeaderStyle = styled.div`
    .notify {
        color: #35B7E0;
        font-size: 2rem;
    }
    h4{
        font-size: 2rem;
        color: #4B4B53;
        @media only screen and (max-width: 1050px) {
            font-size: 1.5rem;
        }
        @media only screen and (max-width: 570px) {
            font-size: 1rem;
        }
        @media only screen and (max-width: 375px) {
            font-size: .75rem;
        }
    }

    .img{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
`