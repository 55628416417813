import {MdWork, MdLuggage, MdClose } from "react-icons/md";
import { BagCardStyle } from "../styles/traveler/BagCardStyle.styled";
import { useEffect, useState } from "react";

export const BagCard = ({bag, bags, setBagTypes, handleUnset})=>{

    const handleSizeChange = (e)=>{
        if(e.target.value > 0){
            e.target.setCustomValidity('');
        } else {
            e.target.setCustomValidity('Exclua a bagagem para continuar, ou mude o espaço disponível.')
        };

        const newBagTypes = bags.map(mBag => {
            if (mBag.temp_id === bag.temp_id) {
              return { ...mBag, particao: e.target.value};
            }
            return mBag;
          });
          
          setBagTypes(newBagTypes);
    }

    useEffect(()=>{
        document.getElementById(`wInput${bag.temp_id}`).value = bag.peso;
    });
    
    return (
        <BagCardStyle className="card p-4" part={bag.particao} gradientid={bag.gradientId}>
            <div className="d-flex justify-content-between align-items-center">
                <h6 className="title mb-0">{bag.type === 'M' ? 'Bagagem de mão' : 'Bagagem despachada'}</h6>
                <button 
                    onClick={()=>handleUnset(bag.temp_id)}
                    type="button" 
                    className="border-0 bg-transparent text-danger p-0"><MdClose/></button>
            </div>
            <hr className="mt-3"/>
            <label className="label">Espaço disponível</label>

            <div className="svg-container text-center mt-2 mb-3">
                {bag.tipo === 'M' ? <MdWork/> : <MdLuggage/>}

                <svg className="position-absolute">
                    <defs>
                        <linearGradient id={`gradient-${bag.gradientId}`}>
                            <stop offset="0%" style={{ stopColor: '#35B7E0', stopOpacity: 1 }} />
                            <stop offset={`${bag.particao}%`} style={{ stopColor: '#35B7E0', stopOpacity: 1 }} />
                            <stop offset={`${bag.particao}%`} style={{ stopColor: '#7C7C83', stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: '#7C7C83', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <input 
                onChange={handleSizeChange} 
                className="s-input" 
                type="range" 
                id={`sInput${bag.temp_id}`}
                max={100}
                step={25}
                value={bag.particao}
                required
            />

            <div className="d-flex justify-content-between mt-2 part">
                <span>0%</span>
                <span>25%</span>
                <span>50%</span>
                <span>75%</span>
                <span>100%</span>
            </div>
            
            <div className="mt-4">
                <label className="label">Peso disponível</label>
                <div className="d-flex gap-2 align-items-center mt-2">
                    <input
                        id={`wInput${bag.temp_id}`} 
                        className="w-input form-control"
                        type="number" 
                        placeholder="00"
                        max={bag.type === 'M' ? 10000:23000}
                        min={100}
                        required={true}
                    />
                    <span>g</span>
                </div>
            </div>
        </BagCardStyle>
    )
}