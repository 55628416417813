import styled from "styled-components";

export const CadButton = styled.button`
    background-color: #35B7E0;
    border-radius: 8px;
    color: white;
    border: none;
    padding: .5em 1em;
    font-weight: bold;

    &:hover{
        color: #35B7E0;
        box-shadow: 0 0 0 1px #35B7E0 inset;
        background-color: white;
    }
`;

export const LogButton = styled.button`
    border: none;
    color: #35B7E0;
    background-color: transparent;
    border-radius: 8px;
    padding: .5em .75em;
    font-weight: bold;
`;

export const CtaButtonSec = styled.button`
    background-color: ${props=>props.variant?'white':'#35B7E0'};
    border: ${props=>props.variant?'1px solid #2B6CBF':'none'};
    color: ${props=>props.variant?'#2B6CBF':'white'};
    border-radius: 8px;
    padding: .75em 1em;
    &:not(:last-child){
        margin-right: 2.5rem;
    }

    &:hover{
        color: white;
        background-color: #2B6CBF;
    }
`;

export const DropDownButton = styled.div`
    display: flex;
    align-items: center;
    border-radius: 50%;
    height: fit-content;
    width: fit-content;
    padding: .5em;
    font-size: 1.5rem;
    color: #313135;
    background-color: #EFEFF0;

    svg{
        transition: .1s ease-in-out;
        ${(props)=>props.drop ? "transform: rotateZ(-90deg);" : ''}
    }
`