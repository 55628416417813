import styled from "styled-components";
export const DashboardContainer = styled.div`
   @media only screen and (max-width: 1050px) {
    input, label, button{
      font-size: .75rem !important;
    }
    h5{
      font-size: 1rem !important;
    }
   }
`;
export const DashboardContent = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #f7fafa;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const MenuAside = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-left: 1.875em;
  padding-bottom: 4rem;
  padding-top: 2.5rem;
  width: 300px;
  overflow: hidden;
  padding-right: 0;
  border-right: solid #dfdfe0 2px;
  background-color: #fff;
  transition: width 1s;
  .MuiSvgIcon-root{
    color: #68686e;
  }
  &.open {
      width: 300px;
      padding-left: 1.875em;
      .nav li {
        display: flex;
      }
      .nav li span {
        display: block;
      }
    }
    &.closed {
      width: 65px;
      padding-left: .5em;
      .nav li {
      width: 100%;
      display: block;
    }
    .nav li span {
      display: none;
    }
    }

  @media only screen and (max-width: 1050px) {
    
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    .nav a {
      width: 100%;
    }
    
  }
  .logo {
    padding-left: 1rem;
  }

  .nav {
    margin-top: 4rem;
    gap: 2rem;

    li {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      white-space: nowrap;
      padding: 0.84375rem 0;
      color: #68686e;
      cursor: pointer;
      border-radius: 8px;
      padding-left: 1rem;

      svg {
        font-size: 1.5rem;
      }

      &:hover {
        background-color: #09caed14;
        color: #35b7e0 !important;
        a {
          color: #35b7e0 !important;
        }
      }
    }
  }

  .actions {
    padding-left: 1rem;
    svg {
      font-size: 1.5rem;
    }

    .support {
      padding: 0.84375rem 0;
      color: #68686e;
    }

    .logout {
      padding: 0.84375rem 0;
      color: #e02244;
      cursor: pointer;
    }
  }

  .selected {
    background-color: #09caed14;
    color: #35b7e0 !important;

    &::after {
      content: "";
      position: absolute;
      background-color: #35b7e0;
      top: 0;
      right: -5px;
      height: 100%;
      width: 8px;
      border-radius: 8px;
    }
  }
`;
