import { MdFlightTakeoff } from "react-icons/md"
import { MySentCard } from "../styles/enterprise/MySentCard.styled"

export const MySentsCard = ({sent})=>{
    
    const capitalize = s => s && s[0].toUpperCase() + s.slice(1)
    const handleDateFormat = (dt)=>{
        const dtObject = new Date(dt);
        const day = dtObject.getDate();
        const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        const month = months[dtObject.getMonth()];
        const year = dtObject.getFullYear();
        const date = day + " " + month + " " + year;
        return date;
    }

    return (
        <MySentCard>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <p className="mb-0">{sent.id}</p>
                    <p className="fw-bold mb-0 status">Pendente</p>
                </div>

                <p className="mb-0 fw-bold">{handleDateFormat(sent.aero_partida)}</p>
            </div>

            <div className="d-flex align-items-center justify-content-between">

                <div>
                    <label className="details-label">Origem</label>
                    <p className="fw-bold">{sent.cadastros_viagens[0].cidade_encomenda} - {sent.cadastros_viagens[0].local_encomenda}</p>
                </div>
                
                <MdFlightTakeoff/>

                <div>
                    <label className="details-label">Destino</label>
                    <p className="fw-bold">{sent.cidade} - {sent.uf}</p>
                </div>

            </div>

            <hr/>

            <div className="d-flex justify-content-between">
                <div>
                    <label className="details-label">Valor a receber</label>
                    <h5 className="price fw-bold">R$ 60,00</h5>
                </div>
                <div>
                    <label className="details-label">Pagamento</label>
                    <p className="status">Pendente</p>
                </div>
            </div>
        </MySentCard>
    )
}