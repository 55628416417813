import ReactInputMask from 'react-input-mask';
import { RegLocTravelStyle } from '../styles/traveler/RegLocTravelStyle.styled';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';
import { Modal } from 'react-bootstrap';
import { ConfirmTravelModal } from '../styles/traveler/ConfirmTravelModal';
import { DateFormat } from '../../utils/DateFormat';
import ReactLoading from 'react-loading';


export const RegTravelLoc = ({setStep, bags, travel, setTravel, prevTravel, edit, hideEditModal})=>{
    const {user, auth} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [modalDate, setModalDate] = useState({
        origin: travel.dt_partida,
        destiny: travel.dt_chegada
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth}`
        }
    };
    const uri = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

    const handleModalClose = _=>setShowModal(false);

    const getCep = (cep, loc)=>{
        const state = document.querySelector(`#state_${loc}`);
        const city = document.querySelector(`#city_${loc}`);
        const street = document.querySelector(`#street_${loc}`);
        const nhood = document.querySelector(`#nh_${loc}`);
        axios.get(`https://opencep.com/v1/${cep}`)
        .then(res=>{
            state.value = res.data.uf
            city.value = res.data.localidade
            street.value = res.data.logradouro
            nhood.value = res.data.bairro
        })
        .catch(e=>e);
    }


    const handleCepChange = (e, type)=>{
        const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");
        if(onlyNumbersCep.length === 8 ){
            switch(type){
                case 0:
                    getCep(onlyNumbersCep, 'origin');
                    break;
                case 1:
                    getCep(onlyNumbersCep, 'destiny');
                    break;
                default:
                    break;
            }
        }
    }
    
    const handleSubmit = async (e)=>{
        e.preventDefault();

        await setTravel({...travel,
            viajante_id: user.id,
            cep_encomenda: e.target.cep_origin.value.replace(/[^0-9]/g, ""),
            local_encomenda: e.target.state_origin.value,
            cidade_encomenda: e.target.city_origin.value,
            cep_entrega: e.target.cep_destiny.value.replace(/[^0-9]/g, ""),
            rua_entrega: e.target.street_destiny.value,
            num_entrega: e.target.number_destiny.value,
            num_encomenda: e.target.number_origin.value,
            bairro_entrega: e.target.nh_destiny.value,
            cidade_entrega: e.target.city_destiny.value,
            estado_entrega: e.target.state_destiny.value,
            dt_partida: DateFormat(travel.dt_partida),
            dt_chegada: DateFormat(travel.dt_chegada),
            complemento_partida: e.target.tip_origin.value,
            complemento_chegada: e.target.tip_destiny.value,
            status: 'EM ANÁLISE'
        });

        setShowModal(true);
    }
        
    const handleRequest = ()=>{
        setLoadingConfirm(true);
        
            
        axios.post(`${uri}/cadastro_viagens`, travel, config)
        .then(res=>{
            const travel_id = res.data.id;
            const reqBags = bags.map(bag => {
                return { ...bag, cadastro_viagem_id: travel_id};
            });
            console.log(res);

            axios.post(`${uri}/bagagens`, {bags: reqBags}, config)
            .then(resBag=>{
                setShowModal(false);
                setStep(0);
            })
            .catch(error=>{
                setLoadingConfirm(false);
            })
        })
        .catch(error=>{
            console.log(error)
            setLoadingConfirm(false);
        });
    }

    const handleEditRequest = async (e)=>{
        e.preventDefault();
        setLoadingConfirm(true);

        const reqTravel = {...travel,
            viajante_id: user.id,
            cep_encomenda: e.target.cep_origin.value.replace(/[^0-9]/g, ""),
            local_encomenda: e.target.state_origin.value,
            cidade_encomenda: e.target.city_origin.value,
            cep_entrega: e.target.cep_destiny.value.replace(/[^0-9]/g, ""),
            rua_entrega: e.target.street_destiny.value,
            num_entrega: e.target.number_destiny.value,
            num_encomenda: e.target.number_origin.value,
            bairro_entrega: e.target.nh_destiny.value,
            cidade_entrega: e.target.city_destiny.value,
            estado_entrega: e.target.state_destiny.value,
            dt_partida: DateFormat(travel.dt_partida),
            dt_chegada: DateFormat(travel.dt_chegada),
            complemento_partida: e.target.tip_origin.value,
            complemento_chegada: e.target.tip_destiny.value,
            status: 'EM ANÁLISE'
        };

        const bagsToAdd = bags.filter(bags => bags.id === 0);

        axios.patch(`${uri}/cadastro_viagens/${prevTravel.id}`, reqTravel, config)
        .then(res=>{
            const travel_id = res.data.id;
            const reqBags = bagsToAdd.map(bag => {
                return { ...bag, cadastro_viagem_id: travel_id};
            });


            if(reqBags.length > 0){
                axios.post(`${uri}/bagagens`, {bags: reqBags}, config)
                .then(resBag=>{
                    setShowModal(false);
                    setStep(0);
                })
                .catch(error=>{
                    setLoadingConfirm(false);
                })
            }

            bags.map((bag)=>{
                if(bag.id !== 0){
                    axios.patch(`${uri}/bagagens/${bag.id}`, bag, config)
                    .then(res=>{
                        setLoadingConfirm(false);
                        hideEditModal()
                    })
                    .catch(error=>error)
                }
            });
        })
        .catch(error=>console.log(error));
    }

    useEffect(()=>{
        if(prevTravel){
            document.getElementById('cep_origin').value = prevTravel.cep_encomenda;
            document.getElementById('cep_destiny').value = prevTravel.cep_entrega;
            document.getElementById('number_destiny').value = prevTravel.num_entrega;
            document.getElementById('number_origin').value = prevTravel.num_encomenda;
            document.getElementById('tip_destiny').value = prevTravel.complemento_chegada;
            document.getElementById('tip_origin').value = prevTravel.complemento_partida;
            getCep(prevTravel.cep_entrega,'destiny');
            getCep(prevTravel.cep_encomenda,'origin');
        }
    },[prevTravel]);

    return (
        <RegLocTravelStyle onSubmit={edit?handleEditRequest:handleSubmit} className='d-flex flex-grow-1 flex-column justify-content-between'>
            <div className="mt-5 row gy-5 where-to">
                <div className="col-12 col-xl-6">
                    <h6 className="title">Local do recebimento da encomenda</h6>
                    <div className="row d-flex gy-3">
                        <div className='col-12 col-md'>
                            <label htmlFor="cep_origin">CEP</label>
                            <ReactInputMask
                                onChange={e=>handleCepChange(e, 0)}
                                mask={"99999-999"}
                                required 
                                placeholder="00000-000" 
                                className="form-control" 
                                id="cep_origin" 
                                name="cep_origin" 
                                type="text"
                            />
                        </div>

                        <div className='col-12 col-md'>
                            <label htmlFor="state_origin">Estado</label>
                            <input placeholder="Estado" readOnly required className="form-control" id="state_origin" name="state_origin" type="text"/>
                        </div>

                        <div className='col-12 col-md'>
                            <label htmlFor="city_origin">Cidade</label>
                            <input required placeholder="Cidade" readOnly className="form-control" id="city_origin" name="city_origin"  type="text"/>
                        </div>
                    </div>

                    <div className="mt-4 row d-flex gy-2">
                        <div className='col-12 col-md'>
                            <label htmlFor="nh_origin">Bairo</label>
                            <input required placeholder="Bairro" readOnly className="form-control" id="nh_origin" name="nh_origin"  type="text"/>
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="street_origin">Rua</label>
                            <input required placeholder="Rua" readOnly className="form-control" id="street_origin" name="street_origin" type="text"/>
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="number_origin">Número</label>
                            <input required placeholder="000" className="form-control" id="number_origin" name="number_origin" type="text"/>
                        </div>
                    </div>
                    <div className='mt-4 w-100'>
                        <label htmlFor="tip_origin">Complemento</label>
                        <input placeholder="Complemento" className="form-control" id="tip_origin" name="tip_origin" type="text"/>
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <h6 className="title">Local para entregar encomenda</h6>
                    <div className="d-flex row gy-3">
                        <div className='col-12 col-md'>
                            <label htmlFor="cep_destiny">CEP</label>
                            <ReactInputMask
                                onChange={e=>handleCepChange(e, 1)}
                                mask={"99999-999"} 
                                required
                                placeholder="00000-000"
                                className="form-control" 
                                id="cep_destiny" 
                                name="cep_destiny" 
                                type="text"
                            />
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="state_destiny">Estado</label>
                            <input required placeholder="Estado" readOnly className="form-control" id="state_destiny" name="state_destiny" type="text"/>
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="city_destiny">Cidade</label>
                            <input required placeholder="Cidade" readOnly className="form-control" id="city_destiny" name="city_destiny"  type="text"/>
                        </div>
                    </div>
                    <div className="d-flex row gy-3 mt-4">
                        <div className='col-12 col-md'>
                            <label htmlFor="nh_destiny">Bairo</label>
                            <input required placeholder="Bairro" readOnly className="form-control" id="nh_destiny" name="nh_destiny"  type="text"/>
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="street_destiny">Rua</label>
                            <input required placeholder="Rua" readOnly className="form-control" id="street_destiny" name="street_destiny" type="text"/>
                        </div>
                        <div className='col-12 col-md'>
                            <label htmlFor="number_destiny">Número</label>
                            <input required placeholder="000" className="form-control" id="number_destiny" name="number_destiny" ype="text"/>
                        </div>
                    </div>
                    <div className='mt-4 w-100'>
                        <label htmlFor="tip_destiny">Complemento</label>
                        <input placeholder="Complemento" className="form-control" id="tip_destiny" name="tip_destiny" type="text"/>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column flex-md-row gap-4 mt-5 justify-content-end">
                <button type="button" onClick={_=>setStep(1)} className="back btn">Voltar</button>
                <button type="submit" className="btn text-white next">
                {loadingConfirm && edit ? <ReactLoading type="spin" color='#fff' width="1.5rem" height="1.75rem"/>:
                'Avançar'}
                </button>
            </div>
            
            <Modal show={showModal} onHide={handleModalClose} centered size='lg'>
                <ConfirmTravelModal>
                    <Modal.Header closeButton>
                        <h4 className='fw-bold text-muted mb-0'>Revise as informações</h4>
                    </Modal.Header>
                    <hr/>
                    <Modal.Body>
                        <div className='d-flex flex-column info-container'>
                            <div>
                                <p className='subtitle fw-bold'>Dados da origem</p>
                                <div>
                                    <label className='fw-bold text-muted'>Aeroporto</label>
                                    <p className='mb-0 text-muted'>{travel.aero_partida}</p>
                                </div>
                                <div className='d-flex info mt-4'>
                                    <div>
                                        <label className='fw-bold text-muted'>Data</label>
                                        <p className='mb-0 text-muted'>{modalDate.origin}</p>
                                    </div>
                                    <div>
                                        <label className='fw-bold text-muted'>Hora</label>
                                        <p className='mb-0 text-muted'>{travel.hr_partida}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className='subtitle fw-bold'>Dados do destino</p>
                                <div>
                                    <label className='fw-bold text-muted'>Aeroporto</label>
                                    <p className='mb-0 text-muted'>{travel.aero_chegada}</p>
                                </div>
                                <div className='d-flex info mt-4'>
                                    <div>
                                        <label className='fw-bold text-muted'>Data</label>
                                        <p className='mb-0 text-muted'>{modalDate.destiny}</p>
                                    </div>
                                    <div>
                                        <label className='fw-bold text-muted'>Hora</label>
                                        <p className='mb-0 text-muted'>{travel.hr_chegada}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='text-end d-flex flex-column flex-md-row justify-content-end gap-3  mt-5'>
                            <button type="button" onClick={handleModalClose} className="back btn">Voltar</button>

                            <button type="button" disabled={loadingConfirm} onClick={handleRequest} className="btn text-white fw-bold next">
                               {loadingConfirm ? <ReactLoading type="spin" color='#fff' width="1.5rem" height="1.75rem"/>:
                               'Confirmar'}
                            </button>
                        </div>
                    </Modal.Body>
                </ConfirmTravelModal>

            </Modal>
        </RegLocTravelStyle>
    );
} 