import { MdAdd, MdWork, MdLuggage } from "react-icons/md";
import { RegTravelBagContainer } from "../styles/traveler/RegTravelBagContainer.styled";
import { BagCard } from "./BagCard";
import { useEffect, useState } from "react";

export const RegTravelBagOptions = ({setStep, setBags, bags})=>{
    const [bagTypes, setBagTypes] = useState(bags!==0?bags:[]);
    const [handDisable, setHandDisable] = useState(false);
    const [dispDisable, setDispDisable] = useState(false);
    const [nextDisable, setNextDisable] = useState(true);

    useEffect(()=>{
        if(bagTypes.length > 0){
            setNextDisable(false);
        }

        if(bagTypes.filter((bag)=>bag.tipo==='M').length >= 1){
            setHandDisable(true)
        } else {
            setHandDisable(false)
        }

        if(bagTypes.filter((bag)=>bag.type==='D').length >= 9){
            return setDispDisable(true)
        } else {
            setDispDisable(false)
        }
    }, [bagTypes]);

    const handleAddHandBag = () => {
        const newBag = {
            temp_id: new Date().getTime(),
            tipo: 'M',
            particao: 50,
            gradientId: Math.random()*200
        };

        setBagTypes([...bagTypes, newBag]);
    };
    
    const handleAddDisBag = () => {
        const newBag = {
            temp_id: new Date().getTime(),
            tipo: 'D',
            particao: 50,
            gradientId: Math.random()*200
        };

        setBagTypes([...bagTypes, newBag]);
    };
    
    const handleUnset = (id) => {
        const updatedBags = bagTypes.filter((bag) => bag.temp_id !== id);
        setBagTypes(updatedBags);
        
        if(updatedBags.length == 0){
            setNextDisable(true);
        }
    };

    const handleNext = (e)=>{
        e.preventDefault();
        const updatedBags = [];
        
        bagTypes.forEach((bag) => {
            const newBag = {
                id: bag.id || 0,
                tipo: bag.type || bag.tipo,
                peso: e.target[`wInput${bag.temp_id}`].value,
                particao: e.target[`sInput${bag.temp_id}`].value
            };

            updatedBags.push(newBag);
        });

        setBags(updatedBags);
        console.log(bags)
        setStep(2);
    }

    return (
        <RegTravelBagContainer onSubmit={handleNext} className="d-flex flex-grow-1 flex-column">
            <div className="flex-grow-1">
                <h4 className="title mt-5">Tipo de bagagem</h4>
                <div className="d-flex flex-column flex-md-row gap-4">
                    <button disabled={handDisable} onClick={handleAddHandBag} type="button" className="add p-3">Bagagem de mão <MdAdd/></button>
                    <button disabled={dispDisable} onClick={handleAddDisBag} type="button" className="add p-3 ">Bagagem despachada <MdAdd/></button>
                </div>

                <h4 className="title mt-5">Bagagens adicionadas</h4>
                <div className="d-flex flex-wrap gap-3" id="bagCardsContainer">
                    {bagTypes.length <= 0 ? 
                    <>Nenhuma bagagem adicionada, clique em um dos botões para adicionar um novo tipo de bagagem</> 
                    : bagTypes.map((bag, index)=>{
                        return <BagCard 
                            key={index}
                            handleUnset={handleUnset} 
                            bag={bag}
                            bags={bagTypes}
                            setBagTypes={setBagTypes}
                        />;
                    })}
                </div>
            </div>

            <div className="d-flex flex-column flex-md-row gap-4 mt-5 justify-content-end">
                <button type="button" onClick={_=>setStep(0)} className="back btn">Voltar</button>
                <button type="submit" disabled={nextDisable} className="btn text-white next">Avançar</button>
            </div>
        </RegTravelBagContainer>
    )
}