import React, { useContext } from "react";
import {Navigate, Route} from 'react-router-dom';
import { RegisterTraveler } from "../pages/traveler/RegisterTraveler";
import { RegisterTravelerAddress } from "../pages/traveler/RegisterTravelerAddress";
import { AuthContext } from "../Contexts/AuthContext";

const ConfigTravelerControl = ()=>{
    const {user, auth} = useContext(AuthContext);

    if(!auth || !user){
        return <Navigate to='/login'/>
    } else if(user.hasOwnProperty('cnh') || user.hasOwnProperty('cnpj')){
        return null;
    }

    if(!user.email_verificado_em){
        return <Navigate to="/verificar-email"/>
    }
    
    if(!user.cpf){
        return <RegisterTraveler/>
    }
    
    if(!user.cep){
        return <RegisterTravelerAddress/>
    }
    
    
    return <Navigate to="/travelers/reg-travel"/>
}

export default ConfigTravelerControl;