import ReactInputMask from "react-input-mask";
import { StyledAside } from "../../components/styles/Aside.styled";
import { LoginForm } from "../../components/styles/LoginForm.styled";
import { Required } from "../../components/styles/Required.styled";
import { Tooltip } from "../../components/styles/Tooltip.styled";
import {
    LoginButton,
    LoginButtonReturn,
} from "../../components/styles/LoginButton.styled";
import { cpf } from 'cpf-cnpj-validator'; 
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { BoxAsideContainer } from "../../components/BoxAsideContainer";
import { LogoNavBar } from "../../components/LogoNavBar";
import axios from "axios";
import RegisterBox from '../../images/register_box.svg';
import TextInput from "../../components/forms/TextInput";

export const RegisterTraveler = () => {
    const [validCpf, setValidCpf] = useState(true);
    const {user, setUser, auth, setAuth} = useContext(AuthContext);
    

    const [cpfError, setCpfError] = useState({
        message: '',
        isSet: false
    });
    
    const [telError, setTelError] = useState({
        message: '',
        isSet: false
    });

    const config = {
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth}`
        }
    }

    const uri = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const key_cnpj = process.env.NODE_ENV === "development" ? process.env.REACT_APP_CPFCNPJ_DEV_KEY : process.env.REACT_APP_CPFCNPJ_PROD_KEY;


    const handleCpfChange = (e)=>{
        setCpfError({...cpfError, isSet: false});
        setValidCpf(true);
        const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
        if(onlyNumbers.length === 11){
            if(!cpf.isValid(onlyNumbers) || e.target.value === ''){
                
                setValidCpf(false)
            } else {
                axios.get(`https://api.cpfcnpj.com.br/${key_cnpj}/1/${onlyNumbers}`)
                .then(res=>{
                    document.getElementById('name').value = res.data.nome;
                })
                .catch(error=>{
                    console.log(error)
                    setValidCpf(true);
                })
            };
        }
    } 

    const handleSubmit = (e)=>{
        e.preventDefault();
        const fd = new FormData();
        fd.append('nome', e.target.name.value);
        fd.append('cpf', e.target.cpf.value.replace(/[.-]/g, ""));
        fd.append('telefone', e.target.cel.value.replace(/[-() ]/g, ""));


        axios.patch(`${uri}/viajantes/${user.id}`, fd, config)
        .then((res)=>{
            setUser({...user, cpf: res.data.cpf})
        })
        .catch(e=>{

            if(e.response.data.errors.cpf){
                setCpfError({
                    message: 'CPF já existe',
                    isSet: true
                });
            };

            if(e.response.data.errors.telefone){
                setTelError({
                    message: 'Telefone já existe',
                    isSet: true
                });
            };
        });
    }

    const getBack = async ()=>{
        axios.post(`${uri}/login/logout`,{},config)
        .then(res=>{
            setAuth(false);
        });

        localStorage.removeItem('token');
        setUser({});
    }

    return (
        <section style={{minHeight: '100%'}} className="d-flex">
            <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
                <div>
                    <LogoNavBar/>
                </div>
                <div className="d-flex flex-column flex-grow-1 justify-content-center">
                <div>
                    <h2 className="fw-bolder ">Cadastro de viajante</h2>
                    <span className='text-muted'>Preencha as informações.</span>
                </div>
                    

                    <LoginForm className="mt-5" onSubmit={handleSubmit}>
                    <div className="w-100">
                        <TextInput 
                            type="text"
                            name="cpf"
                            id="cpf"
                            label="Seu CPF"
                            placeholder="Insira seu CPF"
                            onChange={handleCpfChange}
                            required={true}  mask={"999.999.999-99"}/>
                        <span className={`text-danger d-block mt-2 ${validCpf?'d-none':'d-block'}`}>CPF inválido!</span>
                        <span className={`text-danger d-block mt-2 ${cpfError.isSet?'d-block':'d-none'}`}>CPF já cadastrado!</span>
                        </div>
                        <TextInput 
                            readOnly
                            type="text"
                            name="name"
                            id="name"
                            label="Nome completo"
                            placeholder="Nome completo"
                            required={true}/>
                        
                        <div className="w-100">
                        <TextInput 
                            type="cel"
                            mask={"(99) 99999-9999"}
                            onChange={_=>setTelError({})}
                            name="cel"
                            id="cel"
                            label="Número de telefone"
                            placeholder="Número de telefone"
                            required={true}/>
                            <span className={`text-danger  mt-2 ${telError.isSet?'d-block':'d-none'}`}>Telefone já cadastrado!</span>
                        </div>
                        
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required/>
                            <label className="form-check-label" htmlFor="flexCheckDefault" style={{fontSize: 14}}>
                            Concordo com todos os termos e autorizo o uso dos dados apresentados.
                            </label>
                        </div>

                        <div className="d-flex justify-content-between flex-wrap flex-sm-nowrap w-100 gap-2">
                            <LoginButtonReturn onClick={getBack} type="button">Voltar</LoginButtonReturn>
                            <LoginButton type="submit">Avançar</LoginButton>
                        </div>
                    </LoginForm>
                </div>

            </StyledAside>
            <BoxAsideContainer image={RegisterBox} width={712}/>
        </section>
    );
};
