import { Header } from "../../components/Header";
import { MenuSideBar } from "../../components/MenuSideBar";
import { MySentsFilter } from "../../components/styles/enterprise/MySentsFilter";
import {MySentsCard} from "../../components/enterprise/MySentsCard";
import {useState, useEffect} from 'react';
import axios from "axios";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

export const MySents = ()=>{
    const [sent, setSent] = useState([]);
    const [nextDisable, setNextDisable] = useState(false);
    const [prevDisable, setPrevDisable] = useState(true);

    const config = {
        headers: {
            Accept: 'application/json',
            ContentType: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }

    const handleNextPage = ()=>{
        axios.get(`${sent.next_page_url}`, config)
        .then(res=>{
            setSent(res.data)
            if(!res.data.next_page_url){
                setNextDisable(true);
            }
            setPrevDisable(false);
        })
        .catch(e=>(e))
    }

    const handlePreviousPage = ()=>{
        axios.get(`${sent.prev_page_url}`, config)
        .then(res=>{
            setSent(res.data)
            if(!res.data.prev_page_url){
                setPrevDisable(true);
            }
            setNextDisable(false);
        })
        .catch(e=>(e))
    }

    const toPage = (e)=>{
        if(e.target.id != sent.current_page){
            axios.get(`${sent.links[e.target.id].url}`, config)
            .then(res=>{
                setSent(res.data)

                if(!res.data.next_page_url){
                    setNextDisable(true);
                } else {
                    setNextDisable(false)
                }

                if(!res.data.prev_page_url){
                    setPrevDisable(true);
                } else {
                    setPrevDisable(false);
                }
            })
            .catch(e=>(e))
        }
    }

    useEffect(()=>{
        console.log(sent)
        if(sent.length === 0){
            axios.get(`${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/solic_viajante/paginate`, config)
            .then(res=>{
                setSent(res.data);
            });
        }
    });

    return (
        <MenuSideBar inventory  title="Minhas entregas">
        <div className="flex-grow-1 d-flex flex-column">
           
                <MySentsFilter className="d-flex row flex-wrap gap-3">
                    <div className="col-12 col-md-5 col-lg">
                        <label className="fw-bold text-muted">Status da entrega</label>
                        <select className="form-select text-muted mt-2 p-3 pe-5"><option>Em desenvolvimento</option></select>
                    </div>
                    <div className="col-12 col-md-5 col-lg">
                        <label className="fw-bold text-muted">Status do pagamento</label>
                        <select className="form-select text-muted mt-2 p-3 pe-5"><option>Em desenvolvimento</option></select>
                    </div>
                    <div className="col-12 col-md-5 col-lg-3">
                        <label className="fw-bold text-muted">Data de envio</label>
                        <input className="form-control text-muted mt-2 p-3 " type="date"/>
                    </div>
                </MySentsFilter>
            

            <div className="mt-5 row w-100 gap-4">
                {sent.data ? 
                    Object.keys(sent.data).map((key, index)=>{
                        return (
                            <MySentsCard sent={sent.data[index]}/>
                        )
                    }
                ): <>Nenhuma viagem encontrada</>}
            </div>

            {sent.last_page !== 1 ?
            <div className="d-flex mt-5 flex-grow-1 align-items-end">
                {prevDisable ? '':
                <button className="btn p-0 rounded-circle" onClick={handlePreviousPage}>
                    <MdNavigateBefore style={{fontSize: '1.5rem'}}></MdNavigateBefore>
                </button>}

                {[...Array(sent.last_page).keys()].map((key, index)=>{
                    return (
                        <p
                        className="me-2 mb-0 px-2"
                        key={key}
                        id={key+1}
                        onClick={toPage}
                        style={sent.current_page == key+1 ?
                        {border: '1px solid #09CAED', borderRadius: '50%'}:{cursor: 'pointer'}}
                        >{key+1}</p>
                    )
                })}
                
                {nextDisable ? '': 
                <button className="btn p-0 rounded-circle" onClick={handleNextPage}>
                    <MdNavigateNext style={{fontSize: '1.5rem'}}></MdNavigateNext>
                </button>}
            </div>:''}
        </div>
        </MenuSideBar>
    
    );
}