import styled from "styled-components";

export const CardBorder = styled.div`
  padding: 1.5rem;
  background-color: white;
  ${({ borderDirection, borderColor }) =>
    `border-${borderDirection}: 2px solid ${borderColor};`}
  border-radius: 8px;
  .divider-itens:not(:first-child) {
    border-top: 1px solid #dfdddb;
  }
  .img-avatar{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .fs-16{
      font-size: calc(0.7rem + .3vw)
    }
    .title {
        font-weight: bold;
        font-size: calc(0.9rem + .3vw);
        color: #35B7E0;
    }
 
`;
