import ReactInputMask from "react-input-mask";
import { StyledAside } from "../../components/styles/Aside.styled";
import { LoginForm } from "../../components/styles/LoginForm.styled";
import { Required } from "../../components/styles/Required.styled";
import { Tooltip } from "../../components/styles/Tooltip.styled";
import { LoginButton } from "../../components/styles/LoginButton.styled";
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { BoxAsideContainer } from "../../components/BoxAsideContainer";
import { LogoNavBar } from "../../components/LogoNavBar";
import RegisterBox from "../../images/register_box.svg";
import cep from "cep-promise";
import axios from "axios";
import TextInput from "../../components/forms/TextInput";

export const RegisterDelivererAddress = () => {
  const [checkCep, setCep] = useState(true);
  const { user, setUser } = useContext(AuthContext);

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleCepChange = (e) => {
    const onlyNumbersCep = e.target.value.replace(/[^0-9]/g, "");
    const state = document.querySelector("#state");
    const city = document.querySelector("#city");
    const street = document.querySelector("#street");
    const nhood = document.querySelector("#nhood");

    if (onlyNumbersCep.length === 8) {
      cep(onlyNumbersCep)
        .then((res) => {
          state.value = res.state;
          city.value = res.city;
          street.value = res.street;
          nhood.value = res.neighborhood;

          setCep(true);
        })
        .catch((e) => {
          setCep(false);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkCep) return e.taget.cep.focus();

    const fd = new FormData();

    fd.append("cep", e.target.cep.value.replace(/[^0-9]/g, ""));
    fd.append("rua", e.target.street.value);
    fd.append("numero", e.target.num.value);
    fd.append("bairro", e.target.nhood.value);
    fd.append("cidade", e.target.city.value);
    fd.append("estado", e.target.state.value);

    axios
      .patch(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/entregadores/${user.id}`,
        fd,
        config
      )
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => console.error(e));
  };

  return (
    <section style={{ minHeight: "100%" }} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Vamos cadastrar seu endereço</h2>
            <span className="text-muted">
              Adicione seu endereço para uma melhor experiência
            </span>
          </div>

          <LoginForm
            style={{ alignItems: "normal" }}
            className="mt-5 gap-4"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12">
                <TextInput
                  mask={"99999-999"}
                  onChange={handleCepChange}
                  type="text"
                  name="cep"
                  id="cep"
                  placeholder="00000-000"
                  required
                  label="CEP"
                />
                <span className={`text-danger ${checkCep ? "d-none" : ""}`}>
                  CEP inválido!
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="street"
                  id="street"
                  readOnly
                  placeholder="Ex.: Rua Croácia"
                  required
                  label="Rua"
                />
              </div>

              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="num"
                  id="num"
                  placeholder="Ex.: 342"
                  required
                  label="Número"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <TextInput
                  type="text"
                  name="nhood"
                  id="nhood"
                  readOnly
                  placeholder="Ex.: Sport Club"
                  required
                  label="Bairro"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Ex.: Uberlândia"
                  readOnly
                  required
                  label="Cidade"
                />
              </div>

              <div className="col-12 col-lg-6">
                <TextInput
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Ex.: Minas Gerais"
                  readOnly
                  required
                  label="Estado"
                />
              </div>
            </div>

            <LoginButton className="w-100" type="submit">
              Acessar
            </LoginButton>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
};
