import { useEffect, useState } from "react";
import { RegTravelFormStyle } from "../styles/traveler/RegTravelFormStyle.styled";
import { Combobox, DatePicker } from "react-widgets";
import ReactInputMask from "react-input-mask";
import {DateFormat} from "../../utils/DateFormat";
import { format } from "date-fns";

export const RegTravelForm = ({stepSetter, setTravel, travel})=>{
    const [selectedDate, setSelectedDate] = useState({
        date: new Date(daysFromNow()),
        value: new Date(daysFromNow())
    });

    const [cias, setCias] = useState({
        AZUL: ['AZU', 'AD'],
        LATAM: ['TAM', 'LA'],
        GOL: ['GLO', 'G3']
    });

    const [airports, setAirports] = useState([
        'Aeroporto Internacional de Rio Branco / Plácido de Castro',
        'Aeroporto Internacional de Salvador / Deputado Luis Eduardo Magalhães',
        'Aeroporto Internacional do Rio de Janeiro / Galeão-Antônio Carlos Jobim',
        'Aeroporto Santos Dumont'
    ]);

    const [selectedCia, setSelectedCia] = useState(cias.AZUL);

    function daysFromNow() {
        const result = new Date(new Date());
        result.setDate(result.getDate() + 2);
        return result;
    }
    
    const handleDateChange = (e)=>{
        setSelectedDate({value: e, date: e});
    }

    const handleCiaChange = (e)=>{
        const ciaInput = document.getElementById('cia_input');
        if(!(e in cias)){
            ciaInput.setCustomValidity('A CIA aérea deve ser GOL, AZUL ou LATAM');
        } else {
            ciaInput.setCustomValidity('');
            setSelectedCia(cias[e]);
        }
    }

    const handleAirportChange = (e)=>{
        const airportDestinyInput = document.getElementById('air_destiny_input');
        const airportOriginInput = document.getElementById('air_origin_input');

        if(airports.includes(airportOriginInput.value)){
            airportOriginInput.setCustomValidity('');
        } else {
            airportOriginInput.setCustomValidity('O aeroporto deve ser um dos ofertados.');
        }

        if(airports.includes(airportDestinyInput.value)){
            airportDestinyInput.setCustomValidity('');
        } else {
            airportDestinyInput.setCustomValidity('O aeroporto deve ser um dos ofertados.');
        }


        if(e === airportOriginInput.value){
            airportDestinyInput.setCustomValidity('O aeroporto de destino deve ser diferente do de origem.');
        } else {
            airportDestinyInput.setCustomValidity('');
        };
    }

    const handleTimeChange = (e)=>{
        const originFormatedDate = DateFormat(document.getElementById('dt_origin_input').value, true);
        const destinyFormatedDate = DateFormat(document.getElementById('dt_destiny_input').value, true)

        const dtimeOrigin = new Date(`${originFormatedDate} ${document.getElementById('time_origin').value}:`);
        const dtimeDestiny = new Date(`${destinyFormatedDate} ${e.target.value}:`);
        
        if(dtimeOrigin >= dtimeDestiny){
            e.target.setCustomValidity('O horario de chegada deve ser após o de saída.');
            return
        }
        
        e.target.setCustomValidity('');
    }

    const validateForm = (e)=>{

        if(e.target.flight_num.value.replace(/_/g, '').length < 6){
            e.target.flight_num.focus();
            return false
        }

        if(e.target.loc.value.replace(/_/g, '').length < 6){
            e.target.loc.focus();
            return false
        }

        if(e.target.air_origin_input.value.length <= 0){
            e.target.air_origin_input.focus();
            return false
        }

        if(e.target.air_destiny_input.value.length <= 0){
            e.target.air_destiny_input.focus();
            return false
        }

        if(e.target.dt_origin_input.value.length <= 0){
            e.target.dt_origin_input.focus();
            return false
        }

        if(e.target.dt_destiny_input.value.length <= 0){
            e.target.dt_destiny_input.focus();
            return false
        }

        return true
    }

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(!validateForm(e)){
            return
        };
        
        const formTravel={
            cia_aerea: e.target.cia_input.value,
            num_voo: e.target.flight_num.value.replace(/_/g, ''),
            localizador: e.target.loc.value.replace(/_/g, ''),

            aero_partida: e.target.air_origin_input.value,
            dt_partida: e.target.dt_origin_input.value,
            hr_partida: e.target.time_origin.value,

            aero_chegada: e.target.air_destiny_input.value,
            dt_chegada:e.target.dt_destiny_input.value,
            hr_chegada: e.target.time_destiny.value,
        }

        setTravel(formTravel);
        stepSetter(1);
    }

    useEffect(()=>{
        if(travel != null){
            document.getElementById('cia_input').value = travel.cia_aerea;
            document.getElementById('flight_num').value = travel.num_voo;
            document.getElementById('loc').value = travel.localizador;
            
            document.getElementById('air_origin_input').value = travel.aero_partida;
            document.getElementById('dt_origin_input').value = format(travel.dt_partida, 'dd/MM/yyyy');
            document.getElementById('time_origin').value = travel.hr_partida;

            document.getElementById('air_destiny_input').value = travel.aero_chegada;
            document.getElementById('dt_destiny_input').value = format(travel.dt_chegada, 'dd/MM/yyyy');
            document.getElementById('time_destiny').value = travel.hr_chegada;
        }
    }, [travel])

    return (
        <RegTravelFormStyle onSubmit={handleSubmit} className="mt-5 d-flex flex-grow-1 flex-column justify-content-between">
            <div>
                <h6 className="title">Dados do vôo</h6>
                <div className="row gy-3 mt-3">
                    <div className="col-12 col-md-4">
                        <label>Cia aérea</label>
                        <Combobox
                            hideCaret
                            hideEmptyPopup
                            id="cia"
                            name="cia"
                            className="mt-2 border-0"
                            type="text" 
                            placeholder="Cia aérea"
                            onChange={handleCiaChange}
                            filter='contains'
                            data={Object.keys(cias)}
                        />  
                    </div>
                    <div className="col-12 col-md-4">
                        <label>Número do vôo</label>
                        <ReactInputMask mask={`${selectedCia[1]}9999`}
                        required id="flight_num" className="form-control mt-2" type="text" placeholder="Número do vôo"/>
                    </div>
                    <div className="col-12 col-md-4">
                        <label>Localizador</label>
                        <ReactInputMask mask={`******`} required id="loc" className="form-control mt-2" type="text" placeholder="Localizador"/>
                    </div>
                </div>

                <div className="mt-3 row gy-5">
                    <div className="d-flex flex-column col-12 col-lg-6 gap-4">
                        <h6 className="title">Dados da partida</h6>
                        <div>
                            <label>Aeroporto</label>
                            <Combobox
                                hideCaret
                                hideEmptyPopup
                                id="air_origin" 
                                className="mt-2" 
                                type="text" 
                                placeholder="Aeroporto"
                                filter='contains'
                                data={airports}
                                onChange={handleAirportChange}
                            />
                        </div>
                        <div className="row gy-4 ">
                            <div className="col-12 col-md-8 ">
                                <label>Data</label>
                                <DatePicker
                                    min={new Date(daysFromNow())}
                                    defaultValue={travel ? new Date(travel.dt_partida):new Date(daysFromNow())}
                                    onChange={handleDateChange}
                                    dropUp
                                    
                                    required
                                    id="dt_origin" 
                                    className="mt-2 w-100 date"
                                />

                            </div>
                            <div className="col-12 col-md-4">
                                <label>Hora</label>
                                <input required id="time_origin" className="form-control mt-2 w-100 flex-grow-1" type="time"/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column col-12 col-lg-6 gap-4">
                        <h6 className="title">Dados da chegada</h6>
                        <div>
                            <label>Aeroporto</label>
                            <Combobox
                                hideCaret
                                hideEmptyPopup
                                id="air_destiny" 
                                className="mt-2" 
                                type="text" 
                                placeholder="Aeroporto"
                                onChange={handleAirportChange}
                                filter='contains'
                                data={airports}
                            />
                        </div>
                        <div className="row gy-4">
                            <div className="col-12 col-md-8">
                                <label>Data</label>
                                <DatePicker
                                    min={selectedDate.date}
                                    value={selectedDate.value}
                                    onChange={value => setSelectedDate({...selectedDate, value: value})}
                                    dropUp
                                    required id="dt_destiny" 
                                    className="mt-2 w-100 date"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <label>Hora</label>
                                <input onChange={handleTimeChange} required id="time_destiny" className="form-control mt-2 w-100 flex-grow-1" type="time"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-end mt-3">
                <button type="submit" className="btn btn-next text-white">Avançar</button>
            </div>
        </RegTravelFormStyle>
    )
}