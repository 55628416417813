import landing1 from '../images/landing1.svg';
import landing2 from '../images/landing2.svg';
import { CtaButtonSec} from '../components/styles/landing/LandinButtons.style';
import { MdNavigateBefore, MdNavigateNext, MdStore, MdFlight, MdTwoWheeler} from "react-icons/md"; // não remover imports
import { LandingListBeneficios, LandingSubTitle, LandingText, LandingTitle} from '../components/styles/landing/LandingContainer.style'
import { LandDropDown } from '../components/LandingDropDown';
import { Header } from '../components/lading/Header';
import { Card, CommentCard, CountCard } from '../components/lading/Card'; // não remover imports
import { Footer } from '../components/lading/Footer';
import { Link } from 'react-router-dom';

import { LandingContainerCards } from '../components/styles/landing/LandingCard.style';
import { Container } from 'react-bootstrap';


const Landing = ()=>{

    return (
        <>
            <Header/>
            <Container style={{gap: 150, marginTop:100, marginBottom: 100}} className='d-flex flex-column  px-3'>
            <section>
                    <div className='row gy-4'>
                        <div className='order-2 order-lg-1 col-12 col-lg-6'>
                            <LandingText className='mb-1 text-muted'>Rápido e seguro, como deve ser</LandingText>
                            <LandingTitle>Turbinando viagens, impulsionando vendas</LandingTitle>

                            <LandingText className='text-muted mt-5'>Na T3Lake, conectamos viajantes e e-commerces para tornar suas compras mais rápidas e suas viagens mais recompensadoras. Experimente a conveniência de receber seus itens diretamente de viajantes confiáveis e transforme suas expectativas de entrega.</LandingText>
                            <LandingText className='text-muted fw-bold'>Junte-se a nós e descubra uma maneira totalmente nova de comprar e viajar.</LandingText>

                            <div className='d-flex mt-5'>
                                <Link to='/about'>
                                    <CtaButtonSec variant>Sobre nós</CtaButtonSec>
                                </Link>
                            </div>
                        </div>

                        <div className='order-1 order-lg-2 col-12 col-lg-6 d-flex justify-content-center justify-content-xl-end '>
                            <img src={landing1} style={{maxWidth: 580}} className='w-100' alt='avião viajando o mundo'/>
                        </div>
                        
                    </div>
            </section>
            <section>
            <div   className='row d-flex justify-content-between gap-4'>
                        <div className='order-1 col-12 col-lg-6 d-flex justify-content-center justify-content-xl-end'>
                            <img  style={{maxWidth: 580}} className='w-100' alt='pessoas com malas prontas para viagem' src={landing2}/>
                        </div>

                        <div className='order-2  col-12 col-lg-5'>
                            <LandingText className='mb-1 text-muted'>Maneira mais ágil e gratificante de receber seus produtos online</LandingText>
                            <LandingSubTitle className='text-muted'>Nosso método de trabalho</LandingSubTitle>

                            <LandingText className='text-muted mt-5'>Conectamos viajantes e e-commerces para proporcionar entregas mais rápidas e convenientes do que nunca. 
                            Com total transparência em cada etapa do processo, nossa plataforma garante que seus itens sejam entregues com segurança e confiabilidade.</LandingText>

                            <LandingText className='text-muted mt-3'>Personalizamos cada experiência de entrega para atender às suas necessidades específicas, tornando todo o processo simples e direto.</LandingText>
                            <div className='d-flex'>
                                <Link to="/how">
                                    <CtaButtonSec className='flex-grow-0' variant style={{marginTop: '2.5rem'}}>Como funcionamos</CtaButtonSec>
                                </Link>
                            </div>
                        </div>
                    </div>
            </section>
            <section>
            <div style={{marginBottom: '4rem'}}>
                        <LandingText className='mb-0'>Conheça a nossa</LandingText>
                        <LandingSubTitle className='text-muted mb-4'>Comunidade</LandingSubTitle>
                    <LandingText className='text-muted mb-0'>São esses os responsáveis pelo sucesso da nossa comunidade. Faça parte e aproveite os benefícios!</LandingText>
                </div>

                <LandingContainerCards className='row'>
                    <div className='col-12 col-lg-4 d-flex justify-content-center px-4'>
                    <Card 
                    icon={<MdStore/>}
                    title='E-commerce'
                    info='Uma vez cadastrados, nossos e-commerce parceiros terão acesso à nossa lista de viajantes disponíveis.
                    Podendo contratar o serviços do que lhe for mais conveniente, e nós garantimos a efetividade e segurança.'
                    />
                    </div>
                    <div className='col-12 col-lg-4 d-flex justify-content-center px-4'>
                    <Card 
                    icon={<MdFlight/>}
                    title='Viajante'
                    info='Nossos viajantes verificados cadastram suas viagens, ofertam  os espaços vagos em suas bagagens e quando contratados lucram com isso.
                    Nossos protocolos conferem o máximo de segurança aos nossos viajantes e pacotes.'
                    />
                    </div>
                    <div className='col-12  col-lg-4 d-flex justify-content-center px-4'>
                    <Card 
                    icon={<MdTwoWheeler/>}
                    title='Entregador'
                    info='Nossos parceiros entregadores desempenham um papel fundamental em nossa comunidade,
                    garantindo uma rápida conferência no recebimento e agilidade na entrega, o que reforça a eficiência e confiabilidade do serviço.'
                    />
                    </div>
                    
                </LandingContainerCards>
            </section>
            <section>
            <div className='d-flex flex-column align-items-center'>
                    <LandingText className='mb-0 text-muted'>Conheça os nossos</LandingText>
                    <LandingSubTitle className='text-muted'>Benefícios</LandingSubTitle>
                    <LandingListBeneficios className='row ms-auto'>
                        
                            <div className='col-12 col-sm-5 col-xl-3'>
                                <li className='dotlist text-muted '><LandingText>Ganhe dinheiro enquanto viaja</LandingText></li>
                                
                            </div>
                            <div className='col-12 col-sm-5 col-xl-3'>
                                
                                <li className='dotlist text-muted '><LandingText>Acompnhamento e segurança</LandingText></li>
                            </div>
                            <div className='col-12 col-sm-5 col-xl-3'>
                                <li className='dotlist text-muted '><LandingText>Envio e recebimento rápidos</LandingText></li>
                                
                            </div>
                            <div className='col-12 col-sm-5 col-xl-3'>
                                
                                <li className='dotlist text-muted '><LandingText>Turbine as vendas do seu e-commerce</LandingText></li>
                            </div>
                            <div className='col-12 col-sm-5 col-xl-3'>
                                <li className='dotlist text-muted '><LandingText>Transparência nas entregas</LandingText></li>
                    
                            </div>
                            <div className='col-12 col-sm-5 col-xl-3'>
                                
                                <li className='dotlist text-muted '><LandingText>Entregadores selecionados</LandingText></li>
                            </div>

                       
                    </LandingListBeneficios>
                </div>
            </section>
            <section>
            <div className='mb-5' id='perguntas_frequentes'>
                    <LandingText className='mb-0 text-muted text-center'>As principais dúvidas sobre a T3Lake</LandingText>
                    <LandingSubTitle className='fw-bold text-muted text-center'>Perguntas frequentes</LandingSubTitle>
                </div>

                <LandDropDown 
                    id="drop1" 
                    title={'Quais medidas estão em vigor para garantir a segurança dos produtos e viajantes durante o transporte?'}
                    info={'Visando conferir garantia aos produtos, coletamos os dados de todos os nossos viajantes cadastrados e confirmamos com as informações das viagens cadastradas junto aos sites das respectivas cias aéreas. Por outro lado, fazemos uma dupla checagem dos produtos, cruzando os dados declarados pelo e-commerce e conferindo fisicamente no momento da coleta, proporcionando segurança ao viajante também. '}
                />
                <LandDropDown 
                    id="drop2" 
                    title={'Qual é o tempo médio de entrega?'}
                    info={'Os vôos nacionais possuem em média 3h à 6h de duração, no entanto devem ser incluídos os tempos de coleta e entrega dos produtos, bem como possíveis atrasos e escalas dos voos. No entanto, nossas entregas em média não costumam ultrapassar 36h corridas.'}
                />
                <LandDropDown 
                    id="drop3" 
                    title={'Como é feita a confirmação do recebimento do produto pelo cliente?'}
                    info={'Após a entrega dos pacotes e finalização do processo, um termo de recebimento é assinado pelo dono do pacote e remetido ao E-commerce.'}
                />
                <LandDropDown 
                    id="drop4" 
                    title={'Quais são as políticas de privacidade em vigor para a proteção dos dados dos clientes, e-commerce e entregadores?'}
                    info={'Os dados solicitados pela T3 Lake tem a finalidade de atribuir segurança à todos os envolvidos no processo de transporte. Desse modo, cuidamos para que esses dados estejam seguros em nosso banco, sendo usados apenas para a finalidade anteriormente citada, cumprindo o previsto na Lei Geral de Proteção de Dados (LGPD).'}    
                />
                <LandDropDown 
                    id="drop5" 
                    title={'O serviço de entrega está disponível em todas as regiões?'}
                    info={'Inicialmente, prezando pela rapidez e segurança de nossas entregas, estamos operando apenas em cidades com aeroportos de médio e grande fluxo de passageiros, logo estaremos atendendo as demandas das cidades-sede e cidades arredores. No entanto, nossos esforços estão sendo somados também com o intuito de aumentar o nosso alcance.'}    
                />

                <div className='text-end w-100'>
                    <Link to='/contact'>
                        <CtaButtonSec variant style={{marginTop: '2.5rem', marginRight: '0'}}>
                            Entrar em contato    
                        </CtaButtonSec>
                    </Link>
                </div>
            </section>
                
            </Container>

            

            {/* <div style={{backgroundColor: '#35B7E0',paddingTop: '3.5rem', paddingBottom: '5.5rem'}}>
                <Container mb0>
                    <div>
                        <p className='mb-0 text-white'>Lorem ipsum dolor sit amet</p>
                        <h1 className='fw-bold text-white'>Lorem ipsum dolor</h1>
                    </div>
                    <div className='w-100 text-end text-white' style={{fontSize: '2.5rem', marginBottom: '2rem'}}>
                        <MdNavigateBefore/>
                        <MdNavigateNext/>
                    </div>

                    <div className='d-flex' style={{gap: '1.5rem'}}>
                        <CommentCard/>
                        <CommentCard/>
                        <CommentCard/>
                    </div>
                </Container>
            </div>

            <div style={{backgroundColor: '#2B6CBF'}}>
                <Container className='d-flex justify-content-center' style={{paddingTop: '5rem', paddingBottom: '5rem', gap: '4.5rem'}}>
                    <CountCard/>
                    <CountCard/>
                    <CountCard/>
                </Container>
            </div> */}
            <Footer/>
        </>
        
    )
}

export default Landing;