import { useContext, useState } from "react";
import { AddressStyledContainer } from "./styles/AdressStyledContainer.styled";
import ReactInputMask from "react-input-mask";
import axios from "axios";
import { AuthContext } from "../Contexts/AuthContext";

export const AddressInfoContainer = ({user})=>{
    const {setUser} = useContext(AuthContext);
    const [readOnlyAddress, setReadOnlyAddress] = useState(true);
    const [validCep, setValidCep] = useState(true);

    const [{content, btype}, setButtonType] = useState({
        content: 'Editar endereço',
        btype: 'button'
    });
    

    const editRequest= (data)=>{
        const config = {
            headers:{
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": 'application/json',
                Accept: 'application/json'
            }
        }
        
        let type;

        if(!validCep){
            document.getElementById('cep').focus();
            return
        }

        if(user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh')){
            type = 'viajantes';
        } else if (user.hasOwnProperty('cnpj')){
            type = 'empresas';
        }

        const uri = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/${type}/${user.id}`;

        axios.patch(uri, data, config)
        .then(res=>{
            setUser(res.data);
            setReadOnlyAddress(true);
            setButtonType({
                content: 'Editar endereço',
                btype: 'button'
            });
         }); 
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        
        const data = {
            cep: e.target.cep.value.replace(/[^0-9]/,''),
            cidade: e.target.city.value,
            rua: e.target.street.value,
            bairro: e.target.nhood.value,
            estado: e.target.state.value,
            numero: e.target.num.value
        }
        
        editRequest(data);
    }

    const handleButtonChange = (e)=>{
        const cepInput = document.getElementById('cep');
        setReadOnlyAddress(false);
        setButtonType({content: 'Salvar', btype:'submit'});
        cepInput.focus();
    }

    const handleCepChange = (e)=>{
        const numberscep = e.target.value.replace(/[^0-9]/g, '');

        if(numberscep.length === 8){
            axios.get(`https://opencep.com/v1/${numberscep}`)
            .then(res => {
                setValidCep(true);

                document.getElementById("nhood").value = res.data.bairro;
                document.getElementById("state").value = res.data.uf;
                document.getElementById("street").value = res.data.logradouro;
                document.getElementById("city").value = res.data.localidade;
                document.getElementById("num").focus();
            })
            .catch(e=>{
                setValidCep(false);
            });
        }
    }

    return (
        <AddressStyledContainer onSubmit={handleSubmit}>
            <div className="d-flex align-items-center justify-content-between">
                <h5>Endereço</h5>
            </div>

            <div className="row d-flex flex-wrap gap-4 justify-content-between address">
                <div className="col-12 col-sm">
                    <div >
                        <div className="d-flex justify-content-between">
                            <label className="fw-bold">CEP</label>
                            <span className={`text-danger ${validCep ? 'd-none':''}`}>Cep inválido.</span>
                        </div>
                        <ReactInputMask
                            onChange={handleCepChange} 
                            mask={"99999-999"} 
                            id="cep"
                            maskChar={''}
                            alwaysShowMask={true}
                            disabled={readOnlyAddress}
                            className="form-control mt-2" 
                            type="text" 
                            required
                            placeholder={user.cep}
                        />
                    </div>
                    <div>
                        <label className="mt-4 fw-bold">Bairro</label>
                        <input disabled id="nhood" className="form-control mt-2" type="text" placeholder={user.bairro}/>
                    </div>
                </div>
                <div className="col-12 col-sm">
                    <div>
                        <label className="fw-bold">Estado</label>
                        <input disabled id="state" className="form-control mt-2" type="text" placeholder={user.estado}/>
                    </div>
                    <div>
                        <label className="mt-4 fw-bold">Rua</label>
                        <input disabled id="street" className="form-control mt-2" type="text" placeholder={user.rua}/>
                    </div>
                </div>
                <div className="col-12 col-sm">
                    <div>
                        <label className="fw-bold">Cidade</label>
                        <input disabled id="city" className="form-control mt-2" type="text" placeholder={user.cidade}/>
                    </div>
                    <div>
                        <label className="mt-4 fw-bold">Número</label>
                        <input disabled={readOnlyAddress} required id="num" className="form-control mt-2" type="text" placeholder={user.numero}/>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
                
                <button 
                className="btn btn-edit" 
                type={btype}
                onClick={btype === 'button' ? handleButtonChange : null}
                >{content}</button>
            </div>
        </AddressStyledContainer>
    )
}