import { DropDownButton } from "./styles/landing/LandinButtons.style";
import { MdExpandMore } from "react-icons/md";
import { useState } from 'react';
import { LandingText } from "./styles/landing/LandingContainer.style";

export const LandDropDown = ({id, title, info})=>{
    const [drop, setDrop] = useState(false);
    const [border, setBorder] = useState(false);

        return (
        <>
        <div className="rounded-3 mt-3" 
        style={{backgroundColor: '#fff', padding: '1.375em 1.5em', boxShadow: `${border ? '0 0 0 1px #006AFF':'0 0 0 1px #7C7C83'}`}}>
            <div className="d-flex justify-content-between align-items-center">
                <LandingText className="text-muted mb-0">{title}</LandingText>

                <DropDownButton 
                    className="border-0" 
                    drop={drop} 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#${id}`} 
                    aria-expanded="false" 
                    aria-controls={id} 
                    
                    onClick={()=>{
                        setDrop(!drop);
                        setBorder(!border);
                    }}>


                    <MdExpandMore/>

                </DropDownButton>
            </div>

            <div className="collapse mt-4" id={id}>
            <LandingText>
            {info ?? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi mollis vehicula sem, eget congue turpis tincidunt sed. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam iaculis velit eu erat eleifend, eu euismod augue sagittis. In hendrerit, nunc sit amet pretium pulvinar.'
            }
            </LandingText>
            </div>
        </div>

        </>
    )
}