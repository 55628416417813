import React from 'react'
import { Modal } from 'react-bootstrap'
import { LoginButton, LoginButtonReturn } from '../styles/LoginButton.styled'

export const EditDataModal = ({fields, show, setShow, item, handleEditData}) => {
    const FILTERED_FIELD = fields.filter(f=>f!=='id'&&f!=='data_cadastro');
    const STATUS = [
        'EM ANÁLISE',
        'CONFIRMADA',
        'EMITIDA',
        'EM TRÂNSITO',
        'EM ROTA DE ENTREGA',
        'ENTREGUE','NEGADA'
    ]
    return (
    <Modal
        show={show}
        onHide={_=>setShow(false)}
        centered
    >
        <Modal.Header closeButton><h3 className='fw-bold mb-0'>Editar dados</h3></Modal.Header>
        <Modal.Body>
            <form className='row' onSubmit={handleEditData}>
                {item ? FILTERED_FIELD.map((field)=>(
                    <div className='col-6'>
                        <label htmlFor={field} className='fw-bold my-2'>{field}</label>
                        {field !== 'status' ? 
                        <input className='form-control' defaultValue={item[field]} name={field} id={field}/>
                        : <select id='status' className='form-select'>
                            {STATUS.map(s=>(<option selected={item[field] === s} value={s}>{s}</option>))}
                        </select>
                        }
                    </div>
                )) : ''}
                
                <div className='mt-4 d-flex justify-content-start gap-3'>
                    <LoginButtonReturn onClick={_=>setShow(false)}>Cancelar</LoginButtonReturn>
                    <LoginButton type="submit">Salvar</LoginButton>
                </div>
            </form>
        </Modal.Body>
    </Modal>
    )
}
