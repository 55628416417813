import ReactInputMask from "react-input-mask";
import { PersonalContainerStyle } from "./styles/PersonalContainerStyle.styled";
import { useEffect, useState } from "react";
import { MdAccountCircle, MdEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import {
  LoginButton,
  LoginButtonReturn,
} from "../components/styles/LoginButton.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Link } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { LoginForm } from "./styles/LoginForm.styled";
import ReactCodeInput from "react-code-input";

export const PersonalInfoContainer = ({ setUser, user }) => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState({});
  const [show, setShow] = useState(false);
  const [showCodePhone, setShowCodePhone] = useState(false);
  const [image, setImage] = useState("");
  const [codePhone, setCodePhone] = useState("");
  const [erroCodePhone, setErroCodePhone] = useState(false);
  const [showCodePhoneSucess, setShowCodePhoneSucess] = useState(false);
  const [verifyCodePhone, setVerifyCodePhone] = useState(user.numero_confirmado_em);
  const [loadingverifyCodePhone, setLoadingverifyCodePhone] = useState(false);
  const [msgCodePhone, setMsgCodePhone] = useState({erro: '', send: ''});

  const MAX_SIZE = 5 * 1024 * 1024;
  const URI =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const URI_STORAGE =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_STORAGE_URL_DEV
      : process.env.REACT_APP_API_STORAGE_URL_PROD;
  const CONFIG = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const handleSendCode = () => {

    axios
      .post(`${URI}/sms/enviar_codigo`,
        { 
            numero: user.telefone
        }
        , CONFIG)
      .then((res) => {
        setMsgCodePhone({send: res.data.msg, erro: ''});
        setTimeout(() => setMsgCodePhone({send: '', erro: ''}), 2000);
        setErroCodePhone(false);
      })
      .catch((error) => {
        setMsgCodePhone({send: '', erro: error.response.data.msg ?? ''});
        setTimeout(() => setMsgCodePhone({send: '', erro: ''}), 2000);
        console.log(error)
    });
  };
  const handleVerifyCode = (e) => {
    e.preventDefault();
    setLoadingverifyCodePhone(true);
    axios
      .post(`${URI}/sms/verificar_codigo`,
        { 
             codigo: codePhone,
             numero: user.telefone
        }
        , CONFIG)
      .then((res) => {
        setErroCodePhone(false);
        setShowCodePhoneSucess(true);
        setVerifyCodePhone(true);
        setUser({...user, numero_confirmado_em: true});
        setLoadingverifyCodePhone(false);
      })
      .catch((error) => {
        setErroCodePhone(true);
        setVerifyCodePhone(false);
        setLoadingverifyCodePhone(false);
        console.log(error)
    });
  };

  const handleFileChange = (e) => {
    setFile({});
    const img = e.target.files[0];
    if (img) {
      if (img.size > MAX_SIZE) {
        alert(
          `O arquivo excede o tamanho máximo de ${MAX_SIZE / (1024 * 1024)} mb`
        );
        return;
      }
      const imageUrl = URL.createObjectURL(img);
      setFile({ image: img, name: imageUrl });
    }
    setShow(true);
  };

  const handleSaveImage = () => {
    const fd = new FormData();

    fd.append("imagem", file.image);
    fd.append("_method", "PATCH");

    let type;

    if (user.hasOwnProperty("cpf") && !user.hasOwnProperty("cnh")) {
      type = "viajantes";
    }
    if (user.hasOwnProperty("cnpj")) {
      type = "empresas";
    }

    axios
      .post(`${URI}/${type}/${user.id}`, fd, CONFIG)
      .then((res) => {
        setUser(res.data);
        setImage(file.name);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const handleModalVerifyNumber = ()=>{
    setShowCodePhone(true);
    handleSendCode();
  }

  useEffect(() => {
    if (user.hasOwnProperty("cpf") && !user.hasOwnProperty("cnh")) {
      setTitle("Informações do viajante");
    } else {
      setTitle("Informações da empresa");
    }
  }, [title, user]);

  return (
    <PersonalContainerStyle>
      <h5>{title}</h5>
      <div className="d-flex flex-column flex-md-row gap-4 personal align-items-center">
        <div className="img-container">
          {image.length <= 0 ? (
            <div
              className="img"
              style={{
                backgroundImage: `url(${
                  user.imagem
                    ? `${URI_STORAGE}/${user.imagem}`
                    : "https://placehold.co/140x140"
                })`,
              }}
            ></div>
          ) : (
            <div style={{ backgroundImage: `url(${image})` }} className="img" />
          )}

          <label
            htmlFor="file"
            className="btn-file d-flex align-items-center justify-content-center"
          >
            <MdEdit />
          </label>
          <input
            onChange={handleFileChange}
            type="file"
            accept=".jpeg,.jpg,.mpeg,.png"
            id="file"
            hidden
          />
        </div>

        <div className="d-flex flex-row flex-wrap gap-3">
          {user.hasOwnProperty("cpf") || user.hasOwnProperty("cnh") ? (
            <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
              <div className="w-100">
                <label className="fw-bold">Nome completo</label>
                <input
                  readOnly
                  className="form-control mt-2"
                  value={`${user.nome}`}
                />
              </div>

              <div className="w-100">
                <label className="fw-bold">CPF</label>
                <ReactInputMask
                  readOnly
                  mask={"999.999.999-99"}
                  className="form-control mt-2"
                  value={user.cpf}
                />
              </div>
              {user.telefone ? (
                <div className="position-relative w-100">
                  <label className="fw-bold">Número de celular</label>
                  <ReactInputMask
                    readOnly
                    mask={"(99) 9 9999 9999"}
                    className="form-control mt-2"
                    value={user.telefone}
                  />
                  {!verifyCodePhone ? (
                    <>
                      <div
                        style={{ bottom: -28, right: 0 }}
                        className="position-absolute"
                      >
                        <Link
                          onClick={handleModalVerifyNumber}
                          style={{ textUnderlineOffset: 3, color: "#F3AD43" }}
                          className="p-2"
                        >
                          Verificar número
                        </Link>
                      </div>
                      <div
                        style={{ bottom: 14, right: 12, color: "#F3AD43" }}
                        className="position-absolute"
                      >
                        <ErrorIcon />
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
              <div className="w-100">
                <label className="fw-bold">Nome da empresa</label>
                <input
                  readOnly
                  className="form-control mt-2"
                  value={`${user.nome}`}
                />
              </div>

              <div className="w-100">
                <label className="fw-bold">CNPJ</label>
                <ReactInputMask
                  readOnly
                  mask={"99.999.999/9999-99"}
                  className="form-control mt-2"
                  value={user.cnpj}
                />
              </div>
            </div>
          )}

          <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
            {user.hasOwnProperty("cnpj") ? (
              <div className="w-100">
                <label className="fw-bold">Nome fantasia</label>
                <input
                  readOnly
                  className="form-control mt-2"
                  value={user.nome_fantasia}
                />
              </div>
            ) : (
              ""
            )}

            <div className="w-100">
              <label className="fw-bold">E-mail</label>
              <input
                readOnly
                className="form-control mt-2"
                value={user.email}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={(_) => setShow(false)} size="md">
        <Modal.Header closeButton={true}>
          <h4 className="mb-0 fw-bold text-muted">Confirmar</h4>
        </Modal.Header>
        <Modal.Body className="text-center p-4">
          <LazyLoadImage
            effect="blur"
            className="rounded"
            alt="Imagem escolhida pelo usuário"
            src={file.name}
            placeholderSrc={file.name}
            width={"100%"}
            height={300}
          />

          <LoginButton className="mt-3 w-100" onClick={handleSaveImage}>
            Salvar
          </LoginButton>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCodePhone}
        onHide={(_) => setShowCodePhone(false)}
        size="md"
      >
        <Modal.Header closeButton={true}>
          <h4 className="mb-0 fw-bold text-muted">Confirme o seu número</h4>
        </Modal.Header>
        <Modal.Body className="text-center p-4">
            { showCodePhoneSucess ?
            <div>
                <CheckCircleIcon style={{fontSize: 120, color: '#0CB97B'}}/>
                <h5 style={{ color: '#0CB97B'}} className="fs-5 my-3">Número de celular confirmado com sucesso!</h5>
            </div>

             :
             <LoginForm className="mt-5" onSubmit={handleVerifyCode} method="POST">
            <label className="fw-bold p-2">
              Digite o código enviado para o número
            </label>
            <div className="w-100 position-relative">
              <ReactCodeInput
                value={codePhone}
                onChange={(value) => setCodePhone(value)}
                type="text"
                fields={4}
                className="text-start"
                isValid={!erroCodePhone}
                inputStyle={{
                  fontFamily: "inter",
                  margin: "4px",
                  width: "20%",
                  borderRadius: "6px",
                  fontSize: "24px",
                  height: "56px",
                  paddingLeft: "8%",
                  border: "1px solid #DFDFE0",
                  outlineWidth: 0,
                }}
                inputStyleInvalid={{
                  fontFamily: "inter",
                  margin: "4px",
                  width: "20%",
                  borderRadius: "6px",
                  fontSize: "24px",
                  height: "56px",
                  paddingLeft: "8%",
                  border: "1px solid #FF385C",
                  outlineWidth: 0,
                }}
              />
              {erroCodePhone ? (
                <div
                  style={{ bottom: 20, right: -4, color: "#FF385C" }}
                  className="position-absolute"
                >
                  <ErrorIcon />
                </div>
              ) : null}
            </div>
              {!msgCodePhone.send && !msgCodePhone.erro ? 
              <Link
              onClick={() => handleSendCode()}
              style={{ textUnderlineOffset: 3, color: "#35B7E0" }}
              className="p-2"
            >
              Enviar código novamente
            </Link> :
            ( msgCodePhone.send ?
            <Link
            onClick={() => handleSendCode()}
            style={{ textUnderlineOffset: 3, color: "#0CB97B", textDecoration: 'none' }}
            className="p-2"
          >
            {msgCodePhone.send} 
          </Link> :
          <Link
          onClick={() => handleSendCode()}
          style={{ textUnderlineOffset: 3, color: "#FF385C", textDecoration: 'none' }}
          className="p-2"
        >
          {msgCodePhone.erro} 
        </Link>  
        ) }
           

            <div className="mt-4 d-flex justify-content-start gap-3 w-100">
              <LoginButtonReturn onClick={(_) => setShowCodePhone(false)}>
                Voltar
              </LoginButtonReturn>
              <LoginButton disabled={!(codePhone.length == 4) || loadingverifyCodePhone} type="submit">
                {loadingverifyCodePhone ? 
                    <CircularProgress size="30px" color="white" />
                :
                "Confirmar"
                }
                
              </LoginButton>
            </div>
          </LoginForm>
          }
        </Modal.Body>
      </Modal>
    </PersonalContainerStyle>
  );
};
