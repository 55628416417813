import styled from "styled-components";

export const MySentsFilter = styled.div`
    margin-top: 3.5rem;

    input[type="date"]{
        position: relative;

        &::-webkit-calendar-picker-indicator{
            position: absolute;
            right: 10%;
        }
    }
`