import styled from "styled-components";
import { Navbar } from "react-bootstrap";

export const HeaderStyle = styled.header`
    border-bottom: 1px solid #EFEFF0;
    box-shadow: 0px 4px 7.2px 0px #2B6CBF08;

`;

export const HeaderAuthContainer = styled.div`
    min-width: 210px;
    display: flex;
    @media only screen and (max-width: 1200px) {
        margin-top: 24px;
    }
`;

export const HeaderLogo = styled.div`

`;
export const HeaderList = styled.ul`
gap: 16px;
`;