import TextInput from "../components/forms/TextInput";
import { Header } from "../components/Header";
import { MenuSideBar } from "../components/MenuSideBar";
import { CardBorder } from "../components/styles/CardBorder.styled";
import { LoginButtonReturn } from "../components/styles/LoginButton.styled";
import { SupportContainer } from "../components/styles/SupportContainer.styled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const Financial = () => {
  return (
    <MenuSideBar billing title="Financeiro">
      <div className="flex-grow-1 d-flex flex-column pt-5">
        <div className="row justify-content-between gy-5">
          <div className="col-12 col-md-6">
            <h6 style={{ color: "#68686E" }} className="fw-bold">
              Extrato financeiro
            </h6>
            <div className="d-flex flex-column gap-4">
              <CardBorder borderColor="#35B7E0" borderDirection="bottom">
                <span
                  style={{
                    color: "#7C7C83",
                    fontWeight: "500",
                    fontSize: "0.8rem",
                  }}
                  className=""
                >
                  Saldo na conta
                </span>
                <h2
                  style={{ color: "#35B7E0", fontSize: "calc(1.5rem + 1.5vw)" }}
                  className="fw-bold"
                >
                  R$ 987,30
                </h2>
              </CardBorder>
              <CardBorder borderColor="#F3AD43" borderDirection="bottom">
                <span
                  style={{
                    color: "#7C7C83",
                    fontWeight: "500",
                    fontSize: "0.8rem",
                  }}
                  className=""
                >
                  Em processamento
                </span>
                <h2
                  style={{ color: "#F3AD43", fontSize: "calc(1.5rem + 1.5vw)" }}
                  className="fw-bold"
                >
                  R$ 987,30
                </h2>
              </CardBorder>
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex flex-column gap-4">
            <div className="w-100 w-md-50">
              <label style={{ color: "#68686E" }} className="fw-bold">
                Banco
              </label>
              <TextInput
                onFocusDisabled
                readOnly
                disabled
                value=""
                placeholder={"(Nome do banco)"}
              />
            </div>
            <div>
              <label style={{ color: "#68686E" }} className="fw-bold">
                Agência
              </label>
              <TextInput
                onFocusDisabled
                readOnly
                disabled
                value=""
                placeholder={"(Nome da agência)"}
              />
            </div>
            <div>
              <label style={{ color: "#68686E" }} className="fw-bold">
                Conta
              </label>
              <TextInput
                onFocusDisabled
                readOnly
                disabled
                value=""
                placeholder={"(ID da conta)"}
              />
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 style={{ color: "#68686E" }} className="fw-bold m-0">
                Extrato financeiro
              </h6>
              <span>
                <LoginButtonReturn className="py-2 px-3 fs-6 text-nowrap">
                  Ver mais <ArrowForwardIosIcon fontSize="24" />
                </LoginButtonReturn>
              </span>
            </div>

            <CardBorder className="d-flex flex-column gap-5" borderColor="#35B7E0" borderDirection="bottom">
              <div className="divider-itens row gy-3">
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center"><p style={{color: '#F3AD43'}} className="m-0 d-flex gap-3 fw-bold"><AccessTimeIcon/> Pendente</p></div>
                <div className="col-12 col-sm d-flex justify-content-center align-items-center"><div><p style={{color: '#7C7C83', fontSize: 14}} className="m-0">R$ 987,30</p></div></div>
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-cente">
                    <div>
                    <p  style={{color: '#7C7C83', fontSize: 14}} className="m-0 ">Data</p>
                    <p className="fw-bolder mb-0">21 Jan 2024</p>
                    </div>
                </div>
              </div>
              <div className="divider-itens row gy-3">
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center"><p style={{color: '#0CB97B'}} className="m-0 d-flex gap-3 fw-bold"><CheckCircleOutlineIcon/> Sucesso</p></div>
                <div className="col-12 col-sm d-flex justify-content-center align-items-center"><div><p style={{color: '#7C7C83', fontSize: 14}} className="m-0">R$ 987,30</p></div></div>
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-cente">
                    <div>
                    <p  style={{color: '#7C7C83', fontSize: 14}} className="m-0 ">Data</p>
                    <p className="fw-bolder mb-0">21 Jan 2024</p>
                    </div>
                </div>
              </div>
              <div className="divider-itens row gy-3">
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center"><p style={{color: '#E02244'}} className="m-0 d-flex gap-3 fw-bold"><CancelOutlinedIcon/> Negado</p></div>
                <div className="col-12 col-sm d-flex justify-content-center align-items-center"><div><p style={{color: '#7C7C83', fontSize: 14}} className="m-0">R$ 987,30</p></div></div>
                <div className="col-12 col-sm-4 d-flex justify-content-center align-items-cente">
                    <div>
                    <p  style={{color: '#7C7C83', fontSize: 14}} className="m-0 ">Data</p>
                    <p className="fw-bolder mb-0">21 Jan 2024</p>
                    </div>
                </div>
              </div>
             
            </CardBorder>
          </div>
        </div>
      </div>
    </MenuSideBar>
  );
};
