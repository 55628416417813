import styled from "styled-components";


export const LandingTitle = styled.h1`
        font-size: 56px;
        font-weight: bold;
        color: #09CAED;

        @media only screen and (max-width: 990px) {
            font-size: 36px;
        }
        @media only screen and (max-width: 425px) {
            font-size: 28px;
        }
`
export const LandingSubTitle = styled.h2`
        font-size: 40px;
        font-weight: bold;

        @media only screen and (max-width: 990px) {
            font-size: 24px;
        }
        @media only screen and (max-width: 425px) {
            font-size: 22px;
        }
`
export const LandingText = styled.h2`
        font-size: 16px;
        font-weight: 400;

        @media only screen and (max-width: 990px) {
            font-size: 14px;
        }
`

export const LandingListBeneficios = styled.div`
        margin-top: 56px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 0 56px 0 56px;
        gap: 24px;

        @media only screen and (max-width: 400px){ 
            padding: 0;
        }

        .dotlist{
        position: relative;
        display: flex;
        align-items: center;

        &::before{
            content: '';
            height: .5rem;
            width: .5rem;
            border-radius: 50%;
            background-color: #2B6CBF;
            margin-right: 1rem;
        }
    }
`
