import logo from '../../images/t3logo.svg';
import { Link } from 'react-router-dom';
import { CadButton, LogButton } from '../styles/landing/LandinButtons.style';
import { HeaderStyle, HeaderLogo, HeaderAuthContainer } from '../styles/landing/HeaderStyle.styled';
import { HashLink } from 'react-router-hash-link';
import {Navbar, Nav, Container,} from 'react-bootstrap';
import { useState } from 'react';
import ToogleIcon from './ToogleIcon';

export const Header = ()=>{
    
    return (
        <HeaderStyle>
    <Navbar expand="xl" className="bg-body-tertiary">
      <Container className='justify-content-between'>
        <Navbar.Brand href="#home">
            <HeaderLogo>
                    <Link to='/'>
                        <img src={logo} alt='T3lake logo'/>
                    </Link>
            </HeaderLogo>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='border-0 shadow-none p-0'><ToogleIcon/></Navbar.Toggle>
        <Navbar.Collapse  className='justify-content-end gap-3' id="basic-navbar-nav">
            
            <div className='justify-content-center w-100'>
          <Nav className="justify-content-center gap-1 gap-xl-3">
            <Nav.Link className='text-center text-muted  text-decoration-none' as={Link} to='/about' >Sobre nós</Nav.Link>
            <Nav.Link className='text-center text-muted text-decoration-none' as={Link} to='/how'>Como funciona</Nav.Link>
            <Nav.Link className='text-center text-muted text-decoration-none' as={HashLink} to='/#perguntas_frequentes'>Perguntas frequentes</Nav.Link>
            <Nav.Link className='text-center text-muted text-decoration-none' as={Link} to='/contact'>Contato</Nav.Link>
          </Nav>
          </div>
          <HeaderAuthContainer className='justify-content-center justify-content-xl-end'>
            <Link to="/login" className='text-decoration-none me-2'><LogButton>Login</LogButton></Link>
            <Link to="/register" className='text-decoration-none text-white'><CadButton>Cadastre-se!</CadButton></Link>
        </HeaderAuthContainer>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            {/* <nav className='navbar'>

                <HeaderLogo>
                    <Link to='/'>
                        <img src={logo} alt='T3lake logo'/>
                    </Link>
                </HeaderLogo>

                <ul className='nav gap-4'>
                    <li><Link to='/about'  className='text-muted text-decoration-none'>Sobre nós</Link></li>
                    <li><Link to='/how'  className='text-muted text-decoration-none'>Como funciona</Link></li>
                    <li><HashLink to='/#perguntas_frequentes'  className='text-muted text-decoration-none'>Perguntas frequentes</HashLink></li>
                    <li><Link to='/contact'  className='text-muted text-decoration-none'>Contato</Link></li>
                </ul>
                <div>
                    <Link to="/login" className='text-decoration-none me-2'><LogButton>Login</LogButton></Link>
                    <Link to="/register" className='text-decoration-none text-white'><CadButton>Cadastre-se!</CadButton></Link>
                </div>
            </nav> */}
        </HeaderStyle>
    )
}