import React, { useContext, useEffect } from "react";
import {Outlet, useNavigate} from 'react-router-dom';
import { AuthContext } from "../Contexts/AuthContext";

const PublicNaviControl = ()=>{
    const {user, auth} = useContext(AuthContext);
    const navigator = useNavigate();
    
    useEffect(()=>{
        if(auth){
            if(user.hasOwnProperty('cnpj')){
                return navigator('/companies/profile');
            } 
            
            if (user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh')){
                return navigator('/travelers/profile');
            }

            if(user.hasOwnProperty('cnh'))
            {
                return navigator('/deliverer/profile');
            }
        }
    })

    return <Outlet/>
}

export default PublicNaviControl;