import styled from "styled-components";

export const Tooltip = styled.span`
    position: absolute;
    left: -100%;
    transform: translateY(-100%);
    background-color: black;
    color: white;
    white-space: nowrap;
    padding: .5em 1em;
    border-radius: 6px;
    font-size: .75em;
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s ease;
`;